import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout_user_action } from '../../_redux/action';
import { navigateRoutes } from '../../_config/route';
import { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../../_hooks/useAuthContext';

function useSidebar(props) {
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const onSignOutClick = async () => {
    await dispatch(logout_user_action());
    navigate(navigateRoutes.signIn);
  };

  const viewProfile = () => {
    navigate(navigateRoutes.candidateProfile);
  };

  const toggleSidebar = () => {
    setIsCollapsed((prev) => !prev);
  };

  // Function to toggle dropdown visibility
  const handleToggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleNavigation = (route) => {
    navigate(route);
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  return {
    user,
    isCollapsed,
    isDropdownOpen,
    dropdownRef,
    isDrawerOpen,

    viewProfile,
    onSignOutClick,
    toggleDrawer,
    toggleSidebar,
    handleToggleDropdown,
    handleNavigation,
  };
}
export default useSidebar;
