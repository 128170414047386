import React from 'react';

const companySizesConst = [
  {
    key: '01-10 employees',
    value: '01-10 employees',
  },
  {
    key: '11-50 employees',
    value: '11-50 employees',
  },
  {
    key: '51-200 employees',
    value: '51-200 employees',
  },
  {
    key: '201-500 employees',
    value: '201-500 employees',
  },
  {
    key: '501-1000+ employees',
    value: '501-1000+ employees',
  },
];

function CompanySize({ selectedValue, onCompanySizeChange }) {
  return (
    <div className="select mt-1">
      <p className="mb-1">Company Size</p>
      <select
        className="select-control bg-transparent"
        onChange={(e) =>
          onCompanySizeChange({
            target: { name: 'company_size', value: e.target.value },
          })
        }
        name={'company_size'}
      >
        <option disabled={true} value="" selected={selectedValue === ''}>
          Select Company Size
        </option>
        {companySizesConst.map((item) => (
          <option key={item.key} selected={selectedValue === item.value} defaultValue={item.value}>
            {item.key}
          </option>
        ))}
      </select>
    </div>
  );
}

export default CompanySize;
