/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetch_countries_action } from '../_redux/action/_master';

const CountrySearchBasic = ({ defaultValue, onSearchChange }) => {
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  const { countries } = useSelector((state) => state.fetch_countries_reducer);

  const ref = useRef();
  useEffect(() => {
    if (ref?.current && ref?.current?.value) {
      ref.current.value = defaultValue;
    }
  }, []);

  useEffect(() => {
    const callApi = async () => {
      await dispatch(fetch_countries_action());
    };
    callApi();
  }, []);

  useEffect(() => {
    if (countries?.countries) {
      setOptions(countries.countries);
    }
  }, [countries]);

  return (
    <Select
      ref={ref}
      showSearch
      value={defaultValue}
      className="px-0 relative w-full"
      placeholder={'Search Country'}
      allowClear={true}
      options={(options || []).map((d) => ({
        value: d?._id,
        label: `${d.display_name}`,
      }))}
      getPopupContainer={(trigger) => trigger.parentNode}
      filterOption={(input, option) => (option.label.toLowerCase() ?? '').includes(input.toLowerCase())}
      onSelect={onSearchChange}
    />
  );
};
export default CountrySearchBasic;
