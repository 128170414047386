import { userResumeConstants } from '../../constant';

const initialState = { resumes: null };

export const fetch_user_all_resumes_reducer = (state = initialState, action) => {
  switch (action.type) {
    case userResumeConstants.FETCH_USER_RESUMES_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case userResumeConstants.FETCH_USER_RESUMES_ACTION_SUCCESS:
      return {
        resumes: action.payload,
      };
    case userResumeConstants.FETCH_USER_RESUMES_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};
