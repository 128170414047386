export const userProfessionConstants = {
  FETCH_USER_PROFESSIONS_ACTION_REQUEST: 'FETCH_USER_PROFESSIONS_ACTION_REQUEST',
  FETCH_USER_PROFESSIONS_ACTION_SUCCESS: 'FETCH_USER_PROFESSIONS_ACTION_SUCCESS',
  FETCH_USER_PROFESSIONS_ACTION_FAILURE: 'FETCH_USER_PROFESSIONS_ACTION_FAILURE',

  CREATE_USER_PROFESSION_ACTION_REQUEST: 'CREATE_USER_PROFESSION_ACTION_REQUEST',
  CREATE_USER_PROFESSION_ACTION_SUCCESS: 'CREATE_USER_PROFESSION_ACTION_SUCCESS',
  CREATE_USER_PROFESSION_ACTION_FAILURE: 'CREATE_USER_PROFESSION_ACTION_FAILURE',

  UPDATE_USER_PROFESSION_ACTION_REQUEST: 'UPDATE_USER_PROFESSION_ACTION_REQUEST',
  UPDATE_USER_PROFESSION_ACTION_SUCCESS: 'UPDATE_USER_PROFESSION_ACTION_SUCCESS',
  UPDATE_USER_PROFESSION_ACTION_FAILURE: 'UPDATE_USER_PROFESSION_ACTION_FAILURE',

  DELETE_USER_PROFESSION_ACTION_REQUEST: 'DELETE_USER_PROFESSION_ACTION_REQUEST',
  DELETE_USER_PROFESSION_ACTION_SUCCESS: 'DELETE_USER_PROFESSION_ACTION_SUCCESS',
  DELETE_USER_PROFESSION_ACTION_FAILURE: 'DELETE_USER_PROFESSION_ACTION_FAILURE',
};
