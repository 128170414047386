/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetch_companies_action } from '../_redux/action/_master';

const CompanySearchBasic = ({ defaultValue, onSearchChange }) => {
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  const { companies } = useSelector((state) => state.fetch_companies_reducer);

  const ref = useRef();
  useEffect(() => {
    if (ref?.current && ref?.current?.value) {
      ref.current.value = defaultValue;
    }
  }, []);

  useEffect(() => {
    const callApi = async () => {
      await dispatch(fetch_companies_action());
    };
    callApi();
  }, []);

  useEffect(() => {
    if (companies?.companies) {
      setOptions(companies.companies);
    }
  }, [companies]);

  return (
    <Select
      ref={ref}
      showSearch
      value={defaultValue}
      className="relative w-full py-0"
      placeholder={'Search Company'}
      allowClear={true}
      options={(options || []).map((d) => ({
        value: d?._id,
        label: `${d.company_name}`,
      }))}
      getPopupContainer={(trigger) => trigger.parentNode}
      filterOption={(input, option) => (option.label.toLowerCase() ?? '').includes(input.toLowerCase())}
      onSelect={onSearchChange}
    />
  );
};
export default CompanySearchBasic;
