import { showSuccessMessage, showWarningMessage } from '../../../_component/MessageSnack';
import axiosInstance from '../../../_config/AxiosInstance';
import config from '../../../_config/config';
import { userSkillConstants } from '../../constant';

export const fetch_user_skills_action = () => async (dispatch) => {
  dispatch({
    type: userSkillConstants.FETCH_USER_SKILLS_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.skill.getSkills);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userSkillConstants.FETCH_USER_SKILLS_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userSkillConstants.FETCH_USER_SKILLS_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const create_user_skill_action = (data) => async (dispatch) => {
  dispatch({
    type: userSkillConstants.CREATE_USER_SKILL_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(`${config.skill.getSkill}`, data);
    if (response.status) {
      let data = response.data;
      showSuccessMessage(data.message);
      dispatch({
        type: userSkillConstants.CREATE_USER_SKILL_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userSkillConstants.CREATE_USER_SKILL_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const update_user_skill_action = (id, data) => async (dispatch) => {
  dispatch({
    type: userSkillConstants.UPDATE_USER_SKILL_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.put(`${config.skill.getSkill}/${id}`, data);
    if (response.status) {
      let data = response.data;
      showSuccessMessage(data.message);
      dispatch({
        type: userSkillConstants.UPDATE_USER_SKILL_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userSkillConstants.UPDATE_USER_SKILL_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const delete_user_skill_action = (id) => async (dispatch) => {
  dispatch({
    type: userSkillConstants.DELETE_USER_SKILL_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.delete(`${config.skill.getSkill}/${id}`);
    if (response.status) {
      let data = response.data;
      showSuccessMessage(data.message);
      dispatch({
        type: userSkillConstants.DELETE_USER_SKILL_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userSkillConstants.DELETE_USER_SKILL_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};
