/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetch_headlines_action } from '../_redux/action/_master';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const HeadlineSearchBasic = ({ defaultValue, onSearchChange }) => {
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  const { headlines } = useSelector((state) => state.fetch_headlines_reducer);

  const ref = useRef();
  useEffect(() => {
    if (ref?.current && ref?.current?.value) {
      ref.current.value = defaultValue;
    }
  }, []);

  useEffect(() => {
    const callApi = async () => {
      await dispatch(fetch_headlines_action());
    };
    callApi();
  }, []);

  useEffect(() => {
    if (headlines?.headlines) {
      setOptions(headlines.headlines);
    }
  }, [headlines]);

  return (
    <Select
      ref={ref}
      showSearch
      value={defaultValue}
      className="w-2/3 relative max-[640px]:w-full"
      placeholder={'Search Headline'}
      allowClear={true}
      options={(options || []).map((d) => ({
        value: d?._id,
        label: `${d.display_name}`,
      }))}
      getPopupContainer={(trigger) => trigger.parentNode}
      filterOption={(input, option) => (option.label.toLowerCase() ?? '').includes(input.toLowerCase())}
      onSelect={onSearchChange}
    />
  );
};
export default HeadlineSearchBasic;
