import React from 'react';
import InputField from '../../../../_component/InputField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus, faDeleteLeft, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

function FineTunedResumeFormExperience({
  onParseResumeExperienceAddClick,
  onParseResumeExperienceRemoveClick,
  parsedResumeForm,
  onParseResumeExperienceChange,
  onParseResumeExperienceDescriptionAddClick,
  onParseResumeExperienceDescriptionChange,
  onParseResumeExperienceDescriptionRemoveClick,
}) {
  return (
    <div className="my-8 max-[768px]:my-4 ">
      {Array.isArray(parsedResumeForm?.experiences) &&
        parsedResumeForm.experiences.map((item, index) => (
          <div className="bg-slate-100 rounded-lg mb-2 p-2" key={item?.company_name}>
            <div className="flex flex-row w-full justify-between items-center">
              <div className="grid grid-cols-2 gap-2 mb-2">
                <InputField
                  id={`company-${index}`}
                  label="Company"
                  type="text"
                  name="company_name"
                  placeholder="Enter company"
                  defaultValue={item?.company_name}
                  onBlur={(e) => onParseResumeExperienceChange(index, e)}
                  containerClassName="form-group flex flex-col"
                  inputClassName="rounded-2xl border  border-gray-800 p-1.5"
                  labelClassName=""
                />
                <InputField
                  id={`job-title-${index}`}
                  label="Job Title"
                  type="text"
                  name="job_title"
                  placeholder="Enter job title"
                  defaultValue={item?.job_title}
                  onBlur={(e) => onParseResumeExperienceChange(index, e)}
                  containerClassName="form-group flex flex-col"
                  inputClassName="rounded-2xl border  border-gray-800 p-1.5"
                  labelClassName=""
                />
                <InputField
                  id={`start-date-${index}`}
                  label="Start Date"
                  type="text"
                  name="start_date"
                  placeholder="Enter start date"
                  defaultValue={item?.start_date}
                  onBlur={(e) => onParseResumeExperienceChange(index, e)}
                  containerClassName="form-group flex flex-col"
                  inputClassName="rounded-2xl border  border-gray-800 p-1.5"
                  labelClassName=""
                />
                <InputField
                  id={`end-date-${index}`}
                  label="End Date"
                  type="text"
                  name="end_date"
                  placeholder="Enter end date"
                  defaultValue={item?.end_date}
                  onBlur={(e) => onParseResumeExperienceChange(index, e)}
                  containerClassName="form-group flex flex-col"
                  inputClassName="rounded-2xl border  border-gray-800 p-1.5"
                  labelClassName=""
                />
                <div>
                  <p className="font-medium ">
                    Description{' '}
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className="ml-2 cursor-pointer"
                      onClick={() => onParseResumeExperienceDescriptionAddClick(index)}
                    />
                  </p>
                  {Array.isArray(item?.description) &&
                    item.description.map((dsc, dscIndex) => (
                      <div className="flex flex-row mt-1 items-center">
                        <InputField
                          id={`description-${index}-${dscIndex}`}
                          label=""
                          type="textarea"
                          rows={1}
                          name="description"
                          placeholder="Enter description"
                          defaultValue={dsc}
                          onBlur={(e) => onParseResumeExperienceDescriptionChange(index, e, dscIndex)}
                          containerClassName="form-group flex flex-col"
                          inputClassName="rounded-2xl border  border-gray-800 p-1.5"
                        />
                        <FontAwesomeIcon
                          icon={faDeleteLeft}
                          className="ml-1 text-danger cursor-pointer"
                          onClick={() => onParseResumeExperienceDescriptionRemoveClick(index, dscIndex)}
                        />
                      </div>
                    ))}
                </div>
              </div>
              <span className="self-start">
                <FontAwesomeIcon
                  icon={faCircleMinus}
                  className="ml-5 text-danger cursor-pointer"
                  onClick={() => onParseResumeExperienceRemoveClick(index)}
                />
              </span>
            </div>
          </div>
        ))}
      <div className="flex flex-row items-center w-full ">
        <h2 className="text-gray-600 font-medium ">Experience</h2>{' '}
        <FontAwesomeIcon
          icon={faPlusCircle}
          className="ml-2 h-4 text-gray-600 cursor-pointer"
          onClick={onParseResumeExperienceAddClick}
        />
      </div>
    </div>
  );
}

export default FineTunedResumeFormExperience;
