import { userJobBookmarkConstants } from '../../constant';

const initialState = { bookmarkedJobsData: null, bookmarkedJobData: null };

export const fetch_user_job_bookmarks_reducer = (state = initialState, action) => {
  switch (action.type) {
    case userJobBookmarkConstants.FETCH_USER_JOB_BOOKMARKS_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case userJobBookmarkConstants.FETCH_USER_JOB_BOOKMARKS_ACTION_SUCCESS:
      return {
        bookmarkedJobsData: action.payload,
      };
    case userJobBookmarkConstants.FETCH_USER_JOB_BOOKMARKS_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const fetch_user_job_details_bookmark_reducer = (state = initialState, action) => {
  switch (action.type) {
    case userJobBookmarkConstants.FETCH_USER_JOB_DETAILS_BOOKMARK_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case userJobBookmarkConstants.FETCH_USER_JOB_DETAILS_BOOKMARK_ACTION_SUCCESS:
    case userJobBookmarkConstants.UPDATE_STATUS_USER_BOOKMARK_JOB_ACTION_SUCCESS:
      return {
        bookmarkedJobData: action.payload,
      };
    case userJobBookmarkConstants.FETCH_USER_JOB_DETAILS_BOOKMARK_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};
