import { showWarningMessage } from '../../../_component/MessageSnack';
import axiosInstance from '../../../_config/AxiosInstance';
import config from '../../../_config/config';
import { userContactBookmarkConstants } from '../../constant';

export const fetch_user_bookmark_contacts_action = () => async (dispatch) => {
  dispatch({
    type: userContactBookmarkConstants.FETCH_USER_CONTACT_BOOKMARKS_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.bookmarks.contactBookmarks);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userContactBookmarkConstants.FETCH_USER_CONTACT_BOOKMARKS_ACTION_SUCCESS,
        payload: { data: data.data },
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userContactBookmarkConstants.FETCH_USER_CONTACT_BOOKMARKS_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const update_user_bookmark_contact_action = (id, status) => async (dispatch) => {
  dispatch({
    type: userContactBookmarkConstants.UPDATE_USER_BOOKMARK_CONTACT_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.put(`${config.bookmarks.contactBookmark}/${id}`, status);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userContactBookmarkConstants.UPDATE_USER_BOOKMARK_CONTACT_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userContactBookmarkConstants.UPDATE_USER_BOOKMARK_CONTACT_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

// action to create a new contacts
export const create_user_bookmark_contact_action = (data) => async (dispatch) => {
  dispatch({
    type: userContactBookmarkConstants.CREATE_USER_BOOKMARK_CONTACT_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(config.bookmarks.contactBookmark, data);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userContactBookmarkConstants.CREATE_USER_BOOKMARK_CONTACT_ACTION_SUCCESS,
        payload: data.data,
      });
      return data.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userContactBookmarkConstants.CREATE_USER_BOOKMARK_CONTACT_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return null;
  }
};

export const fetch_user_contact_details_bookmark_action = (id) => async (dispatch) => {
  dispatch({
    type: userContactBookmarkConstants.FETCH_USER_CONTACT_DETAILS_BOOKMARK_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(`${config.bookmarks.contactBookmark}/${id}`);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userContactBookmarkConstants.FETCH_USER_CONTACT_DETAILS_BOOKMARK_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userContactBookmarkConstants.FETCH_USER_CONTACT_DETAILS_BOOKMARK_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const delete_user_contact_details_bookmark_action = (id) => async (dispatch) => {
  dispatch({
    type: userContactBookmarkConstants.DELETE_USER_BOOKMARK_CONTACT_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.delete(`${config.bookmarks.contactBookmark}/${id}`);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userContactBookmarkConstants.DELETE_USER_BOOKMARK_CONTACT_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userContactBookmarkConstants.DELETE_USER_BOOKMARK_CONTACT_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};
