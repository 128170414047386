import React from 'react';
import MainLayout from '../../../_layout/MainLayout';
import './JobTracker.scss';
import useJobTracker from './useJobTracker';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faTableColumns } from '@fortawesome/free-solid-svg-icons';
import JobBorderView from './_components/JobBorderView';
import JobListView from './_components/JobListView';

function JobTracker(props) {
  const {
    loading,
    columns,
    autoApplyJobIds,
    kanbanView,
    tableVisibility,
    isBookmarkedColumn,

    onDragEnd,
    onJobNavigateClick,
    onAutoApplyCreateClick,
    markJobsForAutoApply,
    checkAutoApply,
    handleChangeView,
    toggleTableVisibility,
  } = useJobTracker(props);

  return (
    <MainLayout activeMenuItem="home">
      <div className="job-tracker ">
        <div className="centered job-tracker-title-box window-container">
          <h2 className="window-title">Job Tracker</h2>
          {autoApplyJobIds.length ? (
            <button className="btn btn-save-apply" onClick={onAutoApplyCreateClick}>
              Save to auto apply
            </button>
          ) : null}
        </div>

        <div className="centered  view-box">
          <div className="centered view-handler">
            <span className="handler-title">{/* New Document */}</span>
            <div className="custom-content">
              <div className="centered view-filter" onClick={handleChangeView}>
                <FontAwesomeIcon icon={kanbanView ? faTableColumns : faList} />

                <span>{kanbanView ? 'Border View' : 'List View'}</span>
              </div>
              {/* <div className="centered view-filter">
                  <FontAwesomeIcon icon={faFilter} />
                  <span>Filter</span>
                </div>
                <div className="centered view-filter">
                  <FontAwesomeIcon icon={faArrowDownWideShort} />
                  <span>Sort</span>
                </div> */}
            </div>
          </div>
          {!kanbanView ? (
            <JobBorderView
              columns={columns}
              markJobsForAutoApply={markJobsForAutoApply}
              autoApplyJobIds={autoApplyJobIds}
              onJobNavigateClick={onJobNavigateClick}
              isBookmarkedColumn={isBookmarkedColumn}
              loading={loading}
              onDragEnd={onDragEnd}
            />
          ) : (
            <JobListView
              tableVisibility={tableVisibility}
              toggleTableVisibility={toggleTableVisibility}
              onDragEnd={onDragEnd}
              onJobNavigateClick={onJobNavigateClick}
              columns={columns}
              loading={loading}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
}

export default JobTracker;
