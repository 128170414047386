import { showSuccessMessage, showWarningMessage } from '../../../_component/MessageSnack';
import axiosInstance from '../../../_config/AxiosInstance';
import config from '../../../_config/config';
import { userProfessionConstants } from '../../constant';

export const fetch_user_professions_action = () => async (dispatch) => {
  dispatch({
    type: userProfessionConstants.FETCH_USER_PROFESSIONS_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.profession.getProfessions);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userProfessionConstants.FETCH_USER_PROFESSIONS_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userProfessionConstants.FETCH_USER_PROFESSIONS_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const create_user_profession_action = (data) => async (dispatch) => {
  dispatch({
    type: userProfessionConstants.CREATE_USER_PROFESSION_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(`${config.profession.getProfession}`, data);
    if (response.status) {
      let data = response.data;
      showSuccessMessage(data.message);
      dispatch({
        type: userProfessionConstants.CREATE_USER_PROFESSION_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userProfessionConstants.CREATE_USER_PROFESSION_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const update_user_profession_action = (id, data) => async (dispatch) => {
  dispatch({
    type: userProfessionConstants.UPDATE_USER_PROFESSION_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.put(`${config.profession.getProfession}/${id}`, data);
    if (response.status) {
      let data = response.data;
      showSuccessMessage(data.message);
      dispatch({
        type: userProfessionConstants.UPDATE_USER_PROFESSION_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userProfessionConstants.UPDATE_USER_PROFESSION_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const delete_user_profession_action = (id) => async (dispatch) => {
  dispatch({
    type: userProfessionConstants.DELETE_USER_PROFESSION_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.delete(`${config.profession.getProfession}/${id}`);
    if (response.status) {
      let data = response.data;
      showSuccessMessage(data.message);
      dispatch({
        type: userProfessionConstants.DELETE_USER_PROFESSION_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userProfessionConstants.DELETE_USER_PROFESSION_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};
