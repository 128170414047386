import React from 'react';
import MainLayout from '../../../_layout/MainLayout';
import useFineTunedResume from './useFineTunedResume';
import FineTunedResumeComponent from './_components/FineTunedResumeComponent';
import { Progress, Spin, Tabs } from 'antd';
import FineTunedResumeFormDetails from './_components/FineTunedResumeFormDetails';
import { navigateRoutes } from '../../../_config/route';
import FineTunedResumeFormExperience from './_components/FineTunedResumeFormExperience';
import FineTunedResumeFormSkills from './_components/FineTunedResumeFormSkills';
import FineTunedResumeFormEducation from './_components/FineTunedResumeFormEducation';
import SkeletonLoader from './_components/SkeletonLoader';
import FineTuneResumeCoverLetter from './_components/FineTuneResumeCoverLetter';

const { TabPane } = Tabs;

function FineTunedResume(props) {
  const {
    fineTunedResumeForm,
    fineTuneResumeLoading,
    parsedResumeForm,
    fineTuneResumeUpdateLoading,
    fineTunedResumeComponentRef,
    mockInterviewLoading,
    navigate,
    activeTab,
    tabList,

    fineTunedResumeProcessed,

    setActiveTab,
    handleNextClick,
    handleBackClick,

    onCoverLetterChange,
    onFineTunedResumeSaveClick,
    onParseResumeChange,
    onParseResumeExperienceAddClick,
    onParseResumeExperienceChange,
    onParseResumeEducationAddClick,
    onParseResumeEducationChange,
    onParseResumeSkillAddClick,
    onParseResumeSkillChange,
    onParseResumeExperienceDescriptionChange,
    onParseResumeExperienceDescriptionAddClick,
    onParseResumeExperienceDescriptionRemoveClick,
    onParseResumeExperienceRemoveClick,
    onParseResumeEducationRemoveClick,
    onParseResumeSkillRemoveClick,
    onDownloadFineTunedResumeClick,
    onGenerateMockInterviewClick,

    userResume,
    selectedResume,
    onResumeSelectClick,
    getProgressBarVariant,
  } = useFineTunedResume(props);

  return (
    <MainLayout activeMenuItem="home">
      {fineTuneResumeLoading ? (
        <SkeletonLoader />
      ) : (
        <div>
          {fineTunedResumeForm?.resume?.parsed_value ? (
            <div className=" flex flex-col h-screen min-[768px]:border-l">
              <div className="w-full h-[8%] bg-[#FFFFFF] centered justify-between px-4 fined-tune-resume-container border-b">
                <button
                  className="btn bg-gray-800 text-white font-medium rounded-full hover:bg-gray-600"
                  onClick={() => navigate(navigateRoutes.resumeBuilder)}
                >
                  Close
                </button>
                <Tabs activeKey={activeTab} onChange={setActiveTab} tabBarGutter={2} tabPosition="top">
                  <TabPane tab="Your details" key="Your details" />
                  <TabPane tab="Skills" key="Skills" />
                  <TabPane tab="Experience" key="Experience" />
                  <TabPane tab="Education" key="Education" />
                  <TabPane tab="Cover Letter" key="Cover Letter" />
                </Tabs>
                <button
                  className="btn bg-blue-800 text-white font-medium rounded-full hover:bg-blue-600"
                  type="button"
                  onClick={onDownloadFineTunedResumeClick}
                >
                  Download
                </button>
              </div>

              <div className="w-full h-[92%] max-h-[92%] bg-red-800 grid grid-cols-2 flex-grow">
                <div className="bg-[#ffffff] overflow-hidden">
                  <div className="flex flex-col items-center justify-between h-full py-4 px-8">
                    <div className="w-full py-2 overflow-y-auto mb-2">
                      <h2 className="text-2xl font-semibold">
                        Fine Tune Resume: {fineTunedResumeForm?.job?.title || fineTunedResumeForm?.job_title}{' '}
                      </h2>
                      {activeTab === 'Your details' && (
                        <FineTunedResumeFormDetails
                          onParseResumeChange={onParseResumeChange}
                          parsedResumeForm={parsedResumeForm}
                        />
                      )}
                      {activeTab === 'Skills' && (
                        <FineTunedResumeFormSkills
                          parsedResumeForm={parsedResumeForm}
                          onParseResumeSkillChange={onParseResumeSkillChange}
                          onParseResumeSkillRemoveClick={onParseResumeSkillRemoveClick}
                          onParseResumeSkillAddClick={onParseResumeSkillAddClick}
                        />
                      )}
                      {activeTab === 'Experience' && (
                        <FineTunedResumeFormExperience
                          onParseResumeExperienceAddClick={onParseResumeExperienceAddClick}
                          onParseResumeExperienceRemoveClick={onParseResumeExperienceRemoveClick}
                          parsedResumeForm={parsedResumeForm}
                          onParseResumeExperienceChange={onParseResumeExperienceChange}
                          onParseResumeExperienceDescriptionAddClick={onParseResumeExperienceDescriptionAddClick}
                          onParseResumeExperienceDescriptionChange={onParseResumeExperienceDescriptionChange}
                          onParseResumeExperienceDescriptionRemoveClick={onParseResumeExperienceDescriptionRemoveClick}
                        />
                      )}
                      {activeTab === 'Education' && (
                        <FineTunedResumeFormEducation
                          parsedResumeForm={parsedResumeForm}
                          onParseResumeEducationAddClick={onParseResumeEducationAddClick}
                          onParseResumeEducationChange={onParseResumeEducationChange}
                          onParseResumeEducationRemoveClick={onParseResumeEducationRemoveClick}
                        />
                      )}
                      {activeTab === 'Cover Letter' && (
                        <FineTuneResumeCoverLetter
                          onCoverLetterChange={onCoverLetterChange}
                          fineTunedResumeForm={fineTunedResumeForm}
                        />
                      )}
                    </div>
                    <div className="w-full pb-2 flex flex-row items-center justify-between">
                      <button
                        className="btn border text-gray-950 rounded-full"
                        onClick={handleBackClick}
                        disabled={tabList.indexOf(activeTab) === 0}
                      >
                        Back
                      </button>
                      <button
                        className="btn border bg-[#f4a767] text-gray-950 rounded-full hover:bg-[#f4a767]"
                        onClick={() => {
                          // Always call the save function first
                          onFineTunedResumeSaveClick();

                          // If it's not the last tab, also move to the next tab
                          if (tabList.indexOf(activeTab) !== tabList.length - 1) {
                            handleNextClick();
                          }
                        }}
                        disabled={fineTuneResumeUpdateLoading}
                      >
                        {fineTuneResumeUpdateLoading ? (
                          <Spin size="small" className="mx-4" />
                        ) : tabList.indexOf(activeTab) === tabList.length - 1 ? (
                          'Save'
                        ) : (
                          'Save & Next'
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="bg-[#c8c8c8] flex items-center justify-center py-4 overflow-hidden">
                  <FineTunedResumeComponent
                    parsedResumeForm={parsedResumeForm}
                    fineTunedResumeComponentRef={fineTunedResumeComponentRef}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center w-full h-screen">
              <div className="p-4 m-3 text-center">
                <Progress
                  percent={fineTunedResumeProcessed?.progress_percent || 25}
                  status={getProgressBarVariant() === 'danger' ? 'exception' : 'active'}
                  showInfo={true}
                />
                <p>Fine tuned resume is still being processed. Please reload after some time.</p>
              </div>
            </div>
          )}
        </div>
      )}
    </MainLayout>
  );
}

export default FineTunedResume;
