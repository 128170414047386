import React from 'react';
import MainLayout from '../../../_layout/MainLayout';
import useResumeBuilder from './useResumeBuilder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faLink, faTrash } from '@fortawesome/free-solid-svg-icons';
import './ResumeBuilder.scss';
import { Button, Modal, Spin } from 'antd';
import InputField from '../../../_component/InputField';

function ResumeBuilder(props) {
  const {
    loading,
    userResumes,
    resumeForm,
    resumeModalOpen,
    fineTuneModelOpen,
    fineTuneResumeForm,
    fineTunedResumes,

    onImageUpload,
    onInputChange,
    onResumeSave,
    onResumeDelete,
    onResumeEdit,
    handleResumeModalOpen,
    onResumeNameEdit,
    onResumeViewClick,
    onFineTuneResumeSave,
    onFineTuneFormChange,
    handleFineTuneModalOpen,
    onFineTuneResumeViewClick,
  } = useResumeBuilder(props);
  return (
    <MainLayout activeMenuItem="home">
      <div className="resume-builder">
        <div className="centered builder-top-boxes window-container">
          <h2 className="window-title">Resume Builder</h2>
          <div className="centered buttons">
            <div className="centered">
              <button className="btn btn-resume" onClick={() => handleResumeModalOpen({})}>
                Add Resume
              </button>
            </div>
            <div className="centered">
              <button className="btn btn-resume" onClick={() => handleFineTuneModalOpen({})}>
                Fine Tune Resume
              </button>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="centered spinner-box">
            <Spin size="large" />
          </div>
        ) : (
          <div className="centered  view-box">
            <div className="applied-job-box">
              <table className="table-box">
                <thead>
                  <tr className="w-full">
                    <th className="table-head-serial-number">
                      <div className="centered">#</div>
                    </th>
                    <th className="table-head-job">
                      <div className="centered ">Name</div>
                    </th>
                    <th className="table-head-common">
                      <div className="centered ">Applied to Jobs</div>
                    </th>
                    <th className="table-head-common">
                      <div className="centered ">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(userResumes) &&
                    userResumes.map((item, index) => (
                      <>
                        <tr className="table-data-row">
                          <td className="serial-number">{index + 1}</td>
                          {resumeForm?._id === item._id ? (
                            <td className="border border-gray-200">
                              <InputField
                                type="text"
                                placeholder="Enter name"
                                name="name"
                                defaultValue={resumeForm.name}
                                onBlur={(e) => onResumeNameEdit(e.target.value)}
                                inputClassName="centered text-base border-r"
                              />
                            </td>
                          ) : (
                            <td className="centered job-name">
                              <div>{item?.name}</div>
                              <span>
                                <FontAwesomeIcon className="icon" icon={faEdit} onClick={() => onResumeEdit(item)} />
                              </span>
                            </td>
                          )}
                          <td className="table-data-common ">
                            <div>{item?.jobs}</div>
                          </td>
                          <td className="table-data-last ">
                            <div className="action centered">
                              <FontAwesomeIcon className="icon" icon={faTrash} onClick={() => onResumeDelete(item)} />
                              <FontAwesomeIcon
                                className="icon"
                                icon={faEdit}
                                onClick={() => handleResumeModalOpen(item)}
                              />
                              <FontAwesomeIcon className="icon" icon={faEye} onClick={() => onResumeViewClick(item)} />
                            </div>
                          </td>
                        </tr>
                      </>
                    ))}
                </tbody>
              </table>
            </div>

            <div className="resume-box">
              <table className="table-box">
                <thead>
                  <tr className="w-full">
                    <th className="table-head-serial-number">
                      <div className="centered">#</div>
                    </th>
                    <th className="table-head-job">
                      <div className="centered ">Job Title</div>
                    </th>
                    <th className="table-head-common">
                      <div className="centered ">Company</div>
                    </th>
                    <th className="table-head-common">
                      <div className="centered ">Resume</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(fineTunedResumes) &&
                    fineTunedResumes.map((item, index) => (
                      <>
                        <tr className="table-data-row">
                          <td className="serial-number">{index + 1}</td>

                          <td className="job-title">
                            <div>{item?.job?.title || item?.job_title}</div>
                          </td>
                          <td className="table-data-common">{item?.ai_platform}</td>

                          <td className="table-data-last">
                            <div className="text-center">
                              <FontAwesomeIcon
                                className="icon"
                                icon={faEye}
                                onClick={() => onFineTuneResumeViewClick(item)}
                              />
                            </div>
                          </td>
                        </tr>
                      </>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* Resume Modal */}
        <Modal
          title={resumeForm?.name ? `Edit ${resumeForm?.name}` : 'Add New Resume'}
          open={resumeModalOpen}
          onCancel={() => handleResumeModalOpen({})}
          footer={[
            <Button className="p-4 font-medium" key="save" onClick={onResumeSave}>
              Save
            </Button>,
          ]}
        >
          {resumeForm && (
            <div className="mt-4 space-y-3 ">
              <InputField
                id="name"
                label="Name"
                type="text"
                name="name"
                placeholder="Enter name"
                defaultValue={resumeForm?.name}
                onBlur={onInputChange}
                containerClassName="flex flex-col"
                inputClassName="p-2 border rounded bg-transparent"
                labelClassName="mb-1 font-medium text-gray-700"
                required={true}
              />

              <div className="flex flex-col">
                <label className="mb-1 font-medium text-gray-700">
                  <span className="text-red-500">*</span> Resume File:{' '}
                  {resumeForm?.resume && (
                    <a href={resumeForm.resume}>
                      <FontAwesomeIcon icon={faLink} /> See Resume
                    </a>
                  )}
                </label>
                <input
                  type="file"
                  name="resume"
                  accept="application/pdf"
                  onChange={onImageUpload}
                  className="p-2 rounded w-full"
                />
              </div>
              <InputField
                id="parsed_value"
                label="Resume Content"
                name="parsed_value"
                inputType="textarea"
                rows={8}
                placeholder="Enter content"
                defaultValue={resumeForm?.parsed_value}
                onBlur={onInputChange}
                containerClassName="flex flex-col"
                inputClassName="p-2 border rounded bg-transparent"
                labelClassName="mb-1 font-medium text-gray-700"
                required={true}
              />
            </div>
          )}
        </Modal>

        {/* Fine Tune Modal */}
        <Modal
          title="Fine Tune Resume "
          open={fineTuneModelOpen}
          onCancel={() => handleFineTuneModalOpen({})}
          footer={[
            <Button className="p-4 font-medium" onClick={() => onFineTuneResumeSave('OPENAI')}>
              Open AI
            </Button>,
            // <Button className="p-4 font-medium" onClick={() => onFineTuneResumeSave('CLOUDEAI')}>
            //   Cloude AI
            // </Button>,
          ]}
        >
          <div className="mt-4 space-y-3">
            <InputField
              id="job_title"
              label="Job Title"
              type="text"
              name={'job_title'}
              placeholder="Enter Title"
              defaultValue={fineTuneResumeForm?.job_title}
              onChange={onFineTuneFormChange}
              containerClassName="flex flex-col"
              inputClassName="p-2 border rounded bg-transparent"
              labelClassName="mb-1 font-medium text-gray-700"
              required={true}
            />
            <div className="flex flex-col">
              <label className="mb-1 font-medium text-gray-700">
                <span className="text-red-500">*</span> Select Resume
              </label>
              <select
                onChange={onFineTuneFormChange}
                name="resume"
                value={fineTuneResumeForm?.resume || ''}
                className="p-2 border rounded bg-transparent"
              >
                <option value="" disabled={!fineTuneResumeForm?.resume}>
                  Select resume
                </option>
                {Array.isArray(userResumes) &&
                  userResumes.map((item) => (
                    <option key={item._id} value={item._id} selected={fineTuneResumeForm?.resume === item._id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <InputField
              id="job_description"
              label="Job Description"
              name={'job_description'}
              inputType="textarea"
              rows={10}
              placeholder="Enter content"
              defaultValue={fineTuneResumeForm?.job_description}
              onChange={onFineTuneFormChange}
              containerClassName="flex flex-col"
              inputClassName="p-2 border rounded bg-transparent"
              labelClassName="mb-1 font-medium text-gray-700"
              required={true}
            />
          </div>
        </Modal>
      </div>
    </MainLayout>
  );
}

export default ResumeBuilder;
