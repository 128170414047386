import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { forgot_password_action } from '../../../_redux/action';
import { navigateRoutes } from '../../../_config/route';
import { emailCheck } from '../../../_helpers/formFieldErrorCheck';
import { errorMsg } from '../../../_helpers/errorConst';

function useForgotPassword(props) {
  //initial states
  const [email, setEmail] = useState();
  const [formInputsError, setFormInputsError] = useState({});
  const [loading, setloading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onInputChange = (value) => {
    setEmail(value);
  };

  // submit form
  const onSubmitClick = async (e) => {
    e.preventDefault();
    setloading(true);
    if (emailCheck(email)) {
      const loginData = await dispatch(forgot_password_action({ email }));
      if (loginData) {
        navigate(navigateRoutes.signIn);
      }
      setFormInputsError({});
    } else setFormInputsError({ email: errorMsg.email });

    setloading(false);
  };

  const onSignUpClick = () => {
    navigate(navigateRoutes.signUp);
  };

  const checkFieldError = (val) => {
    if (!emailCheck(val)) setFormInputsError({ email: errorMsg.email });
    else setFormInputsError({});
  };

  return {
    formInputsError,
    loading,

    onInputChange,
    onSubmitClick,
    onSignUpClick,
    checkFieldError,
  };
}

export default useForgotPassword;
