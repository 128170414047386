import axiosInstance from '../../../_config/AxiosInstance';
import config from '../../../_config/config';
import { masterConstants } from '../../constant';

export const fetch_skills_action = () => async (dispatch) => {
  dispatch({
    type: masterConstants.FETCH_SKILLS_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.master.skills);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: masterConstants.FETCH_SKILLS_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: masterConstants.FETCH_SKILLS_ACTION_FAILURE,
      payload: e?.error,
    });
    return e;
  }
};

export const fetch_headlines_action = () => async (dispatch) => {
  dispatch({
    type: masterConstants.FETCH_HEADLINES_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.master.headlines);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: masterConstants.FETCH_HEADLINES_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: masterConstants.FETCH_HEADLINES_ACTION_FAILURE,
      payload: e?.error,
    });
    return e;
  }
};

export const fetch_colleges_action = () => async (dispatch) => {
  dispatch({
    type: masterConstants.FETCH_COLLEGES_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.master.colleges);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: masterConstants.FETCH_COLLEGES_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: masterConstants.FETCH_COLLEGES_ACTION_FAILURE,
      payload: e?.error,
    });
    return e;
  }
};

export const fetch_cities_action = (id) => async (dispatch) => {
  dispatch({
    type: masterConstants.FETCH_CITIES_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.master.cities.replace(':id', id));
    if (response.status) {
      let data = response.data;
      dispatch({
        type: masterConstants.FETCH_CITIES_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: masterConstants.FETCH_CITIES_ACTION_FAILURE,
      payload: e?.error,
    });
    return e;
  }
};

export const fetch_regions_action = (id) => async (dispatch) => {
  dispatch({
    type: masterConstants.FETCH_REGIONS_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.master.regions.replace(':id', id));
    if (response.status) {
      let data = response.data;
      dispatch({
        type: masterConstants.FETCH_REGIONS_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: masterConstants.FETCH_REGIONS_ACTION_FAILURE,
      payload: e?.error,
    });
    return e;
  }
};

export const fetch_countries_action = () => async (dispatch) => {
  dispatch({
    type: masterConstants.FETCH_COUNTRIES_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.master.countries);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: masterConstants.FETCH_COUNTRIES_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: masterConstants.FETCH_COUNTRIES_ACTION_FAILURE,
      payload: e?.error,
    });
    return e;
  }
};

export const fetch_companies_action = () => async (dispatch) => {
  dispatch({
    type: masterConstants.FETCH_COMPANIES_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.master.companies);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: masterConstants.FETCH_COMPANIES_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: masterConstants.FETCH_COMPANIES_ACTION_FAILURE,
      payload: e?.error,
    });
    return e;
  }
};

export const fetch_courses_action = () => async (dispatch) => {
  dispatch({
    type: masterConstants.FETCH_COURSES_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.master.courses);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: masterConstants.FETCH_COURSES_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: masterConstants.FETCH_COURSES_ACTION_FAILURE,
      payload: e?.error,
    });
    return e;
  }
};

export const fetch_course_type_action = () => async (dispatch) => {
  dispatch({
    type: masterConstants.FETCH_COURSE_TYPES_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.master.courseTypes);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: masterConstants.FETCH_COURSE_TYPES_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: masterConstants.FETCH_COURSE_TYPES_ACTION_FAILURE,
      payload: e?.error,
    });
    return e;
  }
};

export const fetch_pronouns_action = () => async (dispatch) => {
  dispatch({
    type: masterConstants.FETCH_PRONOUNS_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.master.pronouns);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: masterConstants.FETCH_PRONOUNS_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: masterConstants.FETCH_PRONOUNS_ACTION_FAILURE,
      payload: e?.error,
    });
    return e;
  }
};
