import { authConstants } from '../../constant';

let user = JSON.parse(localStorage.getItem('aiclues-user'));

const initialState = { loggedIn: false, user, error: '' };

export const signin_user_reducer = (state = initialState, action) => {
  switch (action.type) {
    case authConstants.SIGNIN_USER_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case authConstants.SIGNIN_USER_ACTION_SUCCESS:
      return {
        loggedIn: true,
        user: action.payload,
      };
    case authConstants.SIGNIN_USER_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const signup_user_reducer = (state = initialState, action) => {
  switch (action.type) {
    case authConstants.SIGNUP_USER_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case authConstants.SIGNUP_USER_ACTION_SUCCESS:
      return {
        loggedIn: true,
        user: action.payload,
      };
    case authConstants.SIGNUP_USER_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        message: action.payload,
      };
    default:
      return state;
  }
};

export const logout_user_reducer = (state = initialState, action) => {
  switch (action.type) {
    case authConstants.LOGOUT_USER_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case authConstants.LOGOUT_USER_ACTION_SUCCESS:
      return {
        loggedIn: false,
        user: null,
      };
    case authConstants.LOGOUT_USER_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        message: action.payload,
      };
    default:
      return state;
  }
};
