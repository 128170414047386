import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sigin_user_action } from '../../../_redux/action';
import { navigateRoutes } from '../../../_config/route';
import { emailCheck, emptyStringCheck, passwordCheck } from '../../../_helpers/formFieldErrorCheck';
import { errorMsg } from '../../../_helpers/errorConst';

function useSignIn(props) {
  //initial states
  const [formData, setformData] = useState({});
  const [formInputsError, setFormInputsError] = useState({});
  const [loading, setloading] = useState(false);
  const [showPassword, setshowPassword] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  React.useEffect(() => {
    setformData({
      email: '',
      password: '',
    });
  }, []);

  //set states on input change
  const onInputChange = (value, key) => {
    let data = formData;
    data[key] = value;
    setformData(data);
  };

  // submit form
  const onSubmitClick = async (e) => {
    e.preventDefault();
    setloading(true);

    setFormInputsError({});

    if (validateForm()) {
      const loginData = await dispatch(sigin_user_action(formData));

      if (loginData?.data) {
        navigate(navigateRoutes.candidateDashboard);
      }
    }
    setloading(false);
  };

  const handleShowPassword = (key) => {
    setshowPassword(!showPassword);
  };
  const checkFieldError = (val, name) => {
    if (emptyStringCheck(val)) {
      setFormInputsError({ ...formInputsError, [name]: errorMsg.emptyField });
    } else {
      setFormInputsError({ ...formInputsError, [name]: '' });
    }
    if (name === 'email') {
      if (!emailCheck(val)) setFormInputsError({ ...formInputsError, email: errorMsg.email });
    }
    if (name === 'password') {
      if (!passwordCheck(val)) setFormInputsError({ ...formInputsError, password: errorMsg.password });
      else setFormInputsError({ ...formInputsError, password: '' });

      return;
    }
  };
  const validateForm = () => {
    let val = true;
    let data = formInputsError;
    for (let i in formData) {
      if (emptyStringCheck(formData[i])) {
        data = { ...data, [i]: errorMsg.emptyField };
        val = false;
      } else if (formInputsError[i] && formInputsError[i].length > 0) {
        val = false;
      }
    }

    setFormInputsError(data);

    return val;
  };

  const onSignUpClick = () => navigate(navigateRoutes.signUp);
  const onForgetPasswordClick = () => navigate(navigateRoutes.forgotPassword);

  return {
    formInputsError,
    loading,
    showPassword,

    onInputChange,
    onSubmitClick,
    handleShowPassword,
    onForgetPasswordClick,
    onSignUpClick,
    checkFieldError,
  };
}

export default useSignIn;
