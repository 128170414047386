import React from 'react';
import EducationEditForm from './EducationEditForm';
import { dateFormatter } from '../../../../_helpers/dateFormatter';
import ProfessionEditForm from './ProfessionEditForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export default ({
  userEducations,
  educationForm,
  addNewEducation,
  onEducationDeleteClick,
  onEducationEditClick,
  onNewEducationClick,
  onEducationFormChange,
  onEducationSave,
  educationFormError,
  professionFormError,

  addNewProfession,
  professionForm,
  userProfessions,
  onProfessionDeleteClick,
  onProfessionEditClick,
  onProfessionFormChange,
  onProfessionSave,
  onNewProfessionClick,
  handleProfessionCheckBoxChange,
}) => {
  return (
    <div className="experience-box profile-container">
      <div className="experience-display profile-box">
        <h3 className="experience-display-title">Professional Information</h3>
        <div className="experience-display-details-box">
          {Array.isArray(userProfessions) && userProfessions.length > 0 ? (
            <div className="experience-card-box">
              {userProfessions.map((item) => (
                <React.Fragment key={item._id}>
                  {item?._id === professionForm?._id ? (
                    <ProfessionEditForm
                      professionForm={professionForm}
                      onProfessionFormChange={onProfessionFormChange}
                      onProfessionSave={onProfessionSave}
                      handleProfessionCheckBoxChange={handleProfessionCheckBoxChange}
                      professionFormError={professionFormError}
                    />
                  ) : (
                    <div className="experience-card-container">
                      <h3 className="experience-title">{item?.company && item.company?.company_name}</h3>
                      <p className="experience-role">{item?.headline}</p>
                      <div className="centered experience-time">
                        {item?.start_date && dateFormatter(item.start_date)} -{' '}
                        {item?.is_current_company ? 'Present' : item?.end_date && dateFormatter(item.end_date)}
                      </div>
                      <div className="experience-description">{item?.description}</div>
                      {!addNewProfession && (
                        <div className="experience-edit-buttons flex items-center">
                          <button className="edit-buttons text-green-800" onClick={() => onProfessionEditClick(item)}>
                            Edit
                          </button>
                          <button className="edit-buttons text-red-800" onClick={() => onProfessionDeleteClick(item)}>
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          ) : (
            <div className="no-experience-message">No experience Added. Please add experience</div>
          )}

          {!addNewProfession && (
            <button className="add-experience-button" onClick={onNewProfessionClick}>
              <FontAwesomeIcon icon={faPlus} /> Add Experience
            </button>
          )}

          {addNewProfession && professionForm._id === undefined && (
            <ProfessionEditForm
              professionForm={professionForm}
              onProfessionFormChange={onProfessionFormChange}
              onProfessionSave={onProfessionSave}
              handleProfessionCheckBoxChange={handleProfessionCheckBoxChange}
              professionFormError={professionFormError}
            />
          )}
        </div>
      </div>

      <div className="experience-display profile-box">
        <h3 className="experience-display-title">Education Information</h3>
        <div className="experience-display-details-box">
          {Array.isArray(userEducations) && userEducations.length > 0 ? (
            <div className="experience-card-box">
              {userEducations.map((item) => (
                <React.Fragment key={item._id}>
                  {item?._id === educationForm?._id ? (
                    <EducationEditForm
                      educationForm={educationForm}
                      onEducationFormChange={onEducationFormChange}
                      onEducationSave={onEducationSave}
                      educationFormError={educationFormError}
                    />
                  ) : (
                    <div className="experience-card-container">
                      <h3 className="experience-title">{item?.college && item.college?.college_name}</h3>
                      <p className="experience-role">{item?.course_type && item.course_type?.display_name}</p>
                      <div className="centered experience-time">
                        {item?.major && item.major?.display_name} - GPA: {item?.gpa}
                      </div>
                      <div className="experience-description">{item?.end_date && dateFormatter(item.end_date)}</div>
                      {!addNewEducation && (
                        <div className="experience-edit-buttons flex items-center">
                          <button className="edit-buttons text-green-800" onClick={() => onEducationEditClick(item)}>
                            Edit
                          </button>
                          <button className="edit-buttons text-red-800" onClick={() => onEducationDeleteClick(item)}>
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          ) : (
            <div className="no-experience-message">No education added. Please add education.</div>
          )}

          {!addNewEducation && (
            <button className="add-experience-button" onClick={onNewEducationClick}>
              <FontAwesomeIcon icon={faPlus} /> Add Education
            </button>
          )}

          {addNewEducation && educationForm._id === undefined && (
            <EducationEditForm
              educationForm={educationForm}
              onEducationFormChange={onEducationFormChange}
              onEducationSave={onEducationSave}
              educationFormError={educationFormError}
            />
          )}
        </div>
      </div>
    </div>
  );
};
