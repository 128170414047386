import { message } from 'antd';
import { userCompanyBookmarkConstants } from '../../constant';

const initialState = {
  bookmarkedCompaniesData: null,
  bookmarkedCompanyData: null,
};

export const fetch_user_company_bookmarks_reducer = (state = initialState, action) => {
  switch (action.type) {
    case userCompanyBookmarkConstants.FETCH_USER_COMPANY_BOOKMARKS_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case userCompanyBookmarkConstants.FETCH_USER_COMPANY_BOOKMARKS_ACTION_SUCCESS:
      return {
        bookmarkedCompaniesData: action.payload,
      };
    case userCompanyBookmarkConstants.FETCH_USER_COMPANY_BOOKMARKS_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    // Handle creation success
    case userCompanyBookmarkConstants.CREATE_USER_BOOKMARK_COMPANY_ACTION_SUCCESS:
      return {
        ...state,
        bookmarkedCompaniesData: [...state.bookmarkedCompaniesData, action.payload],
      };

    default:
      return state;
  }
};

export const fetch_user_company_details_bookmark_reducer = (state = initialState, action) => {
  switch (action.type) {
    case userCompanyBookmarkConstants.FETCH_USER_COMPANY_DETAILS_BOOKMARK_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case userCompanyBookmarkConstants.FETCH_USER_COMPANY_DETAILS_BOOKMARK_ACTION_SUCCESS:
    case userCompanyBookmarkConstants.UPDATE_USER_BOOKMARK_COMPANY_ACTION_SUCCESS:
      return {
        bookmarkedCompanyData: action.payload,
      };
    case userCompanyBookmarkConstants.FETCH_USER_COMPANY_DETAILS_BOOKMARK_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};
