import { faCircleMinus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import InputField from '../../../../_component/InputField';

function FineTunedResumeFormEducation({
  parsedResumeForm,
  onParseResumeEducationAddClick,
  onParseResumeEducationChange,
  onParseResumeEducationRemoveClick,
}) {
  return (
    <div className="my-8 max-[768px]:my-4 ">
      {Array.isArray(parsedResumeForm?.educations) &&
        parsedResumeForm.educations.map((item, index) => (
          <div className="bg-slate-100 rounded-lg mb-2 p-2" key={item?.company_name}>
            <div className="flex flex-row w-full justify-between items-center">
              <div className="grid grid-cols-2 gap-2 mb-2">
                <InputField
                  id={`degree_name-${index}`}
                  label="Degree name"
                  type="text"
                  name="degree_name"
                  placeholder="Enter degree"
                  defaultValue={item?.degree_name}
                  onBlur={(e) => onParseResumeEducationChange(index, e)}
                  containerClassName="form-group flex flex-col"
                  inputClassName="rounded-2xl border  border-gray-800 p-1.5"
                  labelClassName=" "
                />
                <InputField
                  id={`college_name-${index}`}
                  label="College name"
                  type="text"
                  name="college_name"
                  placeholder="Enter college name"
                  defaultValue={item?.college_name}
                  onBlur={(e) => onParseResumeEducationChange(index, e)}
                  containerClassName="form-group flex flex-col"
                  inputClassName="rounded-2xl border border-gray-800 p-1.5"
                  labelClassName=""
                />
                <InputField
                  id={`start_date-${index}`}
                  label="Start Date"
                  type="text"
                  name="start_date"
                  placeholder="Enter start date"
                  defaultValue={item?.start_date}
                  onBlur={(e) => onParseResumeEducationChange(index, e)}
                  containerClassName="form-group flex flex-col "
                  inputClassName="rounded-2xl border border-gray-800 p-1.5"
                  labelClassName=""
                />
                <InputField
                  id={`end_date-${index}`}
                  label="End Date"
                  type="text"
                  name="end_date"
                  placeholder="Enter end date"
                  defaultValue={item?.end_date}
                  onBlur={(e) => onParseResumeEducationChange(index, e)}
                  containerClassName="form-group flex flex-col"
                  inputClassName="rounded-2xl border border-gray-800 p-1.5"
                  labelClassName=""
                />
              </div>
              <span className="self-start">
                <FontAwesomeIcon
                  icon={faCircleMinus}
                  className="ml-5 text-danger cursor-pointer"
                  onClick={() => onParseResumeEducationRemoveClick(index)}
                />
              </span>
            </div>
          </div>
        ))}
      <div className="flex flex-row items-center w-full ">
        <h2 className="text-gray-600 font-medium ">Add Education</h2>{' '}
        <FontAwesomeIcon
          icon={faPlusCircle}
          className="ml-2 h-4 text-gray-600 cursor-pointer"
          onClick={onParseResumeEducationAddClick}
        />
      </div>
    </div>
  );
}

export default FineTunedResumeFormEducation;
