/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import './EditCandidateProfile.scss';
import useEditCandidateProfile from './useEditCandidateProfile';
import MainLayout from '../../../_layout/MainLayout';
import PersonalProfile from './_components/PersonalProfile';
import ExperienceAndEducation from './_components/ExperienceAndEducation';
import { Spin, Tabs } from 'antd';

export default (props) => {
  const {
    user,
    loading,
    userSkills,
    userEducations,
    educationForm,
    addNewEducation,
    addNewProfession,
    professionForm,
    userProfessions,
    imageUploadLoading,
    educationFormError,
    professionFormError,
    fileImageInputRef,

    updateUserMetaInfo,
    updateUserProfile,
    onSkillCreate,
    onSkillUpdate,
    onSkillDeleteClick,
    onEducationFormChange,
    onEducationDeleteClick,
    onEducationEditClick,
    onNewEducationClick,
    onEducationSave,
    onProfessionDeleteClick,
    onProfessionEditClick,
    onProfessionFormChange,
    onProfessionSave,
    onNewProfessionClick,
    handleProfessionCheckBoxChange,
    onImageUpload,
    onVerifyEmailClick,
    handleImageInputClick,
  } = useEditCandidateProfile(props);

  const profileTabs = [
    {
      label: 'Profile',
      key: 'PersonalInformation',
      children: (
        <PersonalProfile
          user={user}
          loading={loading}
          onUserMetaEdit={updateUserMetaInfo}
          onProfileEdit={updateUserProfile}
          userSkills={userSkills}
          onSkillCreate={onSkillCreate}
          onSkillUpdate={onSkillUpdate}
          onSkillDeleteClick={onSkillDeleteClick}
          onImageUpload={onImageUpload}
          imageUploadLoading={imageUploadLoading}
          onVerifyEmailClick={onVerifyEmailClick}
          fileImageInputRef={fileImageInputRef}
          handleImageInputClick={handleImageInputClick}
        />
      ),
    },
    {
      label: 'Experience & Education',
      key: 'Experience',
      children: (
        <ExperienceAndEducation
          userEducations={userEducations}
          educationForm={educationForm}
          addNewEducation={addNewEducation}
          onEducationSave={onEducationSave}
          onEducationDeleteClick={onEducationDeleteClick}
          onEducationEditClick={onEducationEditClick}
          onNewEducationClick={onNewEducationClick}
          onEducationFormChange={onEducationFormChange}
          addNewProfession={addNewProfession}
          professionForm={professionForm}
          userProfessions={userProfessions}
          onProfessionDeleteClick={onProfessionDeleteClick}
          onProfessionEditClick={onProfessionEditClick}
          onProfessionFormChange={onProfessionFormChange}
          onProfessionSave={onProfessionSave}
          onNewProfessionClick={onNewProfessionClick}
          handleProfessionCheckBoxChange={handleProfessionCheckBoxChange}
          educationFormError={educationFormError}
          professionFormError={professionFormError}
        />
      ),
    },
  ];

  return (
    <MainLayout activeMenuItem="home">
      <div className="profile-page-container centered">
        <span className="profile-page-title">Profile Settings</span>
        {loading ? (
          <div className="spinner-box">
            <Spin size="large" />
          </div>
        ) : (
          <div className="profile-details-box">
            <div className="active-tab-box max-[1024px]:hidden">
              <Tabs
                defaultActiveKey="PersonalInformation"
                items={profileTabs}
                tabPosition={'left'}
                className="custom-tabs"
              />
            </div>
            <div className="active-tab-box hidden max-[1024px]:block">
              <Tabs
                defaultActiveKey="PersonalInformation"
                items={profileTabs}
                tabPosition={'top'}
                className="custom-tabs"
              />
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
};
