/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetch_cities_action } from '../_redux/action/_master';

const CitySearchBasic = ({ defaultValue, onSearchChange, region_value }) => {
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  const { cities } = useSelector((state) => state.fetch_cities_reducer);

  const ref = useRef();
  useEffect(() => {
    if (ref?.current && ref?.current?.value) {
      ref.current.value = defaultValue;
    }
  }, []);

  useEffect(() => {
    const callApi = async () => {
      if (region_value) await dispatch(fetch_cities_action(region_value));
    };
    callApi();
  }, [region_value]);

  useEffect(() => {
    if (cities?.cities) {
      setOptions(cities.cities);
    }
  }, [cities]);

  return (
    <Select
      ref={ref}
      showSearch
      value={defaultValue}
      className="px-0 relative w-full"
      placeholder={'Search City'}
      allowClear={true}
      options={(options || []).map((d) => ({
        value: d?._id,
        label: `${d.display_name}`,
      }))}
      getPopupContainer={(trigger) => trigger.parentNode}
      filterOption={(input, option) => (option.label.toLowerCase() ?? '').includes(input.toLowerCase())}
      onSelect={onSearchChange}
    />
  );
};
export default CitySearchBasic;
