/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import useSignIn from './useSignIn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserClock } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../../_component/InputField';
import TestimonialSwiper from '../../../_component/TestimonialSwiper';
import { Spin } from 'antd';

export default (props) => {
  const {
    loading,
    formInputsError,
    showPassword,

    onInputChange,
    onSubmitClick,
    handleShowPassword,
    onForgetPasswordClick,
    onSignUpClick,
    checkFieldError,
  } = useSignIn(props);

  return (
    <div className="auth-box centered">
      <div className="centered auth-box-left">
        <div className="auth-left-logo-box ">
          <span className="auth-left-logo-icon">
            <FontAwesomeIcon icon={faUserClock} />
          </span>
          <span className="auth-left-logo-text">Interview&</span>
        </div>
        <form className="centered auth-left-form" onSubmit={onSubmitClick}>
          <div className="auth-form-greet">
            <h1 className="auth-form-greet-welcome">Welcome to Ai Clues</h1>
            <p className="auth-form-detail">Welcome back! Please enter your details</p>
          </div>
          {/* <button type="button" className="centered auth-from-login-btn">
            <img src="/assets/images/Google-icon.svg" className="mr-1 w-5 h-5" />
            Sign in with Google
          </button> */}
          {/* <div className="centered mb-3 w-full">
            <hr className="h-0 border-b border-solid border-gray-200 grow" />
            <p className="mx-4 text-gray-800">or</p>
            <hr className="h-0 border-b border-solid border-gray-200 grow" />
          </div> */}
          <InputField
            id="email"
            type="text"
            placeholder="Email"
            onChange={(e) => onInputChange(e.target.value, 'email')}
            onBlur={(e) => checkFieldError(e.target.value, 'email')}
            error={formInputsError.email}
            formSubmitError={formInputsError.formSubmitError}
            containerClassName="containerClass"
            inputClassName="inputClass"
            iconClassName="iconClass"
            errorClassName="errorClass"
            fontIconClassName="fontIconClass"
          />
          <InputField
            id="password"
            type="password"
            placeholder="Password"
            onChange={(e) => onInputChange(e.target.value, 'password')}
            onBlur={(e) => checkFieldError(e.target.value, 'password')}
            error={formInputsError.password}
            showPassword={showPassword}
            handleShowPassword={handleShowPassword}
            formSubmitError={formInputsError.formSubmitError}
            containerClassName="containerClass"
            inputClassName="inputClass"
            iconClassName="iconClass"
            errorClassName="errorClass"
            fontIconClassName="fontIconClass"
          />
          <div className="auth-form-forgot-remember">
            <label className="auth-form-remember">
              <input type="checkbox" className="remember-checkbox" />
              <span className="remember-text">Remember for 30 days</span>
            </label>
            <button type="button" onClick={onForgetPasswordClick} className="auth-form-forgot">
              Forgot Password
            </button>
          </div>
          <button className="auth-form-submit-btn" type="submit" value="Submit">
            {loading ? (
              <div className="w-full centered justify-center">
                <Spin size="small" />
              </div>
            ) : (
              'Sign In'
            )}
          </button>
          <p className="auth-form-account">
            Don't have an account?{' '}
            <button type="button" onClick={onSignUpClick} className="auth-form-account-btn">
              Sign up
            </button>
          </p>
        </form>
      </div>
      <div className="auth-box-carousel centered">
        <TestimonialSwiper />
      </div>
    </div>
  );
};
