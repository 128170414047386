import React from 'react';

const dropdownOptions = [
  {
    key: 'Yes',
    value: true,
  },
  {
    key: 'No',
    value: false,
  },
];
function DifferentlyAbledBasic({ onDifferentlyAbledChange, selectedValue }) {
  return (
    <span className="select mt-1">
      <select
        className="select-control bg-transparent"
        onChange={(e) => onDifferentlyAbledChange(e.target.value)}
        name={'veteran'}
      >
        <option disabled={true} value="" selected={selectedValue === undefined}>
          Select
        </option>
        {dropdownOptions.map((item) => (
          <option key={item.key} selected={selectedValue === item.value} value={item.value}>
            {item.key}
          </option>
        ))}
      </select>
    </span>
  );
}

export default DifferentlyAbledBasic;
