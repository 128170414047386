import React from 'react';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Spin, Tooltip } from 'antd';
import { autoApplyStatus } from '../../../../_helpers/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

function JobBorderView({
  isBookmarkedColumn,
  loading,
  columns,
  markJobsForAutoApply,
  autoApplyJobIds,
  onJobNavigateClick,
  onDragEnd,
}) {
  return (
    <div className="border-view-box">
      <DragDropContext onDragEnd={onDragEnd} className="flex ">
        {Object.entries(columns).map(([columnId, column], index) => (
          <div className="border-col-box" key={columnId}>
            <Droppable droppableId={columnId} key={columnId} index={index} className="">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    background: snapshot.isDraggingOver ? '#F4F5F7' : 'white',
                  }}
                  className="column-container"
                >
                  <h3 className="column-title" style={{ color: column.color }}>
                    {column?.title}
                  </h3>
                  {loading ? (
                    <div className="centered spinner-box">
                      <Spin size="small" />
                    </div>
                  ) : (
                    <div>
                      {Array.isArray(column.items) && column.items.length ? (
                        <div className="p-1">
                          {column.items &&
                            column.items.map((item, index) => (
                              <Draggable draggableId={item._id} index={index} key={item._id}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    snapshot={snapshot}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="col-cards centered "
                                    style={{
                                      userSelect: 'none',
                                      backgroundColor: snapshot.isDragging ? '#e8e9eb' : '#ffffff',
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    <div className="centered card-jobs-info">
                                      <a
                                        href={item?.bookmark_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="job-link"
                                      >
                                        <FontAwesomeIcon icon={faEye} />
                                      </a>
                                      <div className="job-details">
                                        <span className="study job-title" onClick={() => onJobNavigateClick(item?._id)}>
                                          {item?.title}
                                          {'  '}
                                        </span>

                                        <p className="company-name">{item?.company.replace(/·/g, '').trim()}</p>
                                        <p className="company-location">{item?.location.replace(/·/g, '').trim()}</p>
                                      </div>
                                      {isBookmarkedColumn(columnId) && (
                                        <>
                                          {item?.auto_apply_status === autoApplyStatus.applied ||
                                          item?.auto_apply_status === 'AUTOAPPLYMARKED' ||
                                          autoApplyJobIds.includes(item?._id) ? (
                                            <div>
                                              <button
                                                {...(item?.auto_apply_status !== 'AUTOAPPLYMARKED' && {
                                                  onClick: () => markJobsForAutoApply(item?._id),
                                                })}
                                                className="auto-apply-btn"
                                              >
                                                <Tooltip placement="top" title="Auto apply marked">
                                                  <img
                                                    src="/assets/images/Green-tick.svg"
                                                    alt="Marked"
                                                    className="icon-dim"
                                                  />
                                                </Tooltip>
                                              </button>
                                            </div>
                                          ) : (
                                            <div>
                                              <button
                                                onClick={() => markJobsForAutoApply(item?._id)}
                                                className="auto-apply-btn"
                                              >
                                                <Tooltip placement="top" title="Mark for auto apply">
                                                  <img
                                                    src="/assets/images/add-icon.svg"
                                                    alt="Mark for auto apply"
                                                    className="icon-dim"
                                                  />
                                                </Tooltip>
                                              </button>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </div>
                                    <div className="centered card-jobs-ref">
                                      <span className="job-date">
                                        {item?.updatedAt
                                          ? new Intl.DateTimeFormat('en-GB', {
                                              day: '2-digit',
                                              month: 'long',
                                              year: 'numeric',
                                            }).format(new Date(item?.updatedAt))
                                          : 'No date available'}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {/* <div className="add-more-text">+ Add more</div> */}
                        </div>
                      ) : (
                        <div className="no-jobs">
                          <p>No jobs here yet!</p>
                        </div>
                      )}

                      {provided.placeholder}
                    </div>
                  )}
                </div>
              )}
            </Droppable>
          </div>
        ))}
      </DragDropContext>
    </div>
  );
}

export default JobBorderView;
