export const emailCheck = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const isValidEmail = emailRegex.test(email);
  return isValidEmail;
};

export const urlCheck = (url) => {
  const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  const isValidURL = urlRegex.test(url);
  return isValidURL;
};

export const emptyStringCheck = (string) => {
  const emptyStringRegex = /^\s*$/;
  const isEmptyString = emptyStringRegex.test(string);
  return isEmptyString;
};

export const passwordCheck = (password) => {
  return password.length > 7;
};

export const fieldCheck = (val) => {
  let isValid = val === undefined || val === null;
  return isValid || val.length > 0;
};
