/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  create_user_mock_interview_action,
  fetch_user_fine_tune_resume_action,
  fetch_user_resumes_action,
  regenerate_user_fine_tune_resume_action,
  update_user_fine_tune_resume_action,
} from '../../../_redux/action';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { navigateRoutes } from '../../../_config/route';
import { socket } from '../../../_config/socketio';
import { queueStatusTypes, socketIoEvents } from '../../../_helpers/types';

function useFineTunedResume() {
  const [fineTunedResumeForm, setFineTunedResumeForm] = useState(null);
  const [fineTuneResumeLoading, setFineTuneResumeLoading] = useState(null);
  const [parsedResumeForm, setParsedResumeForm] = useState(null);
  const [fineTuneResumeUpdateLoading, setFineTuneResumeUpdateLoading] = useState(null);
  const [fineTunedResumeProcessed, setFineTunedResumeProcessed] = useState(null);

  const [selectedResume, setSelectedResume] = useState(null);

  const [userResume, setUserResume] = useState(null);

  const [mockInterviewLoading, setMockInterviewLoading] = useState(null);

  const { fineTunedResumeData } = useSelector((state) => state.fetch_user_fine_tune_resume_reducer);

  const { resumes } = useSelector((state) => state.fetch_user_all_resumes_reducer);

  const dispatch = useDispatch();
  const params = useParams();
  const fineTunedResumeComponentRef = useRef();
  const navigate = useNavigate();

  // state for active tab
  const [activeTab, setActiveTab] = useState('Your details');

  // Function to handle switching to next tabs
  const handleNextClick = () => {
    const currentIndex = tabList.indexOf(activeTab);
    if (currentIndex < tabList.length - 1) {
      setActiveTab(tabList[currentIndex + 1]);
    }
  };
  // function to handle switching to previous tabs
  const handleBackClick = () => {
    const currentIndex = tabList.indexOf(activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabList[currentIndex - 1]);
    }
  };

  useEffect(() => {
    const { id } = params;
    const callApi = async () => {
      setFineTuneResumeLoading(true);
      await dispatch(fetch_user_fine_tune_resume_action(id));
      await dispatch(fetch_user_resumes_action());
      setFineTuneResumeLoading(false);
    };

    callApi();
  }, [params]);

  useEffect(() => {
    if (resumes?.resumes) {
      setUserResume(resumes?.resumes);
    }
  }, [resumes]);

  useEffect(() => {
    socket.on(socketIoEvents.fineTuneResume, async (data) => {
      setFineTunedResumeProcessed(data);
    });

    return () => {
      socket.off('connect');
    };
  }, []);

  useEffect(() => {
    const { id } = params;
    const callUpdatedResumeApi = async () => {
      if (fineTunedResumeProcessed?.status === queueStatusTypes.resolved) {
        await dispatch(fetch_user_fine_tune_resume_action(id));
      }
    };

    callUpdatedResumeApi();
  }, [fineTunedResumeProcessed]);

  useEffect(() => {
    if (fineTunedResumeData) {
      setFineTunedResumeForm(fineTunedResumeData?.savedJobFineTunedResume);
      let parsed =
        fineTunedResumeData?.savedJobFineTunedResume?.parsed_value &&
        JSON.parse(fineTunedResumeData?.savedJobFineTunedResume?.parsed_value);
      setParsedResumeForm(parsed);
    }
  }, [fineTunedResumeData]);

  const onFineTunedResumeSaveClick = async () => {
    setFineTuneResumeUpdateLoading(true);
    await dispatch(
      update_user_fine_tune_resume_action(fineTunedResumeForm?._id, {
        parsed_value: JSON.stringify(parsedResumeForm),
      })
    );
    setFineTuneResumeUpdateLoading(false);
  };

  const onParseResumeChange = (e) => {
    let name = e.target.name;
    let val = e.target.value;
    setParsedResumeForm({ ...parsedResumeForm, [name]: val });
  };

  const onParseResumeExperienceAddClick = () => {
    let experiences = parsedResumeForm?.experiences;
    if (Array.isArray(experiences)) {
      experiences.push({
        company_name: '',
        job_title: '',
        start_date: '',
        end_date: '',
        description: [],
      });
    } else {
      experiences = [];
      experiences.push({
        company_name: '',
        job_title: '',
        start_date: '',
        end_date: '',
        description: [],
      });
    }
    setParsedResumeForm({ ...parsedResumeForm, experiences });
  };

  const onParseResumeEducationAddClick = () => {
    let educations = parsedResumeForm?.educations;
    if (Array.isArray(educations)) {
      educations.push({
        degree_name: '',
        college_name: '',
        end_date: '',
        start_date: '',
      });
    } else {
      educations = [];
      educations.push({
        degree_name: '',
        college_name: '',
        end_date: '',
        start_date: '',
      });
    }
    setParsedResumeForm({ ...parsedResumeForm, educations });
  };

  const onParseResumeExperienceChange = (index, e) => {
    let experiences = parsedResumeForm?.experiences;
    let name = e.target.name;
    let val = e.target.value;
    experiences[index][name] = val;
    setParsedResumeForm({ ...parsedResumeForm, experiences });
  };

  const onParseResumeEducationChange = (index, e) => {
    let educations = parsedResumeForm?.educations;
    let name = e.target.name;
    let val = e.target.value;
    educations[index][name] = val;
    setParsedResumeForm({ ...parsedResumeForm, educations });
  };

  const onParseResumeSkillAddClick = (e) => {
    let skills = parsedResumeForm?.skills;
    if (Array.isArray(skills)) {
      skills.push('');
    } else {
      skills = [];
      skills.push('');
    }
    setParsedResumeForm({ ...parsedResumeForm, skills });
  };

  const onParseResumeSkillChange = (index, e) => {
    let val = e.target.value;
    let skills = parsedResumeForm?.skills;
    skills[index] = val;
    setParsedResumeForm({ ...parsedResumeForm, skills });
  };

  const onParseResumeExperienceDescriptionChange = (index, e, dscIndex) => {
    let val = e.target.value;
    let experiences = parsedResumeForm?.experiences;
    let descriptions = experiences[index]?.description;
    if (Array.isArray(descriptions)) {
      experiences[index].description[dscIndex] = val;
    }
    setParsedResumeForm({ ...parsedResumeForm, experiences });
  };
  const onParseResumeExperienceDescriptionAddClick = (index) => {
    let experiences = parsedResumeForm?.experiences;
    let descriptions = experiences[index]?.description;
    if (Array.isArray(descriptions)) {
      experiences[index].description.push('');
    }
    setParsedResumeForm({ ...parsedResumeForm, experiences });
  };
  const onParseResumeExperienceDescriptionRemoveClick = (index, dscIndex) => {
    let experiences = parsedResumeForm?.experiences;
    let descriptions = experiences[index]?.description;
    if (Array.isArray(descriptions)) {
      experiences[index].description.splice(dscIndex, 1);
    }
    setParsedResumeForm({ ...parsedResumeForm, experiences });
  };
  const onParseResumeExperienceRemoveClick = (index) => {
    let experiences = parsedResumeForm?.experiences;
    if (Array.isArray(experiences)) {
      experiences.splice(index, 1);
    }
    setParsedResumeForm({ ...parsedResumeForm, experiences });
  };
  const onParseResumeEducationRemoveClick = (index) => {
    let educations = parsedResumeForm?.educations;
    if (Array.isArray(educations)) {
      educations.splice(index, 1);
    }
    setParsedResumeForm({ ...parsedResumeForm, educations });
  };
  const onParseResumeSkillRemoveClick = (index) => {
    let skills = parsedResumeForm?.skills;

    if (Array.isArray(skills)) {
      skills.splice(index, 1);
    }
    setParsedResumeForm({ ...parsedResumeForm, skills });
  };

  const onDownloadFineTunedResumeClick = useReactToPrint({
    content: () => fineTunedResumeComponentRef.current,
    documentTitle: fineTunedResumeForm?.resume?.name,
  });

  const onGenerateMockInterviewClick = async () => {
    setMockInterviewLoading(true);
    let res = await dispatch(
      create_user_mock_interview_action({
        job: fineTunedResumeData?.job,
        resume: fineTunedResumeData?.resume,
      })
    );

    if (res?._id) {
      navigate(navigateRoutes.takeMockInterview.replace(':id', res._id));
    }

    setMockInterviewLoading(false);
  };

  const onCoverLetterChange = async (e) => {
    let val = e.target.value;
    setFineTuneResumeUpdateLoading(true);
    await dispatch(
      update_user_fine_tune_resume_action(fineTunedResumeForm?._id, {
        cover_letter: val,
      })
    );
    setFineTuneResumeUpdateLoading(false);
  };

  const onResumeSelectClick = async (val) => {
    if (val) {
      setSelectedResume(val);
      setFineTuneResumeLoading(true);
      await dispatch(
        regenerate_user_fine_tune_resume_action(fineTunedResumeForm?._id, {
          resume: val,
        })
      );
      await dispatch(fetch_user_fine_tune_resume_action(fineTunedResumeForm?._id));
      setFineTuneResumeLoading(false);
    }
  };

  const getProgressBarVariant = () => {
    if (fineTunedResumeProcessed?.status === queueStatusTypes.started) return 'warning';
    if (fineTunedResumeProcessed?.status === queueStatusTypes.completed) return 'info';
    if (fineTunedResumeProcessed?.status === queueStatusTypes.resolved) return 'success';
    return 'warning';
  };

  const tabList = ['Your details', 'Skills', 'Experience', 'Education', 'Cover Letter'];

  return {
    fineTunedResumeForm,
    fineTuneResumeLoading,
    parsedResumeForm,
    fineTuneResumeUpdateLoading,
    fineTunedResumeComponentRef,
    mockInterviewLoading,
    navigate,
    activeTab,
    tabList,

    fineTunedResumeProcessed,

    userResume,
    selectedResume,

    setActiveTab,
    handleNextClick,
    handleBackClick,

    onFineTunedResumeSaveClick,
    onParseResumeChange,
    onParseResumeExperienceAddClick,
    onParseResumeExperienceChange,
    onParseResumeEducationAddClick,
    onParseResumeEducationChange,
    onParseResumeSkillAddClick,
    onParseResumeSkillChange,
    onParseResumeExperienceDescriptionChange,
    onParseResumeExperienceDescriptionAddClick,
    onParseResumeExperienceDescriptionRemoveClick,
    onParseResumeExperienceRemoveClick,
    onParseResumeEducationRemoveClick,
    onParseResumeSkillRemoveClick,
    onDownloadFineTunedResumeClick,
    onGenerateMockInterviewClick,
    onCoverLetterChange,

    onResumeSelectClick,
    getProgressBarVariant,
  };
}

export default useFineTunedResume;
