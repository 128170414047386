import React from 'react';

const contactRelationShipStatus = {
  coworker: 'COWORKER',
  friend: 'FRIEND',
  recruiter: 'RECRUITER',
  mentor: 'MENTOR',
  hr: 'HR',
};

function ContactRelationship({ selectedValue, onContactRelationshipChange }) {
  return (
    <div className="select mt-1">
      <p className="mb-1">Contact Relationship</p>
      <select
        className="select-control bg-transparent"
        onChange={(e) =>
          onContactRelationshipChange({
            target: { name: 'contact_relationship', value: e.target.value },
          })
        }
        name={'contact_relationship'}
      >
        <option disabled={true} value="" selected={selectedValue === ''}>
          Select Relationship
        </option>
        {Object.keys(contactRelationShipStatus).map((item) => (
          <option
            key={contactRelationShipStatus[item]}
            selected={selectedValue === contactRelationShipStatus[item]}
            value={contactRelationShipStatus[item]}
          >
            {contactRelationShipStatus[item].toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
          </option>
        ))}
      </select>
    </div>
  );
}

export default ContactRelationship;
