/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  fetch_user_bookmark_jobs_action,
  mark_auto_apply_user_bookmark_job_action,
  update_status_user_bookmark_job_action,
} from '../../../_redux/action';
import { jobApplyStatus } from '../../../_helpers/types';
import { navigateRoutes } from '../../../_config/route';

const allColumns = {
  bookmarked: {
    items: [],
    title: 'Bookmarked',
    color: '#FF6347',
  },
  applied: {
    items: [],
    title: 'Applied',
    color: '#4682B4',
  },
  interview: {
    items: [],
    title: 'Interview',
    color: '#32CD32',
  },
  offered: {
    items: [],
    title: 'Offered',
    color: '#FFD700',
  },
  hired: {
    items: [],
    title: 'Hired',
    color: '#8A2BE2',
  },
};

function useJobTracker(props) {
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState(allColumns);
  const [autoApplyJobIds, setAutoApplyJobIds] = useState([]);
  const isBookmarkedColumn = (columnId) => columnId === 'bookmarked';

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [kanbanView, setView] = useState(false);
  const [tableVisibility, setTableVisibility] = useState(() => {
    const initialVisibility = {};
    Object.keys(allColumns).forEach((columnId) => {
      initialVisibility[columnId] = true;
    });
    return initialVisibility;
  });

  const { bookmarkedJobsData } = useSelector((state) => state.fetch_user_job_bookmarks_reducer);

  useEffect(() => {
    const callApi = async () => {
      setLoading(true);
      for (let i in jobApplyStatus) {
        await dispatch(fetch_user_bookmark_jobs_action(jobApplyStatus[i]));
      }
      setLoading(false);
    };

    callApi();
  }, []);

  useEffect(() => {
    if (bookmarkedJobsData?.data) {
      if (bookmarkedJobsData?.status === jobApplyStatus.applied) {
        columns.applied.items = bookmarkedJobsData.data.jobBookmarks;
      }
      if (bookmarkedJobsData?.status === jobApplyStatus.bookmarked) {
        columns.bookmarked.items = bookmarkedJobsData.data.jobBookmarks;
      }
      if (bookmarkedJobsData?.status === jobApplyStatus.hired) {
        columns.hired.items = bookmarkedJobsData.data.jobBookmarks;
      }
      if (bookmarkedJobsData?.status === jobApplyStatus.interview) {
        columns.interview.items = bookmarkedJobsData.data.jobBookmarks;
      }
      if (bookmarkedJobsData?.status === jobApplyStatus.offered) {
        columns.offered.items = bookmarkedJobsData.data.jobBookmarks;
      }
    }
  }, [bookmarkedJobsData]);

  //drag and drop
  const removeFromList = (list, index) => {
    const result = list;
    const [removed] = result.items.splice(index, 1);
    return [removed, result];
  };

  const addToList = (list, index, element) => {
    const result = list;
    result.items.splice(index, 0, element);
    return result;
  };

  const onDragEnd = async (result) => {
    // console.log(result,"ejkbjfabebfjeaf")
    if (!result.destination) {
      return;
    }
    const listCopy = { ...columns };

    const sourceList = listCopy[result.source.droppableId];

    const [removedElement, newSourceList] = removeFromList(sourceList, result.source.index);

    listCopy[result.source.droppableId] = newSourceList;
    const destinationList = listCopy[result.destination.droppableId];
    listCopy[result.destination.droppableId] = addToList(destinationList, result.destination.index, removedElement);
    await dispatch(
      update_status_user_bookmark_job_action(result.draggableId, {
        status: jobApplyStatus[result.destination.droppableId],
      })
    );
    setColumns(listCopy);
  };

  const onJobNavigateClick = (id) => {
    if (id) navigate(navigateRoutes.jobDeails.replace(':id', id));
  };

  const markJobsForAutoApply = (id) => {
    let data = autoApplyJobIds;
    if (id) {
      if (!autoApplyJobIds.includes(id)) data.push(id);
      else {
        const index = autoApplyJobIds.indexOf(id);
        data.splice(index, 1);
      }
    }
    setAutoApplyJobIds([...data]);
  };

  const onAutoApplyCreateClick = async () => {
    await dispatch(mark_auto_apply_user_bookmark_job_action({ jobIds: autoApplyJobIds }));
    await dispatch(fetch_user_bookmark_jobs_action(jobApplyStatus.bookmarked));
    setAutoApplyJobIds([]);
  };

  const checkAutoApply = (item, columnId) => {
    // if (item?.job_apply_url.includes("lever.co")) {
    // if (item?.status === jobApplyStatus.bookmarked && !item?.auto_apply_status) {
    //   return true;
    // }
    // // }

    // return false;
    return columnId === 'bookmarked' && (item?.auto_apply_status || autoApplyJobIds.includes(item?._id));
  };

  const handleChangeView = () => {
    setView(!kanbanView);
  };

  const toggleTableVisibility = (columnId) => {
    setTableVisibility((prevVisibility) => ({
      ...prevVisibility,
      [columnId]: !prevVisibility[columnId],
    }));
  };

  return {
    loading,
    columns,
    autoApplyJobIds,
    kanbanView,
    tableVisibility,
    isBookmarkedColumn,

    onDragEnd,
    onJobNavigateClick,
    onAutoApplyCreateClick,
    markJobsForAutoApply,
    checkAutoApply,
    handleChangeView,
    toggleTableVisibility,
  };
}
export default useJobTracker;
