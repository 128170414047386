/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import useEmailVerify from './useEmailVerify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserClock } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../../_component/InputField';
import TestimonialSwiper from '../../../_component/TestimonialSwiper';
import { Spin } from 'antd';

export default (props) => {
  const {
    loading,
    formInputsError,

    onInputChange,
    onSubmitClick,
    onResendEmailClick,
  } = useEmailVerify(props);

  return (
    <div className="auth-box centered">
      <div className="centered auth-box-left">
        <div className="auth-left-logo-box ">
          <span className="auth-left-logo-icon">
            <FontAwesomeIcon icon={faUserClock} />
          </span>
          <span className="auth-left-logo-text">Interview&</span>
        </div>
        <form className="centered auth-left-form" onSubmit={onSubmitClick}>
          <div className="auth-form-greet">
            <h1 className="auth-form-greet-welcome">Welcome to Ai Clues</h1>
            <p className="auth-form-detail">Enter verification code received on email</p>
          </div>
          <InputField
            id="code"
            type="text"
            placeholder="Enter your code"
            onChange={(e) => onInputChange(e.target.value, 'code')}
            // onBlur={(e) => checkFieldError(e.target.value, 'code')}
            error={formInputsError.code}
            formSubmitError={formInputsError.formSubmitError}
            containerClassName="containerClass"
            inputClassName="inputClass"
            iconClassName="iconClass"
            errorClassName="errorClass"
            fontIconClassName="fontIconClass"
            maxLength={4}
          />

          <button className="auth-form-submit-btn" type="submit" value="Submit">
            {loading ? (
              <div className="w-full centered justify-center">
                <Spin size="small" />
              </div>
            ) : (
              'Verify'
            )}
          </button>
          <p className="auth-form-account">
            Didn't received an email?{' '}
            <button type="button" onClick={onResendEmailClick} className="auth-form-account-btn">
              Resend
            </button>
          </p>
        </form>
      </div>
      <div className="auth-box-carousel centered">
        <TestimonialSwiper />
      </div>
    </div>
  );
};
