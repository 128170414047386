import React from 'react';
import ReactDOM from 'react-dom/client';

// import "bootstrap/dist/css/bootstrap.min.css";
import './assets/scss/main.scss';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './_redux/store';
import 'antd/dist/reset.css';
// import "bootstrap/dist/css/bootstrap.min.css";
import './assets/scss/main.scss';
import './index.css';
import { AuthProvider } from './_hooks/useAuthContext';
const Root = () => {
  return (
    <React.StrictMode>
      <AuthProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </AuthProvider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);
