import React, { createContext, useState, useEffect } from 'react';
import { emitSocketEvent } from '../_config/socketio';
import { socketIoEvents } from '../_helpers/types';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const getUser = async () => {
      const storedUser = JSON.parse(localStorage.getItem('aiclues-user'));

      if (storedUser) {
        setUser(storedUser);
        setAuthenticated(true);
        emitSocketEvent(socketIoEvents.joinRoom, storedUser?.user?._id);
      }
    };
    getUser();
  }, [children]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        authenticated,
        setAuthenticated,
        userRole,
        setUserRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
