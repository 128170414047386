/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetch_pronouns_action } from '../_redux/action/_master';

const PronounSearchBasic = ({ defaultValue, onSearchChange }) => {
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  const { pronouns } = useSelector((state) => state.fetch_pronouns_reducer);

  const ref = useRef();
  useEffect(() => {
    if (ref?.current && ref?.current?.value) {
      ref.current.value = defaultValue;
    }
  }, []);

  useEffect(() => {
    const callApi = async () => {
      await dispatch(fetch_pronouns_action());
    };
    callApi();
  }, []);

  useEffect(() => {
    if (pronouns?.pronouns) {
      setOptions(pronouns.pronouns);
    }
  }, [pronouns]);

  return (
    <Select
      ref={ref}
      showSearch
      value={defaultValue}
      className="w-2/4 relative mb-3 max-[640px]:w-full"
      placeholder={'Search Pronoun'}
      allowClear={true}
      options={(options || []).map((d) => ({
        value: d?._id,
        label: `${d.display_name}`,
      }))}
      getPopupContainer={(trigger) => trigger.parentNode}
      filterOption={(input, option) => (option.label.toLowerCase() ?? '').includes(input.toLowerCase())}
      onSelect={onSearchChange}
    />
  );
};
export default PronounSearchBasic;
