import { showWarningMessage } from '../../../_component/MessageSnack';
import axiosInstance from '../../../_config/AxiosInstance';
import config from '../../../_config/config';
import { userCompanyBookmarkConstants } from '../../constant';

export const fetch_user_bookmark_companies_action = (status) => async (dispatch) => {
  dispatch({
    type: userCompanyBookmarkConstants.FETCH_USER_COMPANY_BOOKMARKS_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.bookmarks.companyBookmarks);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userCompanyBookmarkConstants.FETCH_USER_COMPANY_BOOKMARKS_ACTION_SUCCESS,
        payload: { data: data.data, status },
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userCompanyBookmarkConstants.FETCH_USER_COMPANY_BOOKMARKS_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const update_user_bookmark_company_action = (id, status) => async (dispatch) => {
  dispatch({
    type: userCompanyBookmarkConstants.UPDATE_USER_BOOKMARK_COMPANY_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.put(`${config.bookmarks.companyBookmark}/${id}`, status);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userCompanyBookmarkConstants.UPDATE_USER_BOOKMARK_COMPANY_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userCompanyBookmarkConstants.UPDATE_USER_BOOKMARK_COMPANY_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

// action to create a new company
export const create_user_bookmark_company_action = (data) => async (dispatch) => {
  dispatch({
    type: userCompanyBookmarkConstants.CREATE_USER_BOOKMARK_COMPANY_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(config.bookmarks.companyBookmark, data);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userCompanyBookmarkConstants.CREATE_USER_BOOKMARK_COMPANY_ACTION_SUCCESS,
        payload: data.data,
      });
      return data.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userCompanyBookmarkConstants.CREATE_USER_BOOKMARK_COMPANY_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return null;
  }
};

export const fetch_user_company_details_bookmark_action = (id) => async (dispatch) => {
  dispatch({
    type: userCompanyBookmarkConstants.FETCH_USER_COMPANY_DETAILS_BOOKMARK_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(`${config.bookmarks.companyBookmark}/${id}`);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userCompanyBookmarkConstants.FETCH_USER_COMPANY_DETAILS_BOOKMARK_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userCompanyBookmarkConstants.FETCH_USER_COMPANY_DETAILS_BOOKMARK_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const delete_user_company_details_bookmark_action = (id) => async (dispatch) => {
  dispatch({
    type: userCompanyBookmarkConstants.DELETE_USER_BOOKMARK_COMPANY_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.delete(`${config.bookmarks.companyBookmark}/${id}`);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userCompanyBookmarkConstants.DELETE_USER_BOOKMARK_COMPANY_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userCompanyBookmarkConstants.DELETE_USER_BOOKMARK_COMPANY_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};
