/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  create_user_bookmark_company_action,
  delete_user_company_details_bookmark_action,
  fetch_user_bookmark_companies_action,
  update_user_bookmark_company_action,
} from '../../../_redux/action';

function useSavedCompanies(props) {
  const [loading, setLoading] = useState(false);
  const [allCompanies, setAllCompanies] = useState();
  const [companyForm, setCompanyForm] = useState(null);
  const [companyModalOpen, setCompanyModalOpen] = useState(null);
  const [deleteCompany, setDeleteCompany] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(null);

  // State to manage validation errors
  const [formErrors, setFormErrors] = useState({});

  const dispatch = useDispatch();

  const { bookmarkedCompaniesData } = useSelector((state) => state.fetch_user_company_bookmarks_reducer);

  useEffect(() => {
    const callApi = async () => {
      setLoading(true);
      await dispatch(fetch_user_bookmark_companies_action());
      setLoading(false);
    };

    callApi();
  }, []);

  useEffect(() => {
    if (bookmarkedCompaniesData?.data) {
      setAllCompanies(bookmarkedCompaniesData?.data);
    }
  }, [bookmarkedCompaniesData]);

  // Handles input change and clears validation errors
  const onInputChange = async (e) => {
    setCompanyForm({ ...companyForm, [e.target.name]: e.target.value });

    // Clear the error for the field being updated
    if (formErrors[e.target.name]) {
      setFormErrors({ ...formErrors, [e.target.name]: null });
    }
  };

  const validateForm = () => {
    let errors = {};
    if (!companyForm?.name) errors.name = 'Name is required';
    if (!companyForm?.sector) errors.sector = 'Sector is required';
    if (!companyForm?.location) errors.location = 'Location is required';
    if (!companyForm?.bookmark_url) errors.bookmark_url = 'Company URL is required';
    if (!companyForm?.notes) errors.notes = 'Notes is required';

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // If no errors, return true
  };

  const onCompanySave = async () => {
    if (!validateForm()) return; // Exit if the form is invalid

    if (companyForm?._id) {
      await dispatch(update_user_bookmark_company_action(companyForm._id, companyForm));
    } else {
      await dispatch(create_user_bookmark_company_action(companyForm));
    }

    await dispatch(fetch_user_bookmark_companies_action());

    setCompanyForm({});
    setCompanyModalOpen((v) => !v);
  };

  const handleCompanyModalOpen = (item) => {
    if (item) {
      setCompanyForm(item);
    } else {
      setCompanyForm({}); // Clear form for adding a new company
    }
    setFormErrors({}); // Reset errors when opening the modal
    setCompanyModalOpen((v) => !v);
  };

  const onCompanyViewClick = async (item) => {
    if (item?.bookmark_url) {
      window.open(item.bookmark_url, null);
    }
  };

  const onDeleteCompanyConfirm = async () => {
    if (deleteCompany?._id) {
      await dispatch(delete_user_company_details_bookmark_action(deleteCompany._id));
      await dispatch(fetch_user_bookmark_companies_action());
    }
    setDeleteCompany(null);
    setDeleteModalOpen((v) => !v);
  };

  const onCompanyDelete = async (item) => {
    setDeleteCompany(item);
    setDeleteModalOpen((v) => !v);
  };

  const onDeleteModelClose = () => {
    setDeleteModalOpen((prev) => !prev);
  };

  return {
    loading,
    allCompanies,
    companyForm,
    companyModalOpen,
    deleteCompany,
    deleteModalOpen,
    formErrors,

    onInputChange,
    onCompanySave,
    onCompanyDelete,
    handleCompanyModalOpen,
    onCompanyViewClick,
    onDeleteCompanyConfirm,
    onDeleteModelClose,
  };
}
export default useSavedCompanies;
