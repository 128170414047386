import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './screens/Home';
import { CandidateRoute, PrivateRoute, PublicRoute } from './_config/RouteConfig';
import { navigateRoutes } from './_config/route';
import SignIn from './screens/Auth/SignIn';
import SignUp from './screens/Auth/SignUp';
import Dashboard from './screens/Candidate/Dashboard';
import Profile from './screens/Candidate/Profile';
import EmailVerify from './screens/ProtectedPage/EmailVerify';
import ForgotPassword from './screens/Auth/ForgotPassword';
import ResetPassword from './screens/Auth/ResetPassword';
import JobTracker from './screens/Candidate/JobTracker';
import ResumeBuilder from './screens/Candidate/ResumeBuilder';
import JobDetails from './screens/Candidate/JobDetails';
import FineTunedResume from './screens/Candidate/FineTuneResume';
import MockInterviews from './screens/Candidate/MockInterviews';
import MockInterview from './screens/Candidate/MockInterview';
import SavedContacts from './screens/Candidate/SavedContacts';
import SavedCompanies from './screens/Candidate/SavedCompanies';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/" element={<PublicRoute />}>
          <Route path={navigateRoutes.signIn} element={<SignIn />} />
          <Route path={navigateRoutes.signUp} element={<SignUp />} />
          <Route path={navigateRoutes.forgotPassword} element={<ForgotPassword />} />
          <Route path={navigateRoutes.resetPassword} element={<ResetPassword />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path={navigateRoutes.verifyEmail} element={<EmailVerify />} />
        </Route>

        <Route path="/" element={<CandidateRoute />}>
          <Route path={navigateRoutes.candidateDashboard} element={<Dashboard />} />
          <Route path={navigateRoutes.jobTracker} element={<JobTracker />} />
          <Route path={navigateRoutes.jobDeails} element={<JobDetails />} />
          <Route path={navigateRoutes.resumeBuilder} element={<ResumeBuilder />} />
          <Route path={navigateRoutes.savedContacts} element={<SavedContacts />} />
          <Route path={navigateRoutes.savedCompanies} element={<SavedCompanies />} />
          <Route path={navigateRoutes.fineTunedResume} element={<FineTunedResume />} />
          <Route path={navigateRoutes.candidateProfile} element={<Profile />} />
          <Route path={navigateRoutes.mockInterviews} element={<MockInterviews />} />
          <Route path={navigateRoutes.takeMockInterview} element={<MockInterview />} />
        </Route>
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
