/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  create_user_mock_interview_action,
  fetch_user_mock_interviews_action,
  fetch_user_resumes_action,
  fetch_user_bookmark_jobs_action,
} from '../../../_redux/action';
import { navigateRoutes } from '../../../_config/route';
import { showWarningMessage } from '../../../_component/MessageSnack';

function useMockInterviews(props) {
  const [loading, setLoading] = useState(false);
  const [userMockInterviews, setUserMockInterviews] = useState(null);
  const [mockInterviewModelOpen, setMockInterviewModelOpen] = useState(null);
  const [mockInterviewForm, setMockInterviewForm] = useState(null);
  const [userResumes, setUserResumes] = useState(null);
  const [allJobs, setAllJobs] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mockInterviews } = useSelector((state) => state.fetch_user_mock_interviews_reducer);

  const { bookmarkedJobsData } = useSelector((state) => state.fetch_user_job_bookmarks_reducer);

  const { resumes } = useSelector((state) => state.fetch_user_all_resumes_reducer);

  useEffect(() => {
    if (resumes?.resumes) {
      setUserResumes(resumes?.resumes);
    }
  }, [resumes]);

  useEffect(() => {
    const callApi = async () => {
      setLoading(true);
      await dispatch(fetch_user_mock_interviews_action());
      await dispatch(fetch_user_resumes_action());
      setLoading(false);
    };

    callApi();
  }, []);

  useEffect(() => {
    const callJobApi = async () => {
      await dispatch(fetch_user_bookmark_jobs_action('all'));
    };

    callJobApi();
  }, []);

  useEffect(() => {
    if (mockInterviews?.savedAiMockInterviews) {
      setUserMockInterviews(mockInterviews?.savedAiMockInterviews);
    }
  }, [mockInterviews]);

  useEffect(() => {
    if (bookmarkedJobsData?.data) {
      setAllJobs(bookmarkedJobsData.data.jobBookmarks);
    }
  }, [bookmarkedJobsData]);

  const onMockInterviewClick = async (item) => {
    navigate(navigateRoutes.takeMockInterview.replace(':id', item._id));
  };

  const onMockInterviewFormChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (value) {
      setMockInterviewForm({ ...mockInterviewForm, [name]: value });
    }
  };
  const handleMockInterviewModalOpen = () => {
    setMockInterviewModelOpen((v) => !v);
  };

  const onMockInterviewGenerate = async (ai_platform) => {
    if (ai_platform) {
      if (mockInterviewForm?.resume && mockInterviewForm?.job) {
        const result = await dispatch(
          create_user_mock_interview_action({
            ...mockInterviewForm,
          })
        );
        const mockInterviewId = result?._id;

        if (mockInterviewId) {
          await dispatch(fetch_user_mock_interviews_action());

          navigate(navigateRoutes.takeMockInterview.replace(':id', mockInterviewId));
          setMockInterviewForm(null);
          setMockInterviewModelOpen(false);
        } else {
          showWarningMessage('Mock Interview creation failed');
        }
      } else showWarningMessage('Please select resume and enter job description.');
    }
  };

  return {
    loading,
    userMockInterviews,
    mockInterviewForm,
    mockInterviewModelOpen,
    userResumes,
    allJobs,

    onMockInterviewClick,
    onMockInterviewFormChange,
    onMockInterviewGenerate,
    handleMockInterviewModalOpen,
  };
}
export default useMockInterviews;
