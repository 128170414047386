import { Spin } from 'antd';
import React from 'react';

function ResumeFineTuneSelectDropdown({
  selectedResume,
  userResume,
  fineTuneResumeLoading,
  onResumeSelectClick,
  title,
}) {
  return (
    <div className="flex flex-row items-center">
      <p className="m-0 font-medium">{title || 'Fine Tune Resume'}:</p>
      {fineTuneResumeLoading ? (
        <Spin className=" ml-1" />
      ) : (
        <select
          className="select-control bg-transparent w-max ml-2 p-1"
          onChange={(e) => onResumeSelectClick(e.target.value)}
          name={'resume'}
        >
          <option disabled={true} value="" selected={selectedResume === null}>
            Select Resume
          </option>
          {Array.isArray(userResume) &&
            userResume.map((item) => (
              <option key={item.key} selected={selectedResume === item._id} value={item._id}>
                {item.name}
              </option>
            ))}
        </select>
      )}
    </div>
  );
}

export default ResumeFineTuneSelectDropdown;
