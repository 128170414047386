import RichTextEditor from '@mantine/rte';
import React, { useEffect, useState } from 'react';

const FineTuneResumeCoverLetter = ({ onCoverLetterChange, fineTunedResumeForm }) => {
  const [coverLetter, setCoverLetter] = useState(fineTunedResumeForm?.cover_letter || '');
  useEffect(() => {
    setCoverLetter(fineTunedResumeForm?.cover_letter || '');
  }, [fineTunedResumeForm]);

  return (
    <div className="my-8 max-[768px]:my-4 ">
      <p className=" font-medium">Cover letter:</p>
      <div className="mt-2">
        <RichTextEditor
          value={coverLetter}
          onChange={setCoverLetter}
          onBlur={onCoverLetterChange}
          className="bg-white text-black w-full"
          placeholder="Edit cover letter"
        />
      </div>
    </div>
  );
};

export default FineTuneResumeCoverLetter;
