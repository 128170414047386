import { showWarningMessage } from '../../../_component/MessageSnack';
import axiosInstance from '../../../_config/AxiosInstance';
import config from '../../../_config/config';
import { aiConstants } from '../../constant';

export const create_user_fine_tune_resume_action = (data) => async (dispatch) => {
  dispatch({
    type: aiConstants.CREATE_USER_FINE_TUNE_RESUME_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(config.ai.fineTuneResume, data);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: aiConstants.CREATE_USER_FINE_TUNE_RESUME_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: aiConstants.CREATE_USER_FINE_TUNE_RESUME_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const fetch_user_fine_tune_resumes_action = () => async (dispatch) => {
  dispatch({
    type: aiConstants.FETCH_USER_FINE_TUNE_RESUMES_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.ai.allFineTuneResume);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: aiConstants.FETCH_USER_FINE_TUNE_RESUMES_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: aiConstants.FETCH_USER_FINE_TUNE_RESUMES_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const fetch_user_fine_tune_resume_action = (id) => async (dispatch) => {
  dispatch({
    type: aiConstants.FETCH_USER_FINE_TUNE_RESUME_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(`${config.ai.fineTuneResume}/${id}`);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: aiConstants.FETCH_USER_FINE_TUNE_RESUME_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: aiConstants.FETCH_USER_FINE_TUNE_RESUME_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const update_user_fine_tune_resume_action = (id, data) => async (dispatch) => {
  dispatch({
    type: aiConstants.UPDATE_USER_FINE_TUNE_RESUME_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.put(`${config.ai.fineTuneResume}/${id}`, data);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: aiConstants.UPDATE_USER_FINE_TUNE_RESUME_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: aiConstants.UPDATE_USER_FINE_TUNE_RESUME_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const regenerate_user_fine_tune_resume_action = (id, data) => async (dispatch) => {
  dispatch({
    type: aiConstants.REGENERATE_USER_FINE_TUNE_RESUME_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(`${config.ai.regenerateFineTuneResume}/${id}`, data);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: aiConstants.REGENERATE_USER_FINE_TUNE_RESUME_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: aiConstants.REGENERATE_USER_FINE_TUNE_RESUME_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const create_user_mock_interview_action = (data) => async (dispatch) => {
  dispatch({
    type: aiConstants.CREATE_USER_MOCK_INTERVIEW_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(config.ai.aiMockInterview, data);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: aiConstants.CREATE_USER_MOCK_INTERVIEW_ACTION_SUCCESS,
        payload: data.data,
      });
      return data.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: aiConstants.CREATE_USER_MOCK_INTERVIEW_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return null;
  }
};

export const fetch_user_mock_interviews_action = () => async (dispatch) => {
  dispatch({
    type: aiConstants.FETCH_USER_MOCK_INTERVIEWS_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.ai.allAiMockInterview);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: aiConstants.FETCH_USER_MOCK_INTERVIEWS_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: aiConstants.FETCH_USER_MOCK_INTERVIEWS_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const fetch_user_mock_interview_action = (id) => async (dispatch) => {
  dispatch({
    type: aiConstants.FETCH_USER_MOCK_INTERVIEW_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(`${config.ai.aiMockInterview}/${id}`);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: aiConstants.FETCH_USER_MOCK_INTERVIEW_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: aiConstants.FETCH_USER_MOCK_INTERVIEW_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const fetch_user_mock_interview_questions_action = (id, form) => async (dispatch) => {
  dispatch({
    type: aiConstants.FETCH_USER_MOCK_INTERVIEW_QUESTIONS_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(`${config.ai.aiMockInterviewQuestions}/${id}`, form);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: aiConstants.FETCH_USER_MOCK_INTERVIEW_QUESTIONS_ACTION_SUCCESS,
        payload: data.data,
      });
      return data.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: aiConstants.FETCH_USER_MOCK_INTERVIEW_QUESTIONS_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return null;
  }
};

export const fetch_user_mux_upload_url_action = () => async (dispatch) => {
  dispatch({
    type: aiConstants.FETCH_USER_MUX_UPLOAD_URL_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.ai.getMuxUploadUrl);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: aiConstants.FETCH_USER_MUX_UPLOAD_URL_ACTION_SUCCESS,
        payload: data.data,
      });
      return data.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: aiConstants.FETCH_USER_MUX_UPLOAD_URL_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return null;
  }
};

export const fetch_user_mux_upload_asset_ids_action = (form) => async (dispatch) => {
  dispatch({
    type: aiConstants.FETCH_USER_MUX_UPLOAD_ASSET_IDS_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(config.ai.getMuxUploadIds, form);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: aiConstants.FETCH_USER_MUX_UPLOAD_ASSET_IDS_ACTION_SUCCESS,
        payload: data.data,
      });
      return data.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: aiConstants.FETCH_USER_MUX_UPLOAD_ASSET_IDS_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return null;
  }
};

export const fetch_user_mock_interview_qestion_feedback_action = (id) => async (dispatch) => {
  dispatch({
    type: aiConstants.FETCH_USER_MOCK_INTERVIEW_QUESTION_FEEDBACK_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(`${config.ai.aiMockInterviewQuestionFeedback}/${id}`);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: aiConstants.FETCH_USER_MOCK_INTERVIEW_QUESTIONS_ACTION_SUCCESS,
        payload: data.data,
      });
      return data.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: aiConstants.FETCH_USER_MOCK_INTERVIEW_QUESTIONS_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return null;
  }
};
