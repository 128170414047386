import { showWarningMessage } from '../../../_component/MessageSnack';
import axiosInstance from '../../../_config/AxiosInstance';
import config from '../../../_config/config';
import { userJobBookmarkConstants } from '../../constant';

export const fetch_user_bookmark_jobs_action = (status) => async (dispatch) => {
  dispatch({
    type: userJobBookmarkConstants.FETCH_USER_JOB_BOOKMARKS_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(`${config.bookmarks.jobBookmarks}/${status}`);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userJobBookmarkConstants.FETCH_USER_JOB_BOOKMARKS_ACTION_SUCCESS,
        payload: { data: data.data, status },
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userJobBookmarkConstants.FETCH_USER_JOB_BOOKMARKS_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const update_status_user_bookmark_job_action = (id, status) => async (dispatch) => {
  dispatch({
    type: userJobBookmarkConstants.UPDATE_STATUS_USER_BOOKMARK_JOB_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.put(`${config.bookmarks.jobBookmark}/${id}`, status);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userJobBookmarkConstants.UPDATE_STATUS_USER_BOOKMARK_JOB_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userJobBookmarkConstants.UPDATE_STATUS_USER_BOOKMARK_JOB_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const fetch_user_job_details_bookmark_action = (id) => async (dispatch) => {
  dispatch({
    type: userJobBookmarkConstants.FETCH_USER_JOB_DETAILS_BOOKMARK_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(`${config.bookmarks.jobBookmark}/${id}`);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userJobBookmarkConstants.FETCH_USER_JOB_DETAILS_BOOKMARK_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userJobBookmarkConstants.FETCH_USER_JOB_DETAILS_BOOKMARK_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const mark_auto_apply_user_bookmark_job_action = (data) => async (dispatch) => {
  dispatch({
    type: userJobBookmarkConstants.MARK_AUTO_APPLY_USER_BOOKMARK_JOB_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(`${config.autoApply.createAutoApply}`, data);
    if (response.status) {
      let data = response.data;
      dispatch({
        type: userJobBookmarkConstants.MARK_AUTO_APPLY_USER_BOOKMARK_JOB_ACTION_SUCCESS,
        payload: data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: userJobBookmarkConstants.MARK_AUTO_APPLY_USER_BOOKMARK_JOB_ACTION_FAILURE,
      payload: e?.error,
    });
    showWarningMessage(e.error);
    return e;
  }
};
