/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetch_user_job_details_bookmark_action,
  fetch_user_resumes_action,
  update_status_user_bookmark_job_action,
  create_user_fine_tune_resume_action,
  create_user_mock_interview_action,
} from '../../../_redux/action';
import { useNavigate, useParams } from 'react-router-dom';
import { navigateRoutes } from '../../../_config/route';
import { aiPlatformTypes } from '../../../_helpers/types';

function useJobDetails() {
  const [jobForm, setJobForm] = useState(null);
  const [userResume, setUserResume] = useState(null);
  const [selectedResume, setSelectedResume] = useState(null);
  const [fineTuneResumeLoading, setFineTuneResumeLoading] = useState(null);
  const [selectedResumeDetails, setSelectedResumeDetails] = useState(null); // New state for storing selected resume info
  const [mockInterviewLoading, setMockInterviewLoading] = useState(false); // Declare mock interview loading state

  const { bookmarkedJobData } = useSelector((state) => state.fetch_user_job_details_bookmark_reducer);

  const { resumes } = useSelector((state) => state.fetch_user_all_resumes_reducer);

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const { id } = params;
    const callApi = async () => {
      await dispatch(fetch_user_job_details_bookmark_action(id));
      await dispatch(fetch_user_resumes_action());
    };

    callApi();
  }, [params]);

  useEffect(() => {
    if (bookmarkedJobData) {
      setJobForm(bookmarkedJobData?.jobBookmark);
    }
  }, [bookmarkedJobData]);
  useEffect(() => {
    if (resumes?.resumes) {
      setUserResume(resumes?.resumes);
    }
  }, [resumes]);

  const onJobUpdate = async (val, name) => {
    if (val && name) {
      await dispatch(update_status_user_bookmark_job_action(jobForm?._id, { [name]: val }));
    }
  };

  const onResumeSelectClick = async (val) => {
    if (val) {
      setSelectedResume(val);
      setFineTuneResumeLoading(true);
      // Find the selected resume details from the resumes array
      const resumeDetails = userResume.find((resume) => resume._id === val);
      setSelectedResumeDetails(resumeDetails); // Set the individual resume info

      await dispatch(
        create_user_fine_tune_resume_action({
          job: jobForm?._id,
          resume: val,
          ai_platform: aiPlatformTypes.openai,
        })
      );
      await dispatch(fetch_user_job_details_bookmark_action(jobForm?._id));
      setFineTuneResumeLoading(false);
    }
  };

  const onFineTunedResumeClick = () => {
    if (jobForm?.fine_tuned_resume) navigate(navigateRoutes.fineTunedResume.replace(':id', jobForm.fine_tuned_resume));
  };

  // Function to handle generating the mock interview
  const onGenerateMockInterviewClick = async () => {
    setMockInterviewLoading(true);
    let res = await dispatch(
      create_user_mock_interview_action({
        job: bookmarkedJobData?.jobBookmark, // Update this to reference the correct job if needed
        resume: selectedResumeDetails, // Update this to reference the correct resume if needed
      })
    );

    if (res?._id) {
      navigate(navigateRoutes.takeMockInterview.replace(':id', res._id));
    }
    setMockInterviewLoading(false);
  };

  // Function to handle opening the link
  const handleOpenLink = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return {
    jobForm,
    userResume,
    selectedResume,
    selectedResumeDetails,
    fineTuneResumeLoading,
    mockInterviewLoading, // Return the loading state
    onResumeSelectClick,
    onJobUpdate,
    onFineTunedResumeClick,
    onGenerateMockInterviewClick, // Return the mock interview function
    handleOpenLink,
  };
}

export default useJobDetails;
