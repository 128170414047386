import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const InputField = ({
  id,
  label,
  type,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  showPassword,
  handleShowPassword,
  containerClassName,
  inputClassName,
  labelClassName,
  errorClassName,
  iconClassName,
  fontIconClassName,
  formSubmitError,
  required,
  rows,
  inputType = 'input',
  ...props
}) => {
  const InputElement = inputType === 'textarea' ? 'textarea' : 'input';
  return (
    <div className={`relative ${containerClassName}`}>
      {label && (
        <label className={labelClassName}>
          {required && <span className="text-red-500">*</span>} {label}
        </label>
      )}
      <InputElement
        id={id}
        type={inputType === 'input' ? (showPassword ? 'text' : type) : undefined}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={`outline-none ${inputClassName} ${error ? 'border-red-400' : ''}`}
        rows={inputType === 'textarea' ? rows : undefined}
        {...props}
      />
      {type === 'password' && (
        <span className={`absolute right-0 cursor-pointer ${iconClassName}`} onClick={handleShowPassword}>
          <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} className={`${fontIconClassName}`} />
        </span>
      )}
      {(error || formSubmitError) && (
        <p className={`text-danger ${errorClassName}`}>
          <FontAwesomeIcon icon={faTriangleExclamation} className="mr-1" /> {error || formSubmitError}
        </p>
      )}
    </div>
  );
};

InputField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  showPassword: PropTypes.bool,
  handleShowPassword: PropTypes.func,
  containerClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  fontIconClassName: PropTypes.string,
  formSubmitError: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  inputType: PropTypes.oneOf(['input', 'textarea']),
};

export default InputField;
