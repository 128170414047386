import React from 'react';
import MainLayout from '../../../_layout/MainLayout';
import useSavedCompanies from './useSavedCompanies';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDeleteLeft, faEdit, faEye, faSquareXmark } from '@fortawesome/free-solid-svg-icons';
import StarRatings from 'react-star-ratings';
import CompanySize from '../../../_component/CompanySize';
import { Button, Modal, Spin } from 'antd';
import InputField from '../../../_component/InputField';

function SavedCompanies(props) {
  const {
    loading,
    allCompanies,
    companyForm,
    companyModalOpen,
    deleteCompany,
    deleteModalOpen,
    formErrors,

    onInputChange,
    onCompanySave,
    onCompanyDelete,
    handleCompanyModalOpen,
    onCompanyViewClick,
    onDeleteCompanyConfirm,
  } = useSavedCompanies(props);
  return (
    <MainLayout activeMenuItem="home">
      <div className="p-4 sticky">
        <div className="centered justify-between mb-4 window-container">
          <h2 className="window-title">Saved Companies</h2>
          <div className="centered justify-center">
            <button
              className="btn  bg-slate-50 text-gray-900 rounded-xl outline outline-1 hover:bg-slate-200"
              onClick={() => handleCompanyModalOpen({})}
            >
              Add Company
            </button>
          </div>
        </div>

        {loading ? (
          <div className="centered spinner-box">
            <Spin size="large" />
          </div>
        ) : (
          <div className="centered min-h-[90vh] flex-col gap-2 w-full">
            <div className="overflow-x-auto w-full flex-col mb-8">
              <table className="border-collapse w-full">
                <thead>
                  <tr className="w-full">
                    <th className="px-2 py-1">
                      <div className="centered justify-center text-sm font-medium text-gray-400">#</div>
                    </th>
                    <th className=" px-2 py-1">
                      <div className="centered justify-start text-sm font-medium text-gray-400">Name</div>
                    </th>
                    <th className="px-2 py-1">
                      <div className="centered  justify-start text-sm font-medium text-gray-400">Sector</div>
                    </th>
                    <th className=" px-2 py-1">
                      <div className="centered  justify-start text-sm font-medium text-gray-400">Location</div>
                    </th>
                    <th className=" px-2 py-1">
                      <div className="centered  justify-start text-sm font-medium text-gray-400">Size</div>
                    </th>
                    <th className=" px-2 py-1">
                      <div className="centered  justify-start text-sm font-medium text-gray-400">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(allCompanies) &&
                    allCompanies.map((item, index) => (
                      <tr key={item._id || index} className="border border-gray-200 border-l-0 border-r-0">
                        <td className="px-2 py-1 border border-gray-200 border-l-0 text-center">{index + 1}</td>
                        <td className=" px-2 py-1 border text-base font-medium text-blue-900">{item?.name}</td>
                        <td className=" px-2 py-1 border border-gray-200 text-xs font-medium text-gray-600">
                          {item?.sector}
                        </td>
                        <td className=" px-2 py-1 border border-gray-200 text-xs font-medium text-gray-600">
                          {item?.location}
                        </td>
                        <td className="px-2 py-1 border border-gray-200 text-xs font-medium text-gray-600">
                          {item?.company_size}
                        </td>
                        <td className=" px-2 py-1 border border-r-0 border-gray-200">
                          <div className="centered justify-evenly">
                            <FontAwesomeIcon
                              className="m-2 cursor-pointer"
                              icon={faDeleteLeft}
                              onClick={() => onCompanyDelete(item)}
                            />
                            <FontAwesomeIcon
                              className="m-2 cursor-pointer"
                              icon={faEdit}
                              onClick={() => handleCompanyModalOpen(item)}
                            />
                            <FontAwesomeIcon
                              className="m-2 cursor-pointer"
                              icon={faEye}
                              onClick={() => onCompanyViewClick(item)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <Modal
          title={companyForm?.name ? `Edit ${companyForm?.name}` : 'Add New Company'}
          open={companyModalOpen}
          onCancel={() => handleCompanyModalOpen({})}
          footer={[
            <Button className="p-4 font-medium" key="save" onClick={onCompanySave}>
              Save
            </Button>,
          ]}
        >
          {companyForm && (
            <div className="mt-4 space-y-3">
              <div className="flex flex-row justify-between">
                <p className="font-medium text-gray-700">Priority</p>
                <StarRatings
                  rating={companyForm?.interested}
                  starRatedColor="#FFCC2A"
                  changeRating={(e) =>
                    onInputChange({
                      target: { value: e, name: 'interested' },
                    })
                  }
                  numberOfStars={5}
                  name="rating"
                  starDimension="20"
                  starHoverColor="#FCDDB0"
                />
              </div>

              <InputField
                id="company-name"
                type="text"
                key={companyForm?.name || 'name'}
                name={'name'}
                placeholder="Enter name"
                defaultValue={companyForm?.name}
                onBlur={onInputChange}
                containerClassName="flex flex-col"
                inputClassName="p-2 border rounded bg-transparent"
                error={formErrors.name}
                errorClassName="text-xs"
              />
              <InputField
                id="company-sector"
                type="text"
                key={companyForm?.sector || 'sector'}
                name={'sector'}
                placeholder="Enter sector"
                defaultValue={companyForm?.sector}
                onBlur={onInputChange}
                containerClassName="flex flex-col"
                inputClassName="p-2 border rounded bg-transparent"
                error={formErrors.sector}
                errorClassName="text-xs"
              />
              <InputField
                id="company-location"
                type="tel"
                key={companyForm?.location || 'location'}
                name={'location'}
                placeholder="Enter Location"
                defaultValue={companyForm?.location}
                onBlur={onInputChange}
                containerClassName="flex flex-col"
                inputClassName="p-2 border rounded bg-transparent"
                error={formErrors.location}
                errorClassName="text-xs"
              />
              <InputField
                id="company-url"
                type="text"
                key={companyForm?.bookmark_url || 'bookmark_url'}
                name={'bookmark_url'}
                placeholder="Enter Company URL"
                defaultValue={companyForm?.bookmark_url}
                onBlur={onInputChange}
                containerClassName="flex flex-col"
                inputClassName="p-2 border rounded bg-transparent"
                error={formErrors.bookmark_url}
                errorClassName="text-xs"
              />

              <CompanySize selectedValue={companyForm?.company_size || ''} onCompanySizeChange={onInputChange} />

              <InputField
                id="company-notes"
                type="text"
                key={companyForm?.notes || 'notes'}
                name={'notes'}
                placeholder="Enter notes"
                defaultValue={companyForm?.notes}
                onBlur={onInputChange}
                containerClassName="flex flex-col"
                inputClassName="p-2 border rounded bg-transparent "
                error={formErrors.notes}
                errorClassName="text-xs"
              />
            </div>
          )}
        </Modal>

        <Modal
          title={`Delete company ${deleteCompany?.name}?`}
          open={deleteModalOpen}
          onOk={onDeleteCompanyConfirm}
          onCancel={() => onCompanyDelete(null)}
          okText="Confirm"
          cancelText="Cancel"
          centered
        />
      </div>
    </MainLayout>
  );
}

export default SavedCompanies;
