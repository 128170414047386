export const userContactBookmarkConstants = {
  FETCH_USER_CONTACT_BOOKMARKS_ACTION_REQUEST: 'FETCH_USER_CONTACT_BOOKMARKS_ACTION_REQUEST',
  FETCH_USER_CONTACT_BOOKMARKS_ACTION_SUCCESS: 'FETCH_USER_CONTACT_BOOKMARKS_ACTION_SUCCESS',
  FETCH_USER_CONTACT_BOOKMARKS_ACTION_FAILURE: 'FETCH_USER_CONTACT_BOOKMARKS_ACTION_FAILURE',

  FETCH_USER_CONTACT_DETAILS_BOOKMARK_ACTION_REQUEST: 'FETCH_USER_CONTACT_DETAILS_BOOKMARK_ACTION_REQUEST',
  FETCH_USER_CONTACT_DETAILS_BOOKMARK_ACTION_SUCCESS: 'FETCH_USER_CONTACT_DETAILS_BOOKMARK_ACTION_SUCCESS',
  FETCH_USER_CONTACT_DETAILS_BOOKMARK_ACTION_FAILURE: 'FETCH_USER_CONTACT_DETAILS_BOOKMARK_ACTION_FAILURE',

  // constants for creating contacts
  CREATE_USER_BOOKMARK_CONTACT_ACTION_REQUEST: 'UPDATE_USER_BOOKMARK_CONTACT_ACTION_REQUEST',
  CREATE_USER_BOOKMARK_CONTACT_ACTION_SUCCESS: 'UPDATE_USER_BOOKMARK_CONTACT_ACTION_SUCCESS',
  CREATE_USER_BOOKMARK_CONTACT_ACTION_FAILURE: 'UPDATE_USER_BOOKMARK_CONTACT_ACTION_FAILURE',

  UPDATE_USER_BOOKMARK_CONTACT_ACTION_REQUEST: 'UPDATE_USER_BOOKMARK_CONTACT_ACTION_REQUEST',
  UPDATE_USER_BOOKMARK_CONTACT_ACTION_SUCCESS: 'UPDATE_USER_BOOKMARK_CONTACT_ACTION_SUCCESS',
  UPDATE_USER_BOOKMARK_CONTACT_ACTION_FAILURE: 'UPDATE_USER_BOOKMARK_CONTACT_ACTION_FAILURE',

  DELETE_USER_BOOKMARK_CONTACT_ACTION_REQUEST: 'DELETE_USER_BOOKMARK_CONTACT_ACTION_REQUEST',
  DELETE_USER_BOOKMARK_CONTACT_ACTION_SUCCESS: 'DELETE_USER_BOOKMARK_CONTACT_ACTION_SUCCESS',
  DELETE_USER_BOOKMARK_CONTACT_ACTION_FAILURE: 'DELETE_USER_BOOKMARK_CONTACT_ACTION_FAILURE',
};
