import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPhone, faUserAstronaut, faXmark } from '@fortawesome/free-solid-svg-icons';
import useHome from './useHome';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper';

export default function Homepage() {
  const {
    isSignedIn,
    candidateToggle,
    onDashboardClick,
    onSiginClick,
    handleCandidateToggle,
    selectedSkills,
    handleSkillClick,
    isExploreDisabled,
  } = useHome();

  // Dummy data for TalentCards
  const talentData = [
    { id: 1, name: 'Harish', role: 'Frontend Developer', gradientColor: 'linear-gradient(135deg, #65e872, #fff0)' },
    {
      id: 2,
      name: 'Rajesh',
      role: 'Backend Developer',
      gradientColor: 'linear-gradient(45deg, #ffa6c6 32%, #e7505000)',
    },
    { id: 3, name: 'Raj', role: 'UI/UX Designer', gradientColor: 'linear-gradient(225deg, #e8c365, #e8c36533)' },
    { id: 4, name: 'Yash', role: 'Full Stack Developer', gradientColor: 'linear-gradient(135deg, #e98f5d, #e9905e00)' },
    {
      id: 5,
      name: 'Suraj',
      role: 'Software Engineer',
      gradientColor: 'linear-gradient(135deg, #9bb1ec, #7a62dc33)',
    },
  ];

  return (
    <div className="homepage-wrapper ">
      <header>
        <div className="flex items-center justify-center flex-col ">
          <div className="relative text-center w-full bg-[#192023] py-2 animated-light">
            <div className="text-white font-semibold max-[768px]:text-sm max-[540px]:text-xs max-[500px]:px-10">
              Automated first round interviews. Introducing Pizo.{' '}
              <a className="text-[#83E3AA] underline" href="#">
                Request early access
              </a>
            </div>
            <div className="absolute right-4 top-0 py-2">
              <FontAwesomeIcon icon={faXmark} className="text-slate-300 h-5" />
            </div>
          </div>
          <div className="container ">
            <div className="header-inner flex items-center py-3 max-[768px]:py-2 max-[1024px]:px-4 max-[560px]:px-2">
              <div className="logo me-3">
                <a href="/">
                  <FontAwesomeIcon icon={faUserAstronaut} />
                </a>
                <span className="ml-2">Interviews&</span>
              </div>
              <div className="menu-wrapper ms-auto">
                <ul className="menu flex items-center">
                  {isSignedIn ? (
                    <li>
                      <span
                        onClick={() => onDashboardClick()}
                        className="btn  py-3 px-6 rounded-full text-black  hover:shadow-[0_4px_16px_#0003] hover:bg-white  border border-[#d2eaeb] bg-[#d2eaeb]"
                      >
                        Dashboard
                      </span>
                    </li>
                  ) : (
                    <li>
                      <span
                        onClick={() => onSiginClick()}
                        className="btn py-3 px-6 rounded-full text-black hover:shadow-[0_4px_16px_#0003]  hover:bg-white border border-[#d2eaeb] bg-[#d2eaeb]"
                      >
                        Login
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="space-y-20 max-[768px]:space-y-8">
        <section className="section upper-section ">
          <div className="container inner-section-wrap">
            <h1 className="heading-title">What are you building?</h1>
            <p className="heading-subtitle">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint, magnam. Lorem ipsum dolor sit amet
            </p>

            <div className="candidate-perference-box">
              <div className="candidate-perference-container">
                <button
                  className={`candidate-button  
                  ${
                    candidateToggle
                      ? 'text-white bg-gradient-to-b from-[#79dba0] to-[#47b674]'
                      : 'bg-inherit text-[#192023]'
                  }
                  `}
                  onClick={() => handleCandidateToggle(true)}
                >
                  Apply as Talent
                </button>
                <button
                  className={`candidate-button ${
                    !candidateToggle
                      ? 'text-white bg-gradient-to-b from-[#79dba0] to-[#47b674]'
                      : 'bg-inherit text-[#192023]'
                  }
                `}
                  onClick={() => handleCandidateToggle(false)}
                >
                  Start Hiring
                </button>
              </div>

              {/* Skill Selection Section */}
              <div className="flex justify-center flex-wrap mb-10">
                {selectedSkills.map((skill) => (
                  <button
                    key={skill.name}
                    className={`m-2 px-4 py-2 rounded-full border-[1px] border-[#0000] hover:border-[#0003] ${
                      skill.isSelected ? 'bg-[#192023] text-white' : 'bg-gray-200 text-black'
                    }`}
                    onClick={() => handleSkillClick(skill.name)}
                  >
                    {skill.icon} {skill.name}
                  </button>
                ))}
              </div>

              {/* Explore Button */}
              <button
                className={`py-3 px-6 rounded-full border w-[173px] ${
                  isExploreDisabled
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-[#192023] hover:shadow-[0_4px_16px_#0003] hover:bg-white hover:text-[#192023] text-white'
                }`}
                disabled={isExploreDisabled}
              >
                Explore <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
              </button>
            </div>
          </div>
        </section>
        <section className="section ">
          <div className="bg-gradient-to-b from-[#1A2124] via-[#192023] to-[#f8f8fa]  home-talent-inner">
            <div className="talent-title">Talented Individuals that have previously worked at</div>
            <div className="talent-company-logos">
              Docusign logoUber logoMeta logoAirbnb logoKlarna logoSpotify logoWise.com logoAmazon logoTwilio logoReddit
              logo
            </div>
            <div className="talent-card-wrapper">
              {/* Continous Custom Autoplay Infinite Slider using Swiper js */}
              <Swiper
                modules={[Autoplay]}
                slidesPerView={3}
                spaceBetween={32}
                loop={true}
                autoplay={{
                  delay: 0,
                  disableOnInteraction: false,
                }}
                allowTouchMove={false}
                loopedSlides={2}
                speed={10000}
                breakpoints={{
                  200: { slidesPerView: 0.75, spaceBetween: 30 },
                  500: { slidesPerView: 1, spaceBetween: 30 },
                  700: { slidesPerView: 1.5, spaceBetween: 30 },

                  1100: { slidesPerView: 2, spaceBetween: 40 }, // For larger screens
                  1200: { slidesPerView: 2.5, spaceBetween: 40 }, // For larger screens
                }}
                className="swiper-wrapper pl-10 mt-16"
              >
                {talentData.map((talent) => (
                  <SwiperSlide key={talent.id}>
                    <div className="sliders-div" style={{ background: talent.gradientColor }}>
                      <div className="sliders-upper">
                        <FontAwesomeIcon icon={faUserAstronaut} />
                        <p>{talent.role}</p>
                      </div>
                      <div className="sliders-lower">
                        <div className="talent-info-left">
                          <h3 className=" talent-name">{talent.name}</h3>
                          <div className="talent-prev-comp-box">
                            <p className="prev-comp-heading">Previously:</p>
                            <p className="prev-comp-name">Meta, Uber, Wise, Amazon</p>
                          </div>
                          <div className="talent-skill-box">
                            <span className="skills-white-tag">Data Warehousing</span>
                            <span className="skills-white-tag">ETL</span>
                            <span className="skills-white-tag">Big Data</span>
                            <span className="skills-white-tag">AI Infrastructure</span>
                          </div>
                        </div>
                        <div className="talent-info-right">
                          <img
                            src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8NCUzQTUlMjBmYWNlfGVufDB8fDB8fHww"
                            alt=""
                            className="talent-info-img"
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="py-24 inner-section-wrap w-full">
            <p className="team-container-heading">Explore teams for specialised problems</p>
            <div className="team-stats-container container">
              <div className=" team-stats-inner">
                <div className="team-tabs-menu">
                  <span className="team-tabs bg-white">AI & ML</span>
                  <span className="team-tabs">Web3</span>
                  <span className="team-tabs">Data Engineering</span>
                  <span className="team-tabs">Product Engineering</span>
                  <span className="team-tabs">SaaS</span>
                  <span className="team-tabs">No-Code</span>
                </div>
                <div className="team-cards-container">
                  <div className="cards-container-grid">
                    <div className="cards-content shadow-[5px_3px_10px_15px_#1e306f17]">
                      <div className="">
                        <img
                          src="https://cdn.prod.website-files.com/64bd7dd499271195afe5d0c2/64e756b3ed548b398a9ef2ea_enterprise-ai.gif"
                          alt=""
                          className="cards-img"
                        />
                      </div>
                      <div className="team-card-details ">
                        <div className="card-details-heading">ChatGPT Team</div>
                        <div className="tags-wrapper">
                          <div className="tag-text">Node JS/Python</div>
                          <div className="tag-text">Express/Django</div>
                          <div className="tag-text">Express/Django</div>
                          <div className="tag-text">Express/Django</div>
                          <div className="tag-text">Express/Django</div>
                        </div>
                        <div className="team-card-skills">
                          <p>
                            <strong>Team Composition</strong>
                          </p>
                          <p>+ Data Scientist</p>
                          <p>+ Backend Engineer</p>
                          <p>+ Frontend Engineer</p>
                        </div>
                        <div className="team-card-footer">
                          <div>
                            <img
                              src="https://cdn.prod.website-files.com/64bd7dd499271195afe5d0c2/64e772e357dbc9d3090e4128_3%20faces%20(3).png"
                              alt=""
                              className="team-card-footer-img"
                            />
                          </div>
                          <div>
                            Get Details <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cards-content shadow-[5px_3px_10px_15px_#1e306f17]">
                      <div className="">
                        <img
                          src="https://cdn.prod.website-files.com/64bd7dd499271195afe5d0c2/64e756b3ed548b398a9ef2ea_enterprise-ai.gif"
                          alt=""
                          className="cards-img"
                        />
                      </div>
                      <div className="team-card-details ">
                        <div className="card-details-heading">ChatGPT Team</div>
                        <div className="tags-wrapper">
                          <div className="tag-text">Node JS/Python</div>
                          <div className="tag-text">Express/Django</div>
                          <div className="tag-text">Express/Django</div>
                          <div className="tag-text">Express/Django</div>
                          <div className="tag-text">Express/Django</div>
                        </div>
                        <div className="team-card-skills">
                          <p>
                            <strong>Team Composition</strong>
                          </p>
                          <p>+ Data Scientist</p>
                          <p>+ Backend Engineer</p>
                          <p>+ Frontend Engineer</p>
                        </div>
                        <div className="team-card-footer">
                          <div>
                            <img
                              src="https://cdn.prod.website-files.com/64bd7dd499271195afe5d0c2/64e772e357dbc9d3090e4128_3%20faces%20(3).png"
                              alt=""
                              className="team-card-footer-img"
                            />
                          </div>
                          <div>
                            Get Details <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cards-content shadow-[5px_3px_10px_15px_#1e306f17]">
                      <div className="">
                        <img
                          src="https://cdn.prod.website-files.com/64bd7dd499271195afe5d0c2/64e756b3ed548b398a9ef2ea_enterprise-ai.gif"
                          alt=""
                          className="cards-img"
                        />
                      </div>
                      <div className="team-card-details ">
                        <div className="card-details-heading">ChatGPT Team</div>
                        <div className="tags-wrapper">
                          <div className="tag-text">Node JS/Python</div>
                          <div className="tag-text">Express/Django</div>
                          <div className="tag-text">Express/Django</div>
                          <div className="tag-text">Express/Django</div>
                          <div className="tag-text">Express/Django</div>
                        </div>
                        <div className="team-card-skills">
                          <p>
                            <strong>Team Composition</strong>
                          </p>
                          <p>+ Data Scientist</p>
                          <p>+ Backend Engineer</p>
                          <p>+ Frontend Engineer</p>
                        </div>
                        <div className="team-card-footer">
                          <div>
                            <img
                              src="https://cdn.prod.website-files.com/64bd7dd499271195afe5d0c2/64e772e357dbc9d3090e4128_3%20faces%20(3).png"
                              alt=""
                              className="team-card-footer-img"
                            />
                          </div>
                          <div>
                            Get Details <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-24 flex flex-col items-center h-full w-full space-y-4">
              <h2 className="text-black mx-auto text-center text-6xl font-medium">Recent Activity</h2>
              <div className="w-full flex justify-center relative  overflow-hidden ">
                <Swiper
                  modules={[Autoplay]}
                  slidesPerView={3}
                  spaceBetween={32}
                  loop={true}
                  autoplay={{
                    delay: 0,
                    disableOnInteraction: false,
                  }}
                  allowTouchMove={false}
                  loopedSlides={2}
                  speed={10000}
                  breakpoints={{
                    200: { slidesPerView: 0.75, spaceBetween: 30 },
                    500: { slidesPerView: 1, spaceBetween: 30 },
                    700: { slidesPerView: 1.5, spaceBetween: 30 },

                    1100: { slidesPerView: 2, spaceBetween: 40 }, // For larger screens
                    1200: { slidesPerView: 2.5, spaceBetween: 40 }, // For larger screens
                  }}
                  className="swiper-wrapper pl-10 mt-16"
                >
                  {talentData.map((talent) => (
                    <SwiperSlide key={talent.id}>
                      <div className="sliders-div" style={{ background: talent.gradientColor }}>
                        <div className="sliders-upper">
                          <FontAwesomeIcon icon={faUserAstronaut} />
                          <p>{talent.role}</p>
                        </div>
                        <div className="sliders-lower">
                          <div className="talent-info-left">
                            <h3 className=" talent-name">{talent.name}</h3>
                            <div className="talent-prev-comp-box">
                              <p className="prev-comp-heading">Previously:</p>
                              <p className="prev-comp-name">Meta, Uber, Wise, Amazon</p>
                            </div>
                            <div className="talent-skill-box">
                              <span className="skills-white-tag">Data Warehousing</span>
                              <span className="skills-white-tag">ETL</span>
                              <span className="skills-white-tag">Big Data</span>
                              <span className="skills-white-tag">AI Infrastructure</span>
                            </div>
                          </div>
                          <div className="talent-info-right">
                            <img
                              src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8NCUzQTUlMjBmYWNlfGVufDB8fDB8fHww"
                              alt=""
                              className="talent-info-img"
                            />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <div className="flex items-center justify-center w-full">
              <button className="py-3 px-6 rounded-full border w-[173px] bg-white hover:shadow-[0_4px_16px_#0003] text-[#192023] hover:bg-[#192023] hover:text-white">
                Start Building <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
              </button>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container flex flex-col items-center justify-center gap-6 max-[1024px]:px-10 w-full max-[540px]:px-3">
            <div className="flex flex-row max-[991px]:flex-col relative justify-between gap-6">
              <div
                className="relative w-3/5 max-[991px]:w-full rounded-2xl overflow-hidden shadow-[0_-1px_#fff3,14px_14px_48px_#1e306f0f,2px_4px_16px_#5d57a614] bg-white/70 backdrop-blur-md"
                style={{
                  transform:
                    'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  transformStyle: 'preserve-3d',
                }}
              >
                <video src="https://cdn.prod.website-files.com/64ae816c96c759847a5045b2/65543a167f0df24e5f5f1b12_Purab%20testimonial%201.mp4" />
              </div>
              <div
                className=" relative w-2/5 max-[991px]:w-full rounded-2xl overflow-hidden shadow-[0_-1px_#fff3,14px_14px_48px_#1e306f0f,2px_4px_16px_#5d57a614] bg-white/70 backdrop-blur-md"
                style={{
                  transform:
                    'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  transformStyle: 'preserve-3d',
                }}
              >
                <div className="flex items-center justify-between flex-col text-center h-full p-12 w-full">
                  <div>
                    <FontAwesomeIcon icon={faUserAstronaut} className="h-20 align-middle" />
                  </div>
                  <div className="block w-full">
                    <h2 className="font-medium text-4xl mb-5 max-[1200px]:text-3xl max-[991px]:text-4xl">
                      Top-tier tech talent for Growth
                    </h2>
                    <p className="mb-1 opacity-70 text-wrap leading-5 text-sm">
                      Hire elite software engineers, designers and product managers within 48 hours.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row max-[991px]:flex-col relative justify-between gap-6">
              <div
                className=" relative w-[33%] max-[991px]:w-full rounded-2xl overflow-hidden shadow-[0_-1px_#fff3,14px_14px_48px_#1e306f0f,2px_4px_16px_#5d57a614] bg-white/70 backdrop-blur-md"
                style={{
                  transform:
                    'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  transformStyle: 'preserve-3d',
                }}
              >
                <div className="flex items-center justify-between flex-col text-center h-full p-12 w-full">
                  <div>
                    <FontAwesomeIcon icon={faUserAstronaut} className="h-20 align-middle" />
                  </div>
                  <div className="block w-full">
                    <h2 className="font-medium text-4xl mb-5 max-[1200px]:text-3xl max-[991px]:text-4xl">
                      Top-tier tech talent for Growth
                    </h2>
                    <p className="mb-1 opacity-70 text-wrap leading-5 text-sm">
                      Hire elite software engineers, designers and product managers within 48 hours.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className=" relative w-[67%] max-[991px]:w-full rounded-2xl overflow-hidden shadow-[0_-1px_#fff3,14px_14px_48px_#1e306f0f,2px_4px_16px_#5d57a614] bg-white/70 backdrop-blur-md"
                style={{
                  transform:
                    'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  transformStyle: 'preserve-3d',
                }}
              >
                <video src="https://cdn.prod.website-files.com/64ae816c96c759847a5045b2/65543a167f0df24e5f5f1b12_Purab%20testimonial%201.mp4" />
              </div>
            </div>
            <div className="flex flex-row max-[991px]:flex-col relative justify-between gap-6">
              <div
                className=" relative w-3/5 max-[991px]:w-full rounded-2xl overflow-hidden shadow-[0_-1px_#fff3,14px_14px_48px_#1e306f0f,2px_4px_16px_#5d57a614] bg-white/70 backdrop-blur-md"
                style={{
                  transform:
                    'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  transformStyle: 'preserve-3d',
                }}
              >
                <video src="https://cdn.prod.website-files.com/64ae816c96c759847a5045b2/65543a167f0df24e5f5f1b12_Purab%20testimonial%201.mp4" />
              </div>
              <div
                className=" relative w-2/5 max-[991px]:w-full rounded-2xl overflow-hidden shadow-[0_-1px_#fff3,14px_14px_48px_#1e306f0f,2px_4px_16px_#5d57a614] bg-white/70 backdrop-blur-md"
                style={{
                  transform:
                    'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  transformStyle: 'preserve-3d',
                }}
              >
                <div className="flex items-center justify-between flex-col text-center h-full p-12 w-full">
                  <div>
                    <FontAwesomeIcon icon={faUserAstronaut} className="h-20 align-middle" />
                  </div>
                  <div className="block w-full">
                    <h2 className="font-medium text-4xl mb-5 max-[1200px]:text-3xl max-[991px]:text-4xl">
                      Top-tier tech talent for Growth
                    </h2>
                    <p className="mb-1 opacity-70 text-wrap leading-5 text-sm">
                      Hire elite software engineers, designers and product managers within 48 hours.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section py-24 max-[768px]:py-12 max-[1024px]:px-10 max-[768px]:px-5 max-[540px]:px-3">
          <div className="container rounded-3xl px-8 py-16 bg-[#192023]">
            <h2 className="text-6xl text-center font-medium text-white mb-8">Use Cases</h2>
            <div className="grid grid-cols-[1.25fr_1fr] gap-16 max-w-[68rem] max-[1024px]:grid-cols-1">
              <div className="block opacity-100 transition-all duration-300 ease-in-out relative h-full rounded-lg border-[0.75px] border-white/10 bg-[#4d585e40] shadow-[0_20px_64px_#1e306f14] backdrop-blur-[45px] max-[1024px]:min-h-[30rem] max-[540px]:min-h-80">
                <div className="flex items-center justify-center w-full absolute top-2/4 left-2/4 transform -translate-x-1/2 -translate-y-1/2">
                  <img
                    src="https://cdn.prod.website-files.com/64ae816c96c759847a5045b2/65893c084deb0f411279b8e4_Asset.png"
                    alt=""
                    className="w-[90%]"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div class="backdrop-blur-[60px] border border-white/10 rounded-xl p-6 bg-white/5 transition-all duration-400 ease-in-out">
                  <h3 class="my-0 text-3xl font-medium leading-tight text-white">Build a product</h3>
                  <p class=" mt-2 mb-1 opacity-70 text-white leading-5">
                    Our in-house professionals assemble a team of experts to fulfil your requirements. <br />
                    <br />
                    With our proprietary execution framework, we execute the project, ensuring on or before time
                    delivery.
                  </p>
                </div>

                <div class="backdrop-blur-[60px] border border-white/10 rounded-xl p-6 bg-white/5 transition-all duration-400 ease-in-out">
                  <h3 class="my-0 text-3xl font-medium leading-tight text-white">Build a product</h3>
                  <p class=" mt-2 mb-1 opacity-70 text-white leading-5">
                    Our in-house professionals assemble a team of experts to fulfil your requirements. <br />
                    <br />
                    With our proprietary execution framework, we execute the project, ensuring on or before time
                    delivery.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center w-full mt-8">
              <button className="py-3 px-6 rounded-full border w-[173px] bg-white hover:shadow-[0_4px_16px_#0003] text-[#192023] hover:bg-[#192023] hover:text-white">
                Start Building <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
              </button>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container max-[1024px]:px-10 pt-24 max-[768px]:px-4 max-[768px]:pt-12">
            <div className="grid gap-4 auto-rows-auto items-center grid-cols-[minmax(auto,32rem)_1fr] max-[991px]:grid-cols-1 max-[991px]:gap-12 max-[600px]:gap-6">
              <div className="py-8 relative z-[2]">
                <div className="pb-1 overflow-hidden">
                  <div className="max-w-[28rem] w-full text-5xl leading-none tracking-tighter font-semibold max-[768px]:text-[2rem]">
                    Why companies build with Ai clues
                  </div>
                </div>
                <div className="max-w-[28rem] w-full">
                  <p className="mt-4 mb-1 opacity-70 leading-5">
                    Our streamlined, AI-driven platform handles the intricacies of understanding the problem statements,
                    match-making, assembling the team and delivering on or before time.{' '}
                  </p>
                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex flex-col">
                      <h3 className="text-4xl mb-1 font-medium">48 hrs</h3>
                      <p className="opacity-70 mb-1">Turn Around Time</p>
                    </div>
                    <div className="flex flex-col">
                      <h3 className="text-4xl mb-1 font-medium">60%</h3>
                      <p className="opacity-70 mb-1">Lower Costs</p>
                    </div>
                    <div className="flex flex-col">
                      <h3 className="text-4xl mb-1 font-medium">7x</h3>
                      <p className="opacity-70 mb-1">Faster than Traditional Hiring</p>
                    </div>
                    <div className="flex flex-col">
                      <h3 className="text-4xl mb-1 font-medium">100%</h3>
                      <p className="opacity-70 mb-1">Match Rate</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pb-5 max-[991px]:flex max-[991px]:items-center max-[991px]:justify-center w-full max-[991px]:px-3">
                <div
                  className="transform scale-[1.2] max-[991px]:max-w-[90%] 
                "
                >
                  <video
                    playsInline
                    autoPlay
                    loop
                    muted
                    className="pointer-events-none w-full h-full align-baseline inline-block object-contain overflow-clip "
                  >
                    <source src="https://cdn.prod.website-files.com/64ae816c96c759847a5045b2/64ede95a1c3d386f2787e975_Final%20Globe%20with%20Banners%203-transcode.mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container py-16 max-[991px]:py-8 max-[768px]:px-5 max-[500px]:px-3">
            <div className="flex flex-col items-center justify-center w-full bg-black rounded-full py-16 px-8 max-[500px]:min-h-[38rem]">
              <h2 className="text-6xl font-medium text-white text-center max-[768px]:text-[2rem] max-[768px]:tracking-[-1px]">
                Make ClanX
                <br />
                your competitive advantage
              </h2>
              <div className="flex items-center justify-center w-full mt-6">
                <button className="py-3 px-6 rounded-full border w-[173px] bg-white hover:shadow-[0_4px_16px_#0003] text-[#192023] hover:bg-[#192023] hover:text-white">
                  Start Building <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container flex items-center flex-col gap-12 pb-16 max-[1024px]:px-10 max-[768px]:px-4">
            <div className="grid grid-cols-[minmax(auto,40rem)_1fr] gap-16 w-full max-[991px]:grid-cols-1 max-[991px]:gap-4">
              <h2 className="text-6xl font-medium -tracking-[0.04ch] text-[#192023] max-[768px]:text-[2rem]">
                Compare ClanX’s costs with traditional hiring
              </h2>
              <p className="self-end max-w-[28rem] justify-self-end ">
                See how clanX's flexible hiring model significantly trims expenses compared to traditional hiring
                methods.
              </p>
            </div>
            <div className="component-grid w-full">
              <div className="card relative p-6 rounded-2xl bg-white shadow-[0_20px_64px_0_#1e306f14] ">
                <div className="flex justify-between items-start gap-4 mb-7">
                  <h3 className="text-4xl font-medium">Developers</h3>
                  <img
                    src="https://cdn.prod.website-files.com/64ae816c96c759847a5045b2/64b8bbbcb95f3502e597cd4b_Asset-2.svg"
                    alt=""
                    className="w-12"
                  />
                </div>
                <div className="flex flex-col gap-5">
                  <div className="flex relative items-center flex-row justify-between gap-4 ">
                    <span className="text-lg">How Many?</span>
                    <div className="flex gap-4">
                      <div className="grid grid-flow-col auto-cols-auto border shadow-[1px_1px_0_0_#0000001a] gap-0 w-36 rounded-[2rem]">
                        <div className="opacity-40 text-[#192023] cursor-pointer flex flex-row justify-center items-center w-12 h-12 text-xl">
                          -
                        </div>
                        <div className="px-3 py-2 h-[38px] w-full min-h-12 border-[1px_#000] text-center">1</div>
                        <div className="opacity-40 text-[#192023] cursor-pointer flex flex-row justify-center items-center w-12 h-12 text-xl">
                          +
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex relative items-center flex-row justify-between gap-4 ">
                    <span className="text-lg">Years of Experience</span>
                    <div className="flex gap-4">
                      <div className="grid grid-flow-col auto-cols-auto border shadow-[1px_1px_0_0_#0000001a] gap-0 w-36 rounded-[2rem]">
                        <div className="opacity-40 text-[#192023] cursor-pointer flex flex-row justify-center items-center w-12 h-12 text-xl">
                          -
                        </div>
                        <div className="px-3 py-2 h-[38px] w-full min-h-12 border-[1px_#000] text-center">3</div>
                        <div className="opacity-40 text-[#192023] cursor-pointer flex flex-row justify-center items-center w-12 h-12 text-xl">
                          +
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card relative p-6 rounded-2xl bg-white shadow-[0_20px_64px_0_#1e306f14] ">
                <div className="flex justify-between items-start gap-4 mb-7">
                  <h3 className="text-4xl font-medium">Developers</h3>
                  <img
                    src="https://cdn.prod.website-files.com/64ae816c96c759847a5045b2/64b8bbbcb95f3502e597cd4b_Asset-2.svg"
                    alt=""
                    className="w-12"
                  />
                </div>
                <div className="flex flex-col gap-5">
                  <div className="flex relative items-center flex-row justify-between gap-4 ">
                    <span className="text-lg">How Many?</span>
                    <div className="flex gap-4">
                      <div className="grid grid-flow-col auto-cols-auto border shadow-[1px_1px_0_0_#0000001a] gap-0 w-36 rounded-[2rem]">
                        <div className="opacity-40 text-[#192023] cursor-pointer flex flex-row justify-center items-center w-12 h-12 text-xl">
                          -
                        </div>
                        <div className="px-3 py-2 h-[38px] w-full min-h-12 border-[1px_#000] text-center">1</div>
                        <div className="opacity-40 text-[#192023] cursor-pointer flex flex-row justify-center items-center w-12 h-12 text-xl">
                          +
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex relative items-center flex-row justify-between gap-4 ">
                    <span className="text-lg">Years of Experience</span>
                    <div className="flex gap-4">
                      <div className="grid grid-flow-col auto-cols-auto border shadow-[1px_1px_0_0_#0000001a] gap-0 w-36 rounded-[2rem]">
                        <div className="opacity-40 text-[#192023] cursor-pointer flex flex-row justify-center items-center w-12 h-12 text-xl">
                          -
                        </div>
                        <div className="px-3 py-2 h-[38px] w-full min-h-12 border-[1px_#000] text-center">3</div>
                        <div className="opacity-40 text-[#192023] cursor-pointer flex flex-row justify-center items-center w-12 h-12 text-xl">
                          +
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-card bg-[#192023]  flex items-stretch flex-col rounded-2xl text-white py-10 px-14 ">
                <div className="flex flex-col flex-1 relative">
                  <div className="relative grid grid-cols-3 grid-rows-auto gap-0 backdrop-blur-[120px] bg-[#f8f8fa1a] border border-[#ffffff1a] rounded-[4.5rem] p-1 shadow-inner shadow-[#000c]">
                    <span className="text-black bg-white font-medium text-center rounded-[2.625rem] pt-2 pl-3 pr-4 inline-block relative px-[30px] py-[9px] cursor-pointer align-top">
                      Monthly
                    </span>
                    <span className="font-medium text-center rounded-[2.625rem] pt-2 pl-3 pr-4 inline-block relative px-[30px] py-[9px] cursor-pointer align-top">
                      Quaterly
                    </span>
                    <span className="font-medium text-center rounded-[2.625rem] pt-2 pl-3 pr-4 inline-block relative px-[30px] py-[9px] cursor-pointer align-top">
                      Annually
                    </span>
                  </div>

                  <div className="flex pt-10 h-full items-center justify-start">
                    <div className="flex flex-col relative gap-6  h-full items-start justify-center w-full">
                      <div>
                        <h3 className="text-4xl font-medium mb-2 text-[#14dea2]">Rs. 1,548,980</h3>
                        <p className="text-lg opacity-70">Total monthly cost with ClanX</p>
                      </div>
                      <div className="flex items-center justify-center w-full">
                        <button className="py-3 px-6 rounded-full border w-full bg-white hover:shadow-[0_4px_16px_#0003] text-[#192023] hover:bg-[#192023] hover:text-white">
                          Get Quote
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card relative p-6 rounded-2xl bg-white shadow-[0_20px_64px_0_#1e306f14]">
                <div className="flex justify-between items-start gap-4 mb-7">
                  <h3 className="text-4xl font-medium">Developers</h3>
                  <img
                    src="https://cdn.prod.website-files.com/64ae816c96c759847a5045b2/64b8bbbcb95f3502e597cd4b_Asset-2.svg"
                    alt=""
                    className="w-12"
                  />
                </div>
                <div className="flex flex-col gap-5">
                  <div className="flex relative items-center flex-row justify-between gap-4 ">
                    <span className="text-lg">How Many?</span>
                    <div className="flex gap-4">
                      <div className="grid grid-flow-col auto-cols-auto border shadow-[1px_1px_0_0_#0000001a] gap-0 w-36 rounded-[2rem]">
                        <div className="opacity-40 text-[#192023] cursor-pointer flex flex-row justify-center items-center w-12 h-12 text-xl">
                          -
                        </div>
                        <div className="px-3 py-2 h-[38px] w-full min-h-12 border-[1px_#000] text-center">1</div>
                        <div className="opacity-40 text-[#192023] cursor-pointer flex flex-row justify-center items-center w-12 h-12 text-xl">
                          +
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex relative items-center flex-row justify-between gap-4 ">
                    <span className="text-lg">Years of Experience</span>
                    <div className="flex gap-4">
                      <div className="grid grid-flow-col auto-cols-auto border shadow-[1px_1px_0_0_#0000001a] gap-0 w-36 rounded-[2rem]">
                        <div className="opacity-40 text-[#192023] cursor-pointer flex flex-row justify-center items-center w-12 h-12 text-xl">
                          -
                        </div>
                        <div className="px-3 py-2 h-[38px] w-full min-h-12 border-[1px_#000] text-center">3</div>
                        <div className="opacity-40 text-[#192023] cursor-pointer flex flex-row justify-center items-center w-12 h-12 text-xl">
                          +
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card relative p-6 rounded-2xl bg-white shadow-[0_20px_64px_0_#1e306f14] ">
                <div className="flex justify-between items-start gap-4 mb-7">
                  <h3 className="text-4xl font-medium">Developers</h3>
                  <img
                    src="https://cdn.prod.website-files.com/64ae816c96c759847a5045b2/64b8bbbcb95f3502e597cd4b_Asset-2.svg"
                    alt=""
                    className="w-12"
                  />
                </div>
                <div className="flex flex-col gap-5">
                  <div className="flex relative items-center flex-row justify-between gap-4 ">
                    <span className="text-lg">How Many?</span>
                    <div className="flex gap-4">
                      <div className="grid grid-flow-col auto-cols-auto border shadow-[1px_1px_0_0_#0000001a] gap-0 w-36 rounded-[2rem]">
                        <div className="opacity-40 text-[#192023] cursor-pointer flex flex-row justify-center items-center w-12 h-12 text-xl">
                          -
                        </div>
                        <div className="px-3 py-2 h-[38px] w-full min-h-12 border-[1px_#000] text-center">1</div>
                        <div className="opacity-40 text-[#192023] cursor-pointer flex flex-row justify-center items-center w-12 h-12 text-xl">
                          +
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex relative items-center flex-row justify-between gap-4 ">
                    <span className="text-lg">Years of Experience</span>
                    <div className="flex gap-4">
                      <div className="grid grid-flow-col auto-cols-auto border shadow-[1px_1px_0_0_#0000001a] gap-0 w-36 rounded-[2rem]">
                        <div className="opacity-40 text-[#192023] cursor-pointer flex flex-row justify-center items-center w-12 h-12 text-xl">
                          -
                        </div>
                        <div className="px-3 py-2 h-[38px] w-full min-h-12 border-[1px_#000] text-center">3</div>
                        <div className="opacity-40 text-[#192023] cursor-pointer flex flex-row justify-center items-center w-12 h-12 text-xl">
                          +
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <footer className="section">
        <div className="sub-footer container pt-20 pb-8 max-[1024px]:px-4">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-[0.5fr_0.75fr_0.75fr] max-[991px]:grid-cols-1 max-[991px]:gap-y-16 max-[540px]:gap-y-6 pb-8 items-start gap-x-[4vw] gap-y-4 border-[#0003] border-b">
              <div className="flex flex-col">
                <a href="#" className="text-4xl font-semibold text-left">
                  Ai clues
                </a>
                <span className="mt-1 mb-4">From hi to hired in 48 hours</span>
                <div className="flex flex-row items-center gap-3 justify-start">
                  <a href="#">
                    <FontAwesomeIcon icon={faUserAstronaut} />
                  </a>
                  <a href="#">
                    <FontAwesomeIcon icon={faUserAstronaut} />
                  </a>
                  <a href="#">
                    <FontAwesomeIcon icon={faUserAstronaut} />
                  </a>
                  <a href="#">
                    <FontAwesomeIcon icon={faUserAstronaut} />
                  </a>
                </div>
              </div>
              <div className="">
                <div className="text-3xl font-medium -tracking-[0.075rem]">
                  <span className=" text-[#b4b7b9]">Don’t miss a beat.</span> <br /> Subscribe to our newsletter.
                </div>
                <form className="mt-4 max-w-[27rem] w-full rounded-[3rem] bg-gray-200 mb-3 max-[991px]:max-w-[30rem]">
                  <div className="grid grid-cols-[1fr_8rem] gap-4 ">
                    <input
                      type="text"
                      className=" pl-4 rounded-l-[3rem] border min-h-11 py-2 px-3 "
                      placeholder="johndoe@aiclus.com"
                    />
                    <input
                      type="submit"
                      value="Submit"
                      className=" text-white text-center bg-[#192023] border border-[#d2eaeb] rounded-3xl px-5 py-2"
                    />
                  </div>
                </form>
              </div>
              <div className="grid grid-cols-2 items-start gap-4">
                <div class="flex flex-col items-start">
                  <a href="#" className=" py-1 underline">
                    For Companies
                  </a>
                  <a href="#" className=" py-1 underline">
                    For Freelancers
                  </a>
                  <a href="/#" className=" py-1 underline">
                    Hire AI/ML Developers
                  </a>
                  <a href="#" className=" py-1 underline">
                    About ClanX
                  </a>
                  <a href="#" className=" py-1 underline">
                    Contact
                  </a>
                  <a href="#" className=" py-1 underline">
                    Ambassador Program
                  </a>
                  <a href="#" className=" py-1 underline">
                    AI Enabled Products
                  </a>
                  <a href="#" className=" py-1 underline">
                    Reports
                  </a>
                  <a href="#" className=" py-1 underline">
                    Alternatives
                  </a>
                </div>
                <div className="flex flex-col items-start">
                  <a href="#" className="py-1 underline">
                    Hire Developers
                  </a>
                  <a href="#" className="py-1 underline">
                    Hire Designers
                  </a>
                  <a href="#" className="py-1 underline">
                    Hire Product Managers
                  </a>
                  <a href="#" className="py-1 underline">
                    Hire Software Engineers
                  </a>
                  <a href="#" className="py-1 underline">
                    FAQs
                  </a>
                  <a href="#" className="py-1 underline">
                    Glossary
                  </a>
                  <a href="#" className="py-1 underline">
                    SaaS Landing Pages
                  </a>
                  <a href="#" className="py-1 underline">
                    Interviews
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between pb-4 max-[768px]:flex-col-reverse max-[768px]:items-start">
              <div className="max-[768px]:mt-8">© Copyright 2023, AI Clues. All rights reserved.</div>
              <div className="flex flex-row gap-6 items-start whitespace-normal justify-between max-[768px]:flex-col max-[768px]:gap-1">
                <a href="#" className="text-black text-right">
                  Privacy Policy
                </a>
                <a href="#" className="text-black text-right">
                  Terms of Service
                </a>
                <a href="#" className="text-black text-right">
                  Public Notice
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
