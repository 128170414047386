import React, { useEffect, useState } from 'react';
import MuxPlayer from '@mux/mux-player-react/lazy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faClock, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { Spin } from 'antd';

function InterviewScreen({
  setStartInterview,
  currentPage,
  generatedUserQuestions,
  onCurrentPageChange,
  saveLoading,
  videoElement,
  stopButtonRef,
  feedbackLoading,
  questionFeedback,
  onGenerateQuestionFeedback,
  onPrevPageChange,
  userMockInterview,

  timeLeft,
  formatTime,
}) {
  const generatedFeedback = generatedUserQuestions[currentPage]?.feedback || questionFeedback?.feedback;

  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = React.createRef();

  const toggleAudio = () => {};

  const [audioCurrentTime, setAudioCurrentTime] = useState(0);
  const [audioDuration, setAudioDuration] = useState(0);

  const handleTimeUpdate = (e) => {
    setAudioCurrentTime(e.target.currentTime);
    setAudioDuration(e.target.duration);
  };

  return (
    <div className=" bg-white w-full">
      <div className="flex flex-col items-start justify-start mb-2 gap-1">
        {/* <button
          className="text-blue-700 hover:text-blue-600 font-medium"
          onClick={() => setStartInterview(false)} // Navigate to Instruction page
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-1" /> My sessions
        </button> */}
        <div className="flex flex-row justify-between items-center w-full">
          <span className="text-xl font-bold">{userMockInterview?.job?.title}</span>
          <div>
            {generatedUserQuestions[currentPage]?.video_playback_id ? (
              <div className="space-x-2">
                {' '}
                {currentPage + 1 === 1 ? (
                  <button
                    className="btn rounded-lg bg-blue-700 text-white hover:bg-blue-600 px-4 py-2"
                    onClick={() => setStartInterview((prev) => !prev)} // Navigate to Instruction page
                  >
                    Back
                  </button>
                ) : (
                  <button
                    className="btn rounded-lg bg-blue-700 text-white hover:bg-blue-600 px-4 py-2 disabled:bg-gray-700 disabled:hover:bg-gray-700"
                    onClick={onPrevPageChange}
                    disabled={currentPage + 1 === 1}
                  >
                    Previous Question
                  </button>
                )}
                <button
                  className="btn rounded-lg bg-blue-700 text-white hover:bg-blue-600 px-4 py-2"
                  onClick={() => onCurrentPageChange('add')}
                >
                  Next Question
                </button>
              </div>
            ) : saveLoading ? (
              <button className="btn rounded-lg bg-green-700 text-white hover:bg-green-600 px-4 py-2">
                Saving your response
              </button>
            ) : (
              <div className="space-x-2">
                <button
                  className="btn rounded-lg bg-blue-700 text-white hover:bg-blue-600 px-4 py-2"
                  ref={stopButtonRef}
                >
                  Save Response
                </button>
                <button
                  className="btn rounded-lg bg-blue-700 text-white hover:bg-blue-600 px-4 py-2"
                  onClick={() => setStartInterview(false)} // Navigate to Instruction page
                >
                  Pause / Back
                </button>
              </div>
            )}
          </div>
        </div>
        <div className=" flex items-center max-[768px]:items-start w-full mt-2 mb-1 gap-x-2">
          <span className="p-3 text-sm border font-medium bg-slate-50 rounded-lg">{currentPage + 1}/10</span>

          <span className="p-3 text-sm border rounded-lg bg-slate-50 w-full text-center font-medium">
            {generatedUserQuestions[currentPage]?.question}{' '}
            <span className="ml-2 font-normal">
              ({generatedUserQuestions[currentPage]?.time_to_answer_in_minutes} mins)
            </span>
          </span>
          <span
            className={`min-w-max text-sm font-medium tracking-tight p-3 rounded-lg  ${
              !generatedUserQuestions[currentPage]?.video_playback_id
                ? 'bg-purple-100 text-purple-800'
                : 'bg-green-200 text-green-900'
            }`}
          >
            {!generatedUserQuestions[currentPage]?.video_playback_id ? 'In Progress' : 'Completed'}
          </span>

          {!generatedUserQuestions[currentPage]?.video_playback_id && (
            <p className="text-sm  border p-3 bg-green-100 rounded-lg min-w-max text-green-900">
              <FontAwesomeIcon icon={faClock} className="mr-1" />
              {formatTime(timeLeft)}
            </p>
          )}
        </div>
      </div>
      {generatedUserQuestions[currentPage]?.video_playback_id ? (
        <div>
          <div className="w-full flex justify-center mb-2">
            <MuxPlayer
              playbackId={generatedUserQuestions[currentPage]?.video_playback_id}
              streamType="on-demand"
              className="mux-player rounded-lg w-full "
              loading="viewport"
            />
          </div>
          <div
            className="flex flex-col items-start mt-2 
          w-full
          "
          >
            {/* <span className="text-sm font-medium">Audio recording</span>
            <div className="flex items-center w-full">
              
              <button className="btn text-blue-600 hover:text-blue-800 mr-2" onClick={toggleAudio}>
                <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
              </button>
              <span className="text-sm text-gray-500 mr-2">{formatTime(audioCurrentTime)}</span>
              <audio ref={audioRef} src="your-audio-file.mp3" onTimeUpdate={(e) => handleTimeUpdate(e)}></audio>              
              <div className="relative w-full h-2 bg-gray-300 rounded-full mr-4">
                <div
                  className="absolute h-2 bg-blue-600 rounded-full"
                  style={{ width: `${(audioCurrentTime / audioDuration) * 100}%` }}
                />
              </div>
              <span className="text-sm text-gray-500">{formatTime(audioDuration)}</span>
            </div> */}

            {feedbackLoading ? (
              <button className="btn rounded-lg bg-gray-700 text-white px-4 py-2">Loading feedback</button>
            ) : (
              <>
                {!generatedFeedback && (
                  <button
                    className="btn rounded-lg bg-blue-700 text-white hover:bg-blue-600 px-4 py-2"
                    onClick={() => onGenerateQuestionFeedback()}
                  >
                    Get Feedback
                  </button>
                )}
                {generatedFeedback && (
                  <p>
                    <span className="font-medium">Feedback:</span> {generatedFeedback}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      ) : saveLoading ? (
        <div className="w-full mt-4 flex items-center justify-center">
          <Spin />
        </div>
      ) : (
        <div>
          <div className="centered justify-center">
            <video id="player" ref={videoElement} autoPlay muted className="my-2 rounded-lg" />
          </div>
          <span>You will be evaluated on: {generatedUserQuestions[currentPage]?.evaluation_parameter}</span>
        </div>
      )}
    </div>
  );
}

export default InterviewScreen;
