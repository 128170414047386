import React from 'react';
import MainLayout from '../../../_layout/MainLayout';
import useMockInterview from './useMockInterview';
import InterviewInstructions from './_components/InterviewInstructions';
import InterviewScreen from './_components/InterviewScreen';
import { Spin } from 'antd';

function MockInterview(props) {
  const {
    loading,
    navigate,
    userMockInterview,
    questionsLoading,
    startInterview,
    feedbackLoading,
    questionFeedback,
    currentPage,
    generatedUserQuestions,
    saveLoading,
    videoElement,
    stopButtonRef,

    timeLeft,
    formatTime,

    setStartInterview,
    onGenerateQuestionFeedback,
    onCurrentPageChange,
    onStartClick,
    onPrevPageChange,
    handleStartInterview,
  } = useMockInterview(props);
  return (
    <MainLayout activeMenuItem="home">
      <div className="p-4 sticky max-[540px]:p-2 ">
        {!startInterview && (
          <div className="centered justify-between  window-container h-[8%]">
            <h2 className="window-title !px-0">
              Mock Interview for {userMockInterview?.job?.title} at {userMockInterview?.job?.company}
            </h2>
          </div>
        )}
        {loading ? (
          <div className="centered spinner-box">
            <Spin size="large" />
          </div>
        ) : (
          <div className={`${startInterview ? 'h-screen' : 'h-[92%]'}`}>
            {startInterview ? (
              <InterviewScreen
                setStartInterview={setStartInterview}
                saveLoading={saveLoading}
                currentPage={currentPage}
                generatedUserQuestions={generatedUserQuestions}
                onCurrentPageChange={onCurrentPageChange}
                videoElement={videoElement}
                stopButtonRef={stopButtonRef}
                feedbackLoading={feedbackLoading}
                questionFeedback={questionFeedback}
                onGenerateQuestionFeedback={onGenerateQuestionFeedback}
                onPrevPageChange={onPrevPageChange}
                timeLeft={timeLeft}
                formatTime={formatTime}
                userMockInterview={userMockInterview}
              />
            ) : (
              <InterviewInstructions
                questionsLoading={questionsLoading}
                onStartClick={onStartClick}
                handleStartInterview={handleStartInterview}
              />
            )}
          </div>
        )}
      </div>
    </MainLayout>
  );
}

export default MockInterview;
