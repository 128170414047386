import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import InputField from '../../../../_component/InputField';
import { faCircleMinus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

function FineTunedResumeFormSkills({
  parsedResumeForm,
  onParseResumeSkillChange,
  onParseResumeSkillRemoveClick,
  onParseResumeSkillAddClick,
}) {
  return (
    <div className="my-8 max-[768px]:my-4">
      <div className="flex flex-row gap-1 flex-wrap mb-2">
        {Array.isArray(parsedResumeForm?.skills) &&
          parsedResumeForm.skills.map((item, index) => (
            <div className="mb-0.5 rounded-2xl border border-gray-800 p-1.5" key={item}>
              <InputField
                id={`skill-${index}`}
                type="text"
                name="skill"
                placeholder="Enter skill"
                defaultValue={item}
                onBlur={(e) => onParseResumeSkillChange(index, e)}
                containerClassName="inline"
                inputClassName="text-sm max-w-[100px]"
              />
              <span>
                <FontAwesomeIcon
                  icon={faCircleMinus}
                  className="ml-1 text-danger cursor-pointer"
                  onClick={() => onParseResumeSkillRemoveClick(index)}
                />
              </span>
            </div>
          ))}
      </div>
      <div className="flex flex-row items-center w-full ">
        <h2 className="text-gray-600 font-medium text-sm">Add Skills</h2>{' '}
        <FontAwesomeIcon
          icon={faPlusCircle}
          className="ml-2 h-4 text-gray-600 cursor-pointer"
          onClick={onParseResumeSkillAddClick}
        />
      </div>
    </div>
  );
}

export default FineTunedResumeFormSkills;
