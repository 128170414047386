import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

// List of ethnicity options with corresponding keys and values
const ethnicityOptions = [
  {
    key: 'Asian',
    value: 'asian',
  },
  {
    key: 'Black or African American',
    value: 'black_or_african_american',
  },
  {
    key: 'Hispanic or Latino',
    value: 'hispanic_or_latino',
  },
  {
    key: 'White',
    value: 'white',
  },
  {
    key: 'Native American',
    value: 'native_american',
  },
  {
    key: 'Pacific Islander',
    value: 'pacific_islander',
  },
  {
    key: 'Other',
    value: 'other',
  },
];

function EthnicityBasic({ onEthnicityChange, selectedValues }) {
  const [selectedEthnicities, setSelectedEthnicities] = useState([]);
  const [dropdownValue, setDropdownValue] = useState('');

  // useEffect hook to update selected ethnicities whenever selectedValues prop changes
  useEffect(() => {
    setSelectedEthnicities(selectedValues || []);
  }, [selectedValues]);

  // Handle the selection of an ethnicity from the dropdown
  const handleEthnicitySelect = (value) => {
    if (value && !selectedEthnicities.includes(value)) {
      const newSelections = [...selectedEthnicities, value]; // Add the new selection to the existing list
      setSelectedEthnicities(newSelections); // Update state with new selections
      onEthnicityChange(newSelections); // Notify parent component of the change
      setDropdownValue(''); // Reset the dropdown value
    }
  };

  // Handle the removal of an ethnicity from the selected list
  const handleEthnicityRemove = (value) => {
    const newSelections = selectedEthnicities.filter((item) => item !== value); // Filter out the removed item
    setSelectedEthnicities(newSelections); // Update state with the remaining selections
    onEthnicityChange(newSelections); // Notify parent component of the change
  };

  // Filter out the options that have already been selected
  const availableOptions = ethnicityOptions.filter((option) => !selectedEthnicities.includes(option.value));

  return (
    <>
      <span className="select mt-1">
        <select
          className="select-control bg-transparent"
          onChange={(e) => handleEthnicitySelect(e.target.value)}
          value={dropdownValue}
          name={'ethnicity'}
        >
          <option disabled value="">
            Select Ethnicity
          </option>
          {availableOptions.map((item) => (
            <option key={item.key} value={item.value}>
              {item.key}
            </option>
          ))}
        </select>
      </span>
      <div className="flex gap-2 flex-row flex-wrap my-2">
        {selectedEthnicities.map((ethnicity) => (
          <div
            key={ethnicity}
            className="rounded-lg px-2 py-1 border-gray-900 border bg-white flex flex-row  items-center"
          >
            <span className="mx-2 text-sm font-medium">
              {ethnicityOptions.find((opt) => opt.value === ethnicity)?.key}
            </span>
            <div className="mx-2">
              <FontAwesomeIcon
                icon={faWindowClose}
                onClick={() => handleEthnicityRemove(ethnicity)}
                className="h-4 cursor-pointer text-red-500"
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default EthnicityBasic;
