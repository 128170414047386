import React from 'react';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Spin } from 'antd';

function JobListView({ onDragEnd, columns, loading, tableVisibility, toggleTableVisibility, onJobNavigateClick }) {
  return (
    <div className="list-view-box">
      <DragDropContext onDragEnd={onDragEnd}>
        {Object.entries(columns).map(([columnId, column], index) => (
          <div className="list-box" key={columnId}>
            <Droppable droppableId={columnId} key={columnId} index={index} className="">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    background: snapshot.isDraggingOver ? '#F4F5F7' : 'white',
                  }}
                  className="list-container"
                >
                  <div className="centered status-header">
                    <FontAwesomeIcon
                      icon={tableVisibility[columnId] ? faAngleUp : faAngleDown}
                      className="icon-width"
                      onClick={() => toggleTableVisibility(columnId)}
                    />
                    {/* <FontAwesomeIcon icon={faAngleDown} className="icon-width" /> */}
                    <h4 className="table-title" style={{ color: column.color }}>
                      {column?.title}
                    </h4>
                    {/* <FontAwesomeIcon icon={faPlus} className="icon-width" /> */}
                  </div>
                  <div
                    className={`table-box transition-all duration-300 overflow-hidden ${
                      tableVisibility[columnId] ? 'max-h-full opacity-100' : 'max-h-0 opacity-0'
                    }`}
                  >
                    <table className="table-box">
                      <thead className="">
                        <tr className="fi">
                          {/* <th className="table-head-checkbox">
                      <div className="centered">
                        <input id="checkbox-table-search-1" type="checkbox" className="checkbox" />
                        <label htmlFor="checkbox-table-search-1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </th> */}
                          <th className="table-head-job">
                            <div className="centered ">Job Profile</div>
                          </th>
                          <th className="table-head-common">
                            <div className="centered">Company</div>
                          </th>
                          <th className="table-head-common">
                            <div className="centered ">Location</div>
                          </th>

                          {/* <th className="table-head-common">
                        <div className="centered">See Job</div>
                      </th> */}

                          {/* <th className="table-head-common">
                        <div className="centered ">Created At</div>
                      </th> */}
                          <th className="table-head-common">
                            <div className="centered ">{column?.title} At</div>
                          </th>
                        </tr>
                      </thead>
                      {loading ? (
                        <tbody className="centered spinner-box mb-1 mt-0">
                          <Spin size="small" />
                        </tbody>
                      ) : (
                        <tbody>
                          {Array.isArray(column.items) && column.items.length ? (
                            column.items.map((item, index) => (
                              <Draggable draggableId={item._id} index={index} key={item._id}>
                                {(provided, snapshot) => (
                                  <tr
                                    ref={provided.innerRef}
                                    snapshot={snapshot}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="table-row"
                                    style={{
                                      userSelect: 'none',
                                      backgroundColor: snapshot.isDragging ? '#e8e9eb' : '#ffffff',
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    {/* <td className="data-checkbox ">
                              <div className="centered">
                                <input type="checkbox" className="checkbox" />
                              </div>
                            </td> */}
                                    <td className="table-data-job">
                                      <div className="flex flex-row">
                                        <p className="study w-max" onClick={() => onJobNavigateClick(item?._id)}>
                                          {item?.title}
                                        </p>
                                        <a href={item?.bookmark_url} target="_blank" rel="noopener noreferrer">
                                          <FontAwesomeIcon
                                            icon={faArrowUpRightFromSquare}
                                            className="w-3.5 h-3.5 ml-4"
                                          />
                                        </a>
                                      </div>
                                    </td>
                                    <td className="table-data-common ">
                                      <div className="text-xs font-medium text-gray-600">
                                        {item?.company.replace(/·/g, '').trim()}
                                      </div>
                                    </td>
                                    <td className="table-data-common ">
                                      <div className="text-xs text-gray-400">
                                        {item?.location.replace(/·/g, '').trim()}
                                      </div>
                                    </td>

                                    {/* <td className="table-data-common text-center ">
                                <a href={item?.bookmark_url} target="_blank" rel="noopener noreferrer">
                                  <FontAwesomeIcon
                                    icon={faArrowUpRightFromSquare}
                                    className="w-3.5 h-3.5"
                                  />
                                </a>
                              </td> */}

                                    {/* <td className="table-data-common">
                                <div className="date">
                                  {new Intl.DateTimeFormat('en-GB', {
                                    day: '2-digit',
                                    month: 'long',
                                    year: 'numeric',
                                  }).format(new Date(item?.createdAt))}
                                </div>
                              </td> */}
                                    <td className="table-data-last">
                                      <div className="date">
                                        {item?.updatedAt
                                          ? new Intl.DateTimeFormat('en-GB', {
                                              day: '2-digit',
                                              month: 'long',
                                              year: 'numeric',
                                            }).format(new Date(item?.updatedAt))
                                          : 'No date available'}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="9" className="no-jobs">
                                <p>No jobs here yet!</p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      )}
                    </table>
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        ))}
      </DragDropContext>
    </div>
  );
}

export default JobListView;
