import React from 'react';
import DatePicker from 'react-datepicker';
import CompanySearchBasic from '../../../../_component/Company';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../../../_component/InputField';

function ProfessionEditForm({
  professionForm,
  onProfessionFormChange,
  onProfessionSave,
  handleProfessionCheckBoxChange,
  professionFormError,
}) {
  return (
    <div className="experience-edit-box">
      <div className="edit-container ">
        <InputField
          label="Title"
          placeholder="Enter title"
          name="headline"
          defaultValue={professionForm?.headline}
          onBlur={onProfessionFormChange}
          containerClassName="edit-form-group"
          inputClassName="edit-input"
          labelClassName="edit-label"
          required
          error={professionFormError.headline}
          errorClassName="edit-alert"
        />
        <div className="edit-form-group">
          <p className="edit-label">
            <span className="text-red-600">*</span> Company
          </p>
          <CompanySearchBasic
            defaultValue={professionForm?.company && professionForm.company?.company_name}
            onSearchChange={(e) =>
              onProfessionFormChange({
                target: { name: 'company', value: e },
              })
            }
          />
          {professionFormError.company && professionFormError.company.length > 0 && (
            <p className="edit-alert-select">
              <FontAwesomeIcon icon={faTriangleExclamation} /> {professionFormError.company}
            </p>
          )}
        </div>

        <InputField
          label="Description"
          placeholder="Enter Description"
          name="description"
          defaultValue={professionForm?.description}
          onBlur={onProfessionFormChange}
          containerClassName="edit-form-group"
          inputClassName="edit-input"
          labelClassName="edit-label"
        />

        <div className="edit-form-group">
          <p className="edit-label">
            <span className="text-red-600">*</span> Start Date
          </p>
          <DatePicker
            selected={professionForm?.start_date || ''}
            placeholderText="Select"
            onChange={(date) => {
              onProfessionFormChange({
                target: { value: date, name: 'start_date' },
              });
            }}
            showMonthDropdown
            showYearDropdown
            className="edit-input"
          />
          {professionFormError.start_date && professionFormError.start_date.length > 0 && (
            <p className="edit-alert">
              <FontAwesomeIcon icon={faTriangleExclamation} className="mr-2" /> {professionFormError.start_date}
            </p>
          )}
        </div>

        {(professionForm?.is_current_company === false || professionForm?.is_current_company === undefined) && (
          <div className="edit-form-group">
            <p className="edit-label">End Date</p>
            <DatePicker
              selected={professionForm?.end_date || ''}
              placeholderText="Select"
              onChange={(date) => {
                onProfessionFormChange({
                  target: { value: date, name: 'end_date' },
                });
              }}
              showMonthDropdown
              showYearDropdown
              className="edit-input"
            />
            {professionFormError.end_date && professionFormError.end_date.length > 0 && (
              <p className="edit-alert">
                <FontAwesomeIcon icon={faTriangleExclamation} className="mr-2" /> {professionFormError.end_date}
              </p>
            )}
          </div>
        )}

        <InputField
          label="Currently Working"
          type="checkbox"
          checked={professionForm.is_current_company}
          onChange={handleProfessionCheckBoxChange}
          containerClassName="edit-current-work"
          inputClassName="edit-current-input"
          labelClassName="edit-current-work-label"
        />
      </div>

      <div className="edit-buttons">
        <button className="button-save" onClick={() => onProfessionSave(true)}>
          Save
        </button>
        <button className="button-cancel" onClick={() => onProfessionSave(false)}>
          Cancel
        </button>
      </div>
    </div>
  );
}

export default ProfessionEditForm;
