import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';
import StarRatings from 'react-star-ratings';

const TestimonialSwiper = () => {
  const slidesData = [
    {
      image: '/assets/images/carousel-1.png',
      userImage: '/assets/images/random-user.jpg',
      review:
        "I've been using XYZ for over a year now and their customer service is excellent! Whenever I have a question, the team is always available and willing to help. Highly recommend!",
      name: 'Melissa Smith',
      title: 'Marketing Manager',
      rating: 5,
      totalStar: 5,
    },
    {
      image: '/assets/images/carousel-2.png',
      userImage: '/assets/images/random-user.jpg',
      review:
        "I've been using XYZ for over a year now and their customer service is excellent! Whenever I have a question, the team is always available and willing to help. Highly recommend!",
      name: 'John Doe',
      title: 'Product Manager',
      rating: 4.5,
      totalStar: 5,
    },
    {
      image: '/assets/images/carousel-3.png',
      userImage: '/assets/images/random-user.jpg',
      review:
        "I've been using XYZ for over a year now and their customer service is excellent! Whenever I have a question, the team is always available and willing to help. Highly recommend!",
      name: 'Jane Smith',
      title: 'Sales Executive',
      rating: 4,
      totalStar: 5,
    },
  ];

  return (
    <Swiper
      rewind={true}
      navigation={{
        nextEl: '.swiper-button-next-custom',
        prevEl: '.swiper-button-prev-custom',
      }}
      modules={[Navigation]}
      className="mySwiper"
    >
      {slidesData.map((slide, index) => (
        <SwiperSlide key={index}>
          <div className="slide-content">
            <img src={slide.image} alt="" className="image h-full" />
            <div className="overlay-content">
              <StarRatings
                rating={slide.rating}
                starRatedColor="white"
                starEmptyColor=""
                numberOfStars={slidesData.totalStar}
                name="rating"
                starDimension="20px"
                starSpacing="2px"
              />
              <p className="my-4 mb-0 text-base font-normal leading-relaxed tracking-wide text-gray-200 text-start">
                {slide.review}
              </p>
              <div className="mt-6 flex items-center gap-6 self-start">
                <div className="h-10 w-10 overflow-hidden rounded-full shadow-sm outline-neutral-800">
                  <div className="relative inline-block overflow-hidden rounded-lg border-neutral-800">
                    <img
                      alt=""
                      src={slide.userImage}
                      width="50"
                      height="50"
                      decoding="async"
                      data-nimg="1"
                      className="inline-block"
                      loading="lazy"
                      style={{ color: 'transparent' }}
                    />
                  </div>
                </div>
                <div>
                  <p className="leading-relaxed tracking-wide text-gray-200 text-start">{slide.name}</p>
                  <p className="text-xs leading-relaxed tracking-wide text-gray-400 text-start">{slide.title}</p>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
      <div className="swiper-button-next swiper-button-next-custom">
        <img src="/assets/images/right-arrow.svg" alt="Right arrow" />
      </div>
      <div className="swiper-button-prev swiper-button-prev-custom">
        <img src="/assets/images/left-arrow.svg" alt="Left arrow" />
      </div>
    </Swiper>
  );
};

export default TestimonialSwiper;
