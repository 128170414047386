import React from 'react';
import InputField from '../../../../_component/InputField';

function FineTunedResumeFormDetails({ parsedResumeForm, onParseResumeChange }) {
  return (
    <div className="my-8 max-[768px]:my-4">
      <div className="grid grid-cols-2 gap-2">
        <InputField
          id="name"
          label="Name"
          type="text"
          name="name"
          placeholder="Enter name"
          defaultValue={parsedResumeForm?.name}
          onChange={onParseResumeChange}
          containerClassName="form-group flex flex-col"
          inputClassName="rounded-2xl border border-gray-800 px-3 py-2 text-sm"
          labelClassName="text-gray-800"
        />
        <InputField
          id="headline"
          label="Headline"
          type="text"
          name="headline"
          placeholder="Enter headline"
          defaultValue={parsedResumeForm?.headline}
          onChange={onParseResumeChange}
          containerClassName="form-group flex flex-col"
          inputClassName="rounded-2xl border border-gray-800 px-3 py-2 text-sm"
          labelClassName="text-gray-700"
        />
        <InputField
          id="email"
          label="Email"
          type="text"
          name="email"
          placeholder="Enter email"
          defaultValue={parsedResumeForm?.email}
          onChange={onParseResumeChange}
          containerClassName="form-group  flex flex-col"
          inputClassName="rounded-2xl border border-gray-800 px-3 py-2 text-sm"
          labelClassName="text-gray-700"
        />
        <InputField
          id="phone"
          label="Phone"
          type="text"
          name="phone"
          placeholder="Enter phone"
          defaultValue={parsedResumeForm?.phone}
          onChange={onParseResumeChange}
          containerClassName="form-group  flex flex-col"
          inputClassName="rounded-2xl border border-gray-800 px-3 py-2 text-sm"
          labelClassName="text-gray-700"
        />
        <InputField
          id="linkedin"
          label="Linkedin"
          type="text"
          name="linkedin"
          placeholder="Enter linkedin"
          defaultValue={parsedResumeForm?.linkedin}
          onChange={onParseResumeChange}
          containerClassName="form-group flex flex-col"
          inputClassName="rounded-2xl border border-gray-800 px-3 py-2 text-sm"
          labelClassName="text-gray-700"
        />

        <InputField
          id="overview"
          label="Summary"
          inputType="textarea"
          name="overview"
          placeholder="Enter description"
          defaultValue={parsedResumeForm?.overview}
          onChange={onParseResumeChange}
          containerClassName="form-group  flex flex-col"
          inputClassName="rounded-2xl border border-gray-800 px-3 py-2 text-sm"
          labelClassName="text-gray-700"
          rows={3}
        />
      </div>
    </div>
  );
}

export default FineTunedResumeFormDetails;
