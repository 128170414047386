/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassHalf, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import StarRatings from 'react-star-ratings';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import HeadlineSearchBasic from '../../../../_component/HeadlineSearch';
import PronounSearchBasic from '../../../../_component/PronounSearch';
import SkillExperienceBasic from '../../../../_component/SkillExperience';
import SkillSearchBasic from '../../../../_component/SkillSearch';
import LocationBasic from '../../../../_component/LocationBasic';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import VeteranBasic from '../../../../_component/Veteran';
import DifferentlyAbledBasic from '../../../../_component/DifferentlyAbled';
import EthnicityBasic from '../../../../_component/Ethnicity';
import AuthorizedToWork from '../../../../_component/AuthorizedToWork';
import IdentifyLGBTQ from '../../../../_component/IdentifyLGBTQ';
import InputField from '../../../../_component/InputField';

export default ({
  user,
  loading,
  imageUploadLoading,
  onUserMetaEdit,
  onProfileEdit,
  userSkills,
  onSkillCreate,
  onSkillUpdate,
  onSkillDeleteClick,
  onImageUpload,
  onVerifyEmailClick,
  fileImageInputRef,
  handleImageInputClick,
}) => {
  return (
    <div className="profile-container">
      <div className="text-start text-xl font-medium text-gray-950 mt-3">My Profile</div>
      <div className="profile-box ">
        <div className="profile-image ">
          <img
            src={user?.candidate?.profile_image}
            alt="candidate"
            className="rounded-full h-[100px] w-[100px] object-cover"
            onClick={handleImageInputClick}
          />
          <div
            className="absolute bottom-0 right-0 bg-black/50 p-1.5 px-3  rounded-full cursor-pointer"
            onClick={handleImageInputClick}
          >
            {!imageUploadLoading ? (
              <FontAwesomeIcon icon={faPencil} className="text-white" />
            ) : (
              <FontAwesomeIcon icon={faHourglassHalf} className="text-white" />
            )}
          </div>
          <input
            type="file"
            name="profile_image"
            accept="image/*"
            onChange={onImageUpload}
            ref={fileImageInputRef}
            className="hidden"
          />
        </div>
        <div className="user-info">
          <h2 className="user-name">
            {user?.candidate?.first_name} {user?.candidate?.last_name}
          </h2>
          <p className="user-job-handline">{user?.job_headline && user.job_headline.display_name}</p>
          <span className="text-xs font-medium text-gray-400">
            {user?.region && user.region?.display_name}, {user?.country && user.country?.display_name}
          </span>
        </div>
      </div>
      <div className="personal-information-box profile-box">
        <div className="personal-information-display ">
          <h3 className="personal-information-display-title">Personal Information</h3>
          <div className="personal-information-details-box">
            <div className="personal-information-details-columns">
              <InputField
                label="First Name"
                type="text"
                defaultValue={user?.candidate?.first_name}
                name="first_name"
                onBlur={onUserMetaEdit}
                containerClassName="info-form-group"
                inputClassName="input-control"
                labelClassName="info-label"
              />
              <InputField
                label="Last Name"
                type="text"
                defaultValue={user?.candidate?.last_name}
                name="last_name"
                onBlur={onUserMetaEdit}
                containerClassName="info-form-group"
                inputClassName="input-control"
                labelClassName="info-label"
              />
              <div className="info-form-group">
                <label className="info-label">
                  Email Address{' '}
                  {user?.candidate && user.candidate?.email_verified ? (
                    <span className="ml-2 text-green-600 font-medium">(Verified)</span>
                  ) : (
                    <button onClick={onVerifyEmailClick} className="ml-2 text-red-600 cursor-pointer">
                      (Verify Email)
                    </button>
                  )}
                </label>
                <InputField
                  type="email"
                  defaultValue={user?.candidate && user.candidate?.email}
                  inputClassName="input-control email"
                  disabled={true}
                />
              </div>
              <div className="info-form-group">
                <label className="info-label ">
                  <span className="text-red-600">* </span>Phone Number
                </label>
                <PhoneInput
                  placeholder="Enter Phone number"
                  value={user?.candidate && user.candidate?.phone_number}
                  onCountryChange={() => console.log('')}
                  onBlur={(e) =>
                    onUserMetaEdit({
                      target: {
                        value: e.target.value,
                        name: 'phone_number',
                      },
                    })
                  }
                  containerClass="!w-2/3 max-[640px]:!w-full"
                  inputStyle={{ width: '100%' }}
                />
              </div>
              <div className="info-form-group">
                <label className="info-label">
                  <span className="text-red-600">* </span>Date of Birth
                </label>
                <div>
                  <DatePicker
                    selected={user?.dob ? user.dob : ''}
                    placeholderText="Select DOB"
                    onChange={(date) => {
                      onProfileEdit({
                        target: { value: date, name: 'dob' },
                      });
                    }}
                    showMonthDropdown
                    showYearDropdown
                    className="input-control "
                  />
                </div>
              </div>
              <div className="info-form-group">
                <label className="info-label">
                  <span className="text-red-600">* </span>Headline/Title
                </label>
                <HeadlineSearchBasic
                  defaultValue={user?.job_headline && user.job_headline.display_name}
                  onSearchChange={(e) =>
                    onProfileEdit({
                      target: { name: 'job_headline', value: e },
                    })
                  }
                  className="input-control"
                />
              </div>

              <div className="info-form-group">
                <label className="info-label">
                  <span className="text-red-600">* </span>Gender
                </label>
                <PronounSearchBasic
                  defaultValue={user?.pronoun && user?.pronoun?.display_name}
                  onSearchChange={(e) =>
                    onProfileEdit({
                      target: { name: 'pronoun', value: e },
                    })
                  }
                  containerClass="w-full input-control"
                />
              </div>
              <LocationBasic
                country_name={user?.country && user.country?.display_name}
                region_name={user?.region && user.region?.display_name}
                city_name={user?.city && user.city?.display_name}
                country_value={user?.country && user.country?._id}
                region_value={user?.region && user.region?._id}
                onCountryChange={(e) =>
                  onProfileEdit({
                    target: { name: 'country', value: e },
                  })
                }
                onRegionChange={(e) =>
                  onProfileEdit({
                    target: { name: 'region', value: e },
                  })
                }
                onCityChange={(e) =>
                  onProfileEdit({
                    target: { name: 'city', value: e },
                  })
                }
                className="grid-cols-3 max-[540px]:grid-cols-2 max-[320px]:grid-cols-1 w-full gap-4"
              />
            </div>
          </div>
        </div>
        {/* <button className="edit-btn">
          Edit <FontAwesomeIcon icon={faPencil} />
        </button> */}
      </div>
      <div className="social-information-box profile-box">
        <div className="social-information-display">
          <h3 className="social-information-display-title">Personal Links</h3>

          <div className="social-information-details-box">
            <div className="social-information-details-columns">
              <InputField
                label="Portfolio URL"
                type="text"
                placeholder="Enter Website URL"
                name="portfolio"
                defaultValue={user?.portfolio}
                onBlur={onProfileEdit}
                containerClassName="info-form-group"
                inputClassName="input-control"
                labelClassName="info-label"
              />
              <InputField
                label="LinkedIn URL"
                type="email"
                placeholder="Enter LinkedIn URL"
                name="linkedin"
                defaultValue={user?.linkedin}
                onBlur={onProfileEdit}
                containerClassName="info-form-group"
                inputClassName="input-control"
                labelClassName="info-label"
              />
              <InputField
                label="GitHub URL"
                type="text"
                placeholder="Enter GitHub URL"
                name="github"
                defaultValue={user?.github}
                onBlur={onProfileEdit}
                containerClassName="info-form-group"
                inputClassName="input-control"
                labelClassName="info-label"
              />
              <InputField
                label="Fiverr URL"
                type="text"
                placeholder="Enter Fiverr URL"
                name="fiverr"
                defaultValue={user?.fiverr}
                onBlur={onProfileEdit}
                containerClassName="info-form-group"
                inputClassName="input-control"
                labelClassName="info-label"
              />
            </div>
          </div>
        </div>
        {/* <button className="edit-btn">
          Edit <FontAwesomeIcon icon={faPencil} />
        </button> */}
      </div>
      <div className="social-information-box profile-box">
        <div className="social-information-display">
          <h3 className="social-information-display-title">Job Preference</h3>
          <div className="social-information-details-box">
            <div className="info-form-group ">
              <label className="info-label">Choose Your Ethnicity</label>
              <EthnicityBasic
                onEthnicityChange={(v) =>
                  onProfileEdit({
                    target: { name: 'ethnicity', value: v },
                  })
                }
                selectedValues={user?.ethnicity}
              />
            </div>
            <div className="social-information-details-columns">
              <div className="info-form-group">
                <label className="info-label">Military Veteran</label>
                <VeteranBasic
                  onVeteranChange={(v) =>
                    onProfileEdit({
                      target: { name: 'veteran_status', value: v },
                    })
                  }
                  selectedValue={user?.veteran_status}
                />
              </div>
              <div className="info-form-group">
                <label className="info-label">Differently Abled</label>
                <DifferentlyAbledBasic
                  onDifferentlyAbledChange={(v) =>
                    onProfileEdit({
                      target: { name: 'differently_abled', value: v },
                    })
                  }
                  selectedValue={user?.differently_abled}
                />
              </div>
              <AuthorizedToWork
                onAuthorizationChange={(key, value) =>
                  onProfileEdit({
                    target: { name: key, value },
                  })
                }
                selectedValue={{
                  us_work_authorized: user?.us_work_authorized,
                  uk_work_authorized: user?.uk_work_authorized,
                  ca_work_authorized: user?.ca_work_authorized,
                }}
              />
              <div className="info-form-group">
                <label className="info-label">Do you identify as LGBTQ+?</label>
                <IdentifyLGBTQ
                  onLGBTQChange={(v) =>
                    onProfileEdit({
                      target: { name: 'lgbtq', value: v },
                    })
                  }
                  selectedValue={user?.lgbtq}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <button className="edit-btn">
          Edit <FontAwesomeIcon icon={faPencil} />
        </button> */}
      </div>
      <div className="skills-information-box profile-box">
        <div className="skills-information-display">
          <h3 className="skills-information-display-title">Candidate Skills</h3>
          <div className="skills-information-details-box">
            <div className="skills-information-details-columns">
              <div className="info-form-group">
                <label className="info-label">
                  <span className="text-red-500">* </span>Search Skill
                </label>
                <SkillSearchBasic defaultValue={''} onSearchChange={(e) => onSkillCreate(e)} />
              </div>
              {Array.isArray(userSkills) && userSkills.length > 0 ? (
                <div className="skills-rating-container">
                  <table className="table-box">
                    <thead>
                      <tr className="table-row">
                        <th className="name-head">Name</th>
                        <th className="rating-head">Rating</th>
                        <th className="experience-head">Experience</th>
                        <th className="delete-head">Del</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userSkills.map((item, index) => (
                        <tr key={index} className="table-row table-data-row">
                          <td className="name-head">
                            <span>{item?.skill && item.skill?.display_name}</span>
                          </td>
                          <td className="rating-head">
                            <StarRatings
                              rating={item?.rating}
                              starRatedColor="#FFCC2A"
                              changeRating={(e) => onSkillUpdate(e, 'rating', item)}
                              numberOfStars={5}
                              name="rating"
                              starDimension="20px"
                              starHoverColor="#FCDDB0"
                            />
                          </td>
                          <td className="experience-head">
                            <SkillExperienceBasic
                              onSkillExperienceChange={(expValue) =>
                                onSkillUpdate(expValue, 'year_of_experience', item)
                              }
                              selectedValue={item?.year_of_experience}
                              selectedKey={'year_of_experience'}
                            />
                          </td>
                          <td className="w-1/12 delete-head">
                            <FontAwesomeIcon
                              icon={faTrash}
                              onClick={() => onSkillDeleteClick(item)}
                              className="cursor-pointer"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
