import React from 'react';

const contactStatus = {
  getInTouch: 'GET_IN_TOUCH',
  scheduled: 'MEETING_SCHEDULED',
  followup: 'FOLLOW_UP',
};

function ContactStatus({ selectedValue, onContactStatusChange }) {
  return (
    <div className="select mt-1">
      <p className="mb-1">Contact Status</p>
      <select
        className="select-control bg-transparent"
        onChange={(e) =>
          onContactStatusChange({
            target: { name: 'contact_status', value: e.target.value },
          })
        }
        name={'contact_status'}
      >
        <option disabled={true} value="" selected={selectedValue === ''}>
          Select Status
        </option>
        {Object.keys(contactStatus).map((item) => (
          <option
            key={contactStatus[item]}
            selected={selectedValue === contactStatus[item]}
            // defaultValue={contactStatus[item]}
            value={contactStatus[item]}
          >
            {contactStatus[item]
              .split('_')
              .join(' ')
              .toLowerCase()
              .replace(/^\w/, (c) => c.toUpperCase())}
          </option>
        ))}
      </select>
    </div>
  );
}

export default ContactStatus;
