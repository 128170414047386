import React from 'react';
import CollegeSearchBasic from '../../../../_component/College';
import CourseSearchBasic from '../../../../_component/Course';
import CourseTypeSearchBasic from '../../../../_component/CourseTypeSearch';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputField from '../../../../_component/InputField';

import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

function EducationEditForm({ educationForm, onEducationFormChange, onEducationSave, educationFormError }) {
  return (
    <div className="experience-edit-box">
      <div className="edit-container">
        <div className="edit-form-group">
          <label className="edit-label">
            <span className="text-red-600">*</span> College Name
          </label>
          <CollegeSearchBasic
            defaultValue={educationForm?.college && educationForm.college?.college_name}
            onSearchChange={(e) =>
              onEducationFormChange({
                target: { name: 'college', value: e },
              })
            }
          />
          {educationFormError.college && educationFormError.college.length > 0 && (
            <p className="edit-alert-select">
              <FontAwesomeIcon icon={faCircleExclamation} /> {educationFormError.college}
            </p>
          )}
        </div>

        <div className="edit-form-group">
          <label className="edit-label">Course</label>
          <CourseSearchBasic
            defaultValue={educationForm?.major && educationForm.major?.display_name}
            onSearchChange={(e) =>
              onEducationFormChange({
                target: { name: 'major', value: e },
              })
            }
          />
        </div>

        <div className="edit-form-group">
          <label className="edit-label">Course Type</label>
          <CourseTypeSearchBasic
            defaultValue={educationForm?.course_type && educationForm.course_type?.display_name}
            onSearchChange={(e) =>
              onEducationFormChange({
                target: { name: 'course_type', value: e },
              })
            }
          />
        </div>

        <InputField
          label="GPA"
          type="tel"
          placeholder="Enter GPA"
          name="gpa"
          defaultValue={educationForm?.gpa}
          onBlur={onEducationFormChange}
          containerClassName="edit-form-group"
          inputClassName="edit-input"
          labelClassName="edit-label"
        />

        <div className="edit-form-group edit-form-group-grad">
          <label className="edit-label">Graduation Date</label>
          <DatePicker
            selected={educationForm?.end_date ? educationForm.end_date : ''}
            placeholderText="Select"
            onChange={(date) => {
              onEducationFormChange({
                target: { value: date, name: 'end_date' },
              });
            }}
            showMonthDropdown
            showYearDropdown
            className="edit-input"
          />
        </div>
      </div>

      <div className="edit-buttons">
        <button className="button-save" onClick={() => onEducationSave(true)}>
          Save
        </button>
        <button className="button-cancel" onClick={() => onEducationSave(false)}>
          Cancel
        </button>
      </div>
    </div>
  );
}

export default EducationEditForm;
