import React from 'react';

function SkeletonLoader() {
  return (
    <div className="flex items-center justify-center max-h-screen h-screen w-full py-10 bg-[#f4f1f4]">
      <div className="animate-pulse max-w-lg w-[500px] max-h-full h-svh bg-[#f8f5f4] p-10 shadow-2xl shadow-zinc-500 ">
        <div className="flex space-x-4">
          <div className="flex-1 space-y-6 py-1">
            <div className="space-y-2 w-1/3">
              <p className="h-2 bg-slate-700 rounded"></p>
              <p className="h-2 bg-slate-700 rounded"></p>
              <p className="h-2 bg-slate-700 rounded"></p>
            </div>
            <div className="space-y-2">
              <h2 className="h-3 bg-slate-700 w-1/2"></h2>
              <p className="h-2 bg-slate-700 w-1/3"></p>
              <div className="h-2 bg-slate-700 rounded "></div>
              <div className="h-2 bg-slate-700 rounded mr-4"></div>
            </div>
            <div className="space-y-1">
              <h2 className="h-3 bg-slate-700 w-1/2"></h2>
              <p className="h-2 bg-slate-700 w-1/3"></p>
              <div className="ml-4 flex items-center">
                <span className="h-2 bg-slate-700 rounded-full w-2 mr-0.5 inline-block"></span>
                <p className="h-2 bg-slate-700 inline-block w-[63%] rounded"></p>
              </div>
              <div className="ml-4 flex items-center">
                <span className="h-2 bg-slate-700 rounded-full w-2 mr-0.5 inline-block"></span>
                <p className="h-2 bg-slate-700 inline-block w-[70%] rounded"></p>
              </div>
            </div>
            <div className="space-y-2">
              <h2 className="h-3 bg-slate-700 w-1/2"></h2>
              <p className="h-2 bg-slate-700 w-1/3"></p>
              <div className="ml-4 flex items-center">
                <span className="h-2 bg-slate-700 rounded-full w-2 mr-0.5 "></span>
                <p className="h-2 bg-slate-700 w-[63%] rounded"></p>
              </div>
              <div className="ml-4 flex items-center">
                <span className="h-2 bg-slate-700 rounded-full w-2 mr-0.5 "></span>
                <p className="h-2 bg-slate-700 w-[70%] rounded"></p>
              </div>
              <div className="ml-4 flex items-center">
                <p className=" ml-2.5 rounded w-1/3 h-2 bg-slate-700"></p>
              </div>
              <h2 className="h-3 bg-slate-700 w-1/2"></h2>
            </div>
            <div className="grid grid-cols-2">
              <div className="space-y-1.5">
                <h2 className="h-3 bg-slate-700 w-1/2"></h2>
                <p className="h-2 bg-slate-700 w-1/2 rounded"></p>
                <div className="ml-2.5 flex items-center">
                  <span className="h-2 bg-slate-700 rounded-full w-2 mr-0.5"></span>
                  <p className="h-2 bg-slate-700  w-2/3 rounded"></p>
                </div>
                <p className="h-2.5 bg-slate-700  w-1/2 "></p>
                <div className="ml-2.5 flex items-center">
                  <span className="h-2 bg-slate-700 rounded-full w-2 mr-0.5 "></span>
                  <p className="h-2 bg-slate-700 w-1/2 rounded"></p>
                </div>
                <p className=" h-2 bg-slate-700 w-1/2 "></p>
                <p className=" h-2 bg-slate-700  w-3/4 "></p>
              </div>

              <div className="space-y-1.5 w-1/2">
                <h2 className="h-3 bg-slate-700 w-[65%]"></h2>
                <p className="h-2 bg-slate-700 w-[85%] rounded"></p>
                <p className="h-2 bg-slate-700  w-[70%] rounded"></p>
                <p className=" h-2 bg-slate-700  w-1/2 "></p>
                <p className="h-2.5 bg-slate-700  w-1/2 "></p>

                <p className=" h-2 bg-slate-700  w-3/4 "></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkeletonLoader;
