/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import useForgotPassword from './useForgotPassword';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserClock } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../../_component/InputField';
import TestimonialSwiper from '../../../_component/TestimonialSwiper';
import { Spin } from 'antd';

export default (props) => {
  const {
    loading,
    formInputsError,

    onInputChange,
    onSubmitClick,
    onSignUpClick,
    checkFieldError,
  } = useForgotPassword(props);

  return (
    <div className="auth-box centered ">
      <div className="centered auth-box-left">
        <div className="auth-left-logo-box">
          <span className="auth-left-logo-icon">
            <FontAwesomeIcon icon={faUserClock} />
          </span>
          <span className="auth-left-logo-text">Interview&</span>
        </div>
        <form className="centered auth-left-form" onSubmit={onSubmitClick}>
          <div className="auth-form-greet">
            <h1 className="auth-form-greet-welcome">Welcome to Ai Clues</h1>
            <p className="auth-form-detail">Enter your email to reset password.</p>
          </div>
          <InputField
            id="email"
            type="text"
            placeholder="Email"
            onChange={(e) => onInputChange(e.target.value, 'email')}
            onBlur={(e) => checkFieldError(e.target.value, 'email')}
            error={formInputsError.email}
            formSubmitError={formInputsError.formSubmitError}
            containerClassName="containerClass"
            inputClassName="inputClass"
            iconClassName="iconClass"
            errorClassName="errorClass"
            fontIconClassName="fontIconClass"
          />
          <button className="auth-form-submit-btn mt-4" type="submit" value="Submit">
            {loading ? (
              <div className="w-full centered justify-center">
                <Spin size="small" />
              </div>
            ) : (
              'Submit'
            )}
          </button>

          <p className="auth-form-account">
            Don't have an account?{' '}
            <button type="button" onClick={onSignUpClick} className="auth-form-account-btn">
              Sign up
            </button>
          </p>
        </form>
      </div>
      <div className="auth-box-carousel centered">
        <TestimonialSwiper />
      </div>
    </div>
  );
};
