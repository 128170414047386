import React from 'react';

function FineTunedResumeComponent({ parsedResumeForm, fineTunedResumeComponentRef }) {
  return (
    <div
      className="bg-white p-6 max-w-md w-full h-full overflow-y-auto flex flex-col gap-1"
      ref={fineTunedResumeComponentRef}
    >
      <div className="flex flex-col ">
        <h2 className="text-center font-medium ">{parsedResumeForm?.name}</h2>
        <h4 className="text-center text-sm font-medium">{parsedResumeForm?.headline}</h4>
        <p className="text-center text-[10px] mb-0.5">
          <a href={`mailto:${parsedResumeForm?.email}`} className="underline">
            {parsedResumeForm?.email}
          </a>{' '}
          |{' '}
          <a href={parsedResumeForm?.linkedin} target="_blank" rel="noopener noreferrer" className="underline">
            {parsedResumeForm?.linkedin}
          </a>{' '}
          |{' '}
          <a href={`tel:${parsedResumeForm?.phone}`} className="underline">
            {parsedResumeForm?.phone}
          </a>
        </p>
        <hr />
        <p className="text-[11px] my-1 ">{parsedResumeForm?.overview}</p>
      </div>
      <hr />
      <div className="flex flex-col">
        <h3 className="text-xs font-medium underline mt-0.5">Experience</h3>
        {Array.isArray(parsedResumeForm?.experiences) &&
          parsedResumeForm.experiences.map((exp, index) => (
            <div key={index + 1}>
              <div className="flex justify-between items-center">
                <span className="leading-3">
                  <span className="text-xs font-medium leading-tight">{exp?.job_title}</span>{' '}
                  <span className="text-[10px]">
                    - {exp?.company_name}, {exp?.location}
                  </span>
                </span>
                <span className="text-[11px]">
                  {exp?.start_date} - {exp?.end_date}
                </span>
              </div>
              <ul className="mb-1 list-disc list-inside leading-3">
                {Array.isArray(exp?.description) &&
                  exp.description.map((dsc, index) => (
                    <li key={index + 1} className="text-[11px] leading-tight">
                      {dsc}
                    </li>
                  ))}
              </ul>
            </div>
          ))}
      </div>
      <hr />
      <div className="flex flex-col">
        <h3 className="text-xs font-medium underline mt-0.5">Education</h3>
        {Array.isArray(parsedResumeForm?.educations) &&
          parsedResumeForm.educations.map((edu, index) => (
            <div key={index + 1} className="mb-2 leading-3">
              <div className="flex justify-between items-center">
                <span className="leading-[0.625rem]">
                  <span className="text-xs leading-none font-medium ">{edu?.degree_name}</span>{' '}
                </span>
                <span className="text-[10px] text-right">
                  {edu?.start_date} - {edu?.end_date}
                </span>
              </div>
              <span className="text-[11px]">{edu?.college_name}</span>
              <ul className="mb-1">
                {Array.isArray(edu?.description) && edu.description.map((dsc) => <li className="text-xs">- {dsc}</li>)}
              </ul>
            </div>
          ))}
      </div>
      <hr />
      <h3 className="text-xs font-medium mt-0.5 underline">Skills</h3>
      <ul className="list-disc list-inside  grid grid-cols-3 leading-tight">
        {Array.isArray(parsedResumeForm?.skills) &&
          parsedResumeForm.skills.map((ski, index) => (
            <li key={index} className="text-[10px] font-medium">
              {ski}
            </li>
          ))}
      </ul>
    </div>
  );
}

export default FineTunedResumeComponent;
