import { userEducationConstants } from '../../constant';

const initialState = { educations: null };

export const fetch_user_educations_reducer = (state = initialState, action) => {
  switch (action.type) {
    case userEducationConstants.FETCH_USER_EDUCATIONS_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case userEducationConstants.FETCH_USER_EDUCATIONS_ACTION_SUCCESS:
      return {
        educations: action.payload,
      };
    case userEducationConstants.FETCH_USER_EDUCATIONS_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};
