export const jobApplyStatus = {
  bookmarked: 'BOOKMARKED',
  applied: 'APPLIED',
  interview: 'INTERVIEW',
  offered: 'OFFERED',
  hired: 'HIRED',
};

export const questionStatus = {
  already_sent: 'ALREADYSENT',
  evaluated: 'EVALUATED',
  answered: 'ANSWERED',
  assigned: 'ASSIGNED',
};

export const autoApplyStatus = {
  marked: 'AUTOAPPLYMARKED',
  applied: 'AUTOAPPLIED',
};

export const aiPlatformTypes = {
  openai: 'OPENAI',
  claudeai: 'CLOUDEAI',
};

export const socketIoEvents = {
  autoApply: 'AUTOAPPLY',
  fineTuneResume: 'RESUMEFINETUNE',
  mockInterviewQuestionRating: 'MOCKINTERVIEWQUESTIONRATING',
  mockInterviewGenerate: 'MOCKINTERVIEWGENERATE',
  emailNotification: 'EMAILNOTIFICATION',
  joinRoom: 'JOIN_ROOM',
  userDisconnected: 'USER_DISCONNECTED',
  disconnect: 'disconnect',
};

export const queueStatusTypes = {
  started: 'STARTED',
  completed: 'COMPLETED',
  resolved: 'RESOLVED',
  sent: 'SENT',
};
