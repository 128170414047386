/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetch_regions_action } from '../_redux/action/_master';

const RegionSearchBasic = ({ defaultValue, onSearchChange, country_value }) => {
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  const { regions } = useSelector((state) => state.fetch_regions_reducer);

  const ref = useRef();
  useEffect(() => {
    if (ref?.current && ref?.current?.value) {
      ref.current.value = defaultValue;
    }
  }, []);

  useEffect(() => {
    const callApi = async () => {
      if (country_value) await dispatch(fetch_regions_action(country_value));
    };
    callApi();
  }, [country_value]);

  useEffect(() => {
    if (regions?.regions) {
      setOptions(regions.regions);
    }
  }, [regions]);

  return (
    <Select
      ref={ref}
      showSearch
      value={defaultValue}
      className="px-0 relative w-full"
      placeholder={'Search Region/State'}
      allowClear={true}
      options={(options || []).map((d) => ({
        value: d?._id,
        label: `${d.display_name}`,
      }))}
      getPopupContainer={(trigger) => trigger.parentNode}
      filterOption={(input, option) => (option.label.toLowerCase() ?? '').includes(input.toLowerCase())}
      onSelect={onSearchChange}
    />
  );
};
export default RegionSearchBasic;
