import React from 'react';
// import { Col } from 'react-bootstrap';
import CountrySearchBasic from './Country';
import RegionSearchBasic from './Region';
import CitySearchBasic from './City';

function LocationBasic({
  country_name,
  region_name,
  city_name,
  country_value,
  region_value,
  onCountryChange,
  onRegionChange,
  onCityChange,
  className,
}) {
  return (
    <div className={`grid ${className}`}>
      <div className="info-form-group">
        <p className="info-label">Country</p>
        <CountrySearchBasic defaultValue={country_name} onSearchChange={onCountryChange} />
      </div>
      <div className="info-form-group">
        <p className="info-label">State</p>
        <RegionSearchBasic defaultValue={region_name} onSearchChange={onRegionChange} country_value={country_value} />
      </div>
      <div className="info-form-group">
        <p className="info-label">City</p>
        <CitySearchBasic defaultValue={city_name} onSearchChange={onCityChange} region_value={region_value} />
      </div>
    </div>
  );
}

export default LocationBasic;
