/* eslint-disable import/no-anonymous-default-export */
import './MainLayout.scss';
import Sidebar from '../Sidebar';
export default ({ activeMenuItem, children }) => {
  return (
    <div className="main-layout">
      <Sidebar />
      <div className="main-container">{children}</div>
    </div>
  );
};
