/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  create_user_skill_action,
  delete_user_skill_action,
  fetch_candidate_profile_action,
  fetch_user_skills_action,
  send_verification_code_action,
  update_candidate_profile_action,
  update_user_meta_action,
  update_user_skill_action,
  create_user_education_action,
  delete_user_education_action,
  fetch_user_educations_action,
  update_user_education_action,
  create_user_profession_action,
  delete_user_profession_action,
  fetch_user_professions_action,
  update_user_profession_action,
} from '../../../_redux/action';
import { educationFormatter, professionFormatter } from './_helpers/formFormatter';
import { navigateRoutes } from '../../../_config/route';
import { emptyStringCheck, urlCheck } from '../../../_helpers/formFieldErrorCheck';
import { showWarningMessage } from '../../../_component/MessageSnack';
import { errorMsg } from '../../../_helpers/errorConst';

function useEditCandidateProfile(props) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [userSkills, setUserSkills] = useState(null);

  const [userEducations, setUserEducations] = useState(null);
  const [educationForm, setEducationForm] = useState({});
  const [addNewEducation, setAddNewEducation] = useState(false);
  const [educationFormError, setEducationFormError] = useState({});

  const [userProfessions, setUserProfessions] = useState(null);
  const [professionForm, setProfessionForm] = useState({});
  const [addNewProfession, setAddNewProfession] = useState(false);
  const [professionFormError, setProfessionFormError] = useState({});

  const [imageUploadLoading, setImageUploadLoading] = useState(false);

  // Ref for file input element used to trigger the file selection dialog
  const fileImageInputRef = useRef(null);

  // Function to click the hidden file input when the user triggers the image upload
  const handleImageInputClick = () => {
    if (fileImageInputRef.current) {
      fileImageInputRef.current.click();
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.fetch_candidate_profile_reducer);
  const { skills } = useSelector((state) => state.fetch_user_skills_reducer);
  const { educations } = useSelector((state) => state.fetch_user_educations_reducer);
  const { professions } = useSelector((state) => state.fetch_user_professions_reducer);

  useEffect(() => {
    const callApi = async () => {
      setLoading(true);
      await dispatch(fetch_candidate_profile_action());
      await dispatch(fetch_user_skills_action());
      await dispatch(fetch_user_educations_action());
      await dispatch(fetch_user_professions_action());
      setLoading(false);
    };

    callApi();
  }, []);

  useEffect(() => {
    if (profile?.user_profile) {
      setUser(profile?.user_profile);
    }
  }, [profile]);
  useEffect(() => {
    if (skills?.skills) {
      setUserSkills(skills?.skills);
    }
  }, [skills]);
  useEffect(() => {
    if (educations?.educations) {
      setUserEducations(educations?.educations);
    }
  }, [educations]);
  useEffect(() => {
    if (professions?.experiences) {
      setUserProfessions(professions?.experiences);
    }
  }, [professions]);

  const updateUserMetaInfo = async (e) => {
    let data = { [e.target.name]: e.target.value };

    if (!emptyStringCheck(e.target.value)) {
      await dispatch(update_user_meta_action(data));
      await dispatch(fetch_candidate_profile_action());
    }
  };

  const onImageUpload = async (e) => {
    const file = e.target.files[0];
    const name = e.target.name;
    const data = new FormData();
    data.append(name, file);

    setImageUploadLoading(true);

    if (file) {
      await dispatch(update_user_meta_action(data));
      await dispatch(fetch_candidate_profile_action());
    }

    setImageUploadLoading(false);
  };

  const updateUserProfile = async (e) => {
    let data = { [e.target.name]: e.target.value };

    if (e.target.name === 'dob') {
      await dispatch(update_candidate_profile_action(data));
      return;
    } else if (
      e.target.name === 'linkedin' ||
      e.target.name === 'github' ||
      e.target.name === 'fiverr' ||
      e.target.name === 'portfolio'
    ) {
      if (urlCheck(e.target.value)) {
        await dispatch(update_candidate_profile_action(data));
      } else {
        showWarningMessage(errorMsg.urlCheck);
      }
    } else if (!emptyStringCheck(e.target.value)) {
      await dispatch(update_candidate_profile_action(data));
    } else {
      showWarningMessage(errorMsg.emptyField);
    }
  };

  const onSkillCreate = async (val) => {
    let data = { skill: val, year_of_experience: 1, rating: 0 };
    if (val) await dispatch(create_user_skill_action(data));
    await dispatch(fetch_user_skills_action());
  };
  const onSkillUpdate = async (val, name, item) => {
    let data = { [name]: val };
    if (val) await dispatch(update_user_skill_action(item?._id, data));
    await dispatch(fetch_user_skills_action());
  };
  const onSkillDeleteClick = async (item) => {
    if (item) await dispatch(delete_user_skill_action(item?._id));
    await dispatch(fetch_user_skills_action());
  };

  const onEducationEditClick = async (item) => {
    if (item) setEducationForm(item);
    else setEducationForm({});
    setAddNewEducation((val) => !val);
  };

  const onEducationSave = async (isSave) => {
    if (isSave) {
      let { form, formError } = educationFormatter(educationForm);

      if (Object.keys(formError).length) {
        setEducationFormError(formError);
        return;
      }

      if (educationForm?._id) {
        await dispatch(update_user_education_action(educationForm?._id, form));
      } else await dispatch(create_user_education_action(form));
      await dispatch(fetch_user_educations_action());
    }
    onNewEducationClick();
  };

  const onEducationDeleteClick = async (item) => {
    if (item) await dispatch(delete_user_education_action(item?._id));
    await dispatch(fetch_user_educations_action());
  };
  const onNewEducationClick = async () => {
    setAddNewEducation((val) => !val);
    setEducationForm({});
    setEducationFormError({});
  };
  const onEducationFormChange = async (e) => {
    setEducationForm({ ...educationForm, [e.target.name]: e.target.value });
  };

  const onProfessionEditClick = async (item) => {
    if (item) {
      setProfessionForm(item);
    } else setProfessionForm({});
    setAddNewProfession((val) => !val);
  };
  const onProfessionSave = async (isSave) => {
    if (isSave) {
      let { form, formError } = professionFormatter(professionForm);

      if (Object.keys(formError).length) {
        setProfessionFormError(formError);
        return;
      }

      if (professionForm?._id) {
        await dispatch(update_user_profession_action(professionForm?._id, form));
      } else await dispatch(create_user_profession_action(form));
      await dispatch(fetch_user_professions_action());
    }
    onNewProfessionClick();
  };
  const onProfessionDeleteClick = async (item) => {
    if (item) await dispatch(delete_user_profession_action(item?._id));
    await dispatch(fetch_user_professions_action());
  };
  const onNewProfessionClick = async () => {
    setAddNewProfession((val) => !val);
    setProfessionForm({});
    setProfessionFormError({});
  };
  const onProfessionFormChange = async (e) => {
    setProfessionForm({ ...professionForm, [e.target.name]: e.target.value });
  };

  const handleProfessionCheckBoxChange = async (e) => {
    setProfessionForm({
      ...professionForm,
      is_current_company: !professionForm.is_current_company,
    });
  };

  const onVerifyEmailClick = async (e) => {
    if (user?.candidate && !user.candidate?.email_verified) {
      const res = await dispatch(send_verification_code_action());

      if (res) {
        navigate(navigateRoutes.verifyEmail);
      }
    }
  };

  return {
    user,
    loading,
    userSkills,
    userEducations,
    educationForm,
    addNewEducation,
    addNewProfession,
    professionForm,
    userProfessions,
    imageUploadLoading,
    educationFormError,
    professionFormError,
    fileImageInputRef,

    updateUserMetaInfo,
    updateUserProfile,
    onSkillCreate,
    onSkillUpdate,
    onSkillDeleteClick,
    onEducationFormChange,
    onEducationDeleteClick,
    onEducationEditClick,
    onNewEducationClick,
    onEducationSave,
    onProfessionDeleteClick,
    onProfessionEditClick,
    onProfessionFormChange,
    onProfessionSave,
    onNewProfessionClick,
    handleProfessionCheckBoxChange,
    onImageUpload,
    onVerifyEmailClick,
    handleImageInputClick,
  };
}
export default useEditCandidateProfile;
