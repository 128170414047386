export const aiConstants = {
  CREATE_USER_FINE_TUNE_RESUME_ACTION_REQUEST: 'CREATE_USER_FINE_TUNE_RESUME_ACTION_REQUEST',
  CREATE_USER_FINE_TUNE_RESUME_ACTION_SUCCESS: 'CREATE_USER_FINE_TUNE_RESUME_ACTION_SUCCESS',
  CREATE_USER_FINE_TUNE_RESUME_ACTION_FAILURE: 'CREATE_USER_FINE_TUNE_RESUME_ACTION_FAILURE',

  FETCH_USER_FINE_TUNE_RESUMES_ACTION_REQUEST: 'FETCH_USER_FINE_TUNE_RESUMES_ACTION_REQUEST',
  FETCH_USER_FINE_TUNE_RESUMES_ACTION_SUCCESS: 'FETCH_USER_FINE_TUNE_RESUMES_ACTION_SUCCESS',
  FETCH_USER_FINE_TUNE_RESUMES_ACTION_FAILURE: 'FETCH_USER_FINE_TUNE_RESUMES_ACTION_FAILURE',

  FETCH_USER_FINE_TUNE_RESUME_ACTION_REQUEST: 'FETCH_USER_FINE_TUNE_RESUME_ACTION_REQUEST',
  FETCH_USER_FINE_TUNE_RESUME_ACTION_SUCCESS: 'FETCH_USER_FINE_TUNE_RESUME_ACTION_SUCCESS',
  FETCH_USER_FINE_TUNE_RESUME_ACTION_FAILURE: 'FETCH_USER_FINE_TUNE_RESUME_ACTION_FAILURE',

  UPDATE_USER_FINE_TUNE_RESUME_ACTION_REQUEST: 'UPDATE_USER_FINE_TUNE_RESUME_ACTION_REQUEST',
  UPDATE_USER_FINE_TUNE_RESUME_ACTION_SUCCESS: 'UPDATE_USER_FINE_TUNE_RESUME_ACTION_SUCCESS',
  UPDATE_USER_FINE_TUNE_RESUME_ACTION_FAILURE: 'UPDATE_USER_FINE_TUNE_RESUME_ACTION_FAILURE',

  REGENERATE_USER_FINE_TUNE_RESUME_ACTION_REQUEST: 'REGENERATE_USER_FINE_TUNE_RESUME_ACTION_REQUEST',
  REGENERATE_USER_FINE_TUNE_RESUME_ACTION_SUCCESS: 'REGENERATE_USER_FINE_TUNE_RESUME_ACTION_SUCCESS',
  REGENERATE_USER_FINE_TUNE_RESUME_ACTION_FAILURE: 'REGENERATE_USER_FINE_TUNE_RESUME_ACTION_FAILURE',

  CREATE_USER_MOCK_INTERVIEW_ACTION_REQUEST: 'CREATE_USER_MOCK_INTERVIEW_ACTION_REQUEST',
  CREATE_USER_MOCK_INTERVIEW_ACTION_SUCCESS: 'CREATE_USER_MOCK_INTERVIEW_ACTION_SUCCESS',
  CREATE_USER_MOCK_INTERVIEW_ACTION_FAILURE: 'CREATE_USER_MOCK_INTERVIEW_ACTION_FAILURE',

  FETCH_USER_MOCK_INTERVIEWS_ACTION_REQUEST: 'FETCH_USER_MOCK_INTERVIEWS_ACTION_REQUEST',
  FETCH_USER_MOCK_INTERVIEWS_ACTION_SUCCESS: 'FETCH_USER_MOCK_INTERVIEWS_ACTION_SUCCESS',
  FETCH_USER_MOCK_INTERVIEWS_ACTION_FAILURE: 'FETCH_USER_MOCK_INTERVIEWS_ACTION_FAILURE',

  FETCH_USER_MOCK_INTERVIEW_ACTION_REQUEST: 'FETCH_USER_MOCK_INTERVIEW_ACTION_REQUEST',
  FETCH_USER_MOCK_INTERVIEW_ACTION_SUCCESS: 'FETCH_USER_MOCK_INTERVIEW_ACTION_SUCCESS',
  FETCH_USER_MOCK_INTERVIEW_ACTION_FAILURE: 'FETCH_USER_MOCK_INTERVIEW_ACTION_FAILURE',

  UPDATE_USER_MOCK_INTERVIEW_ACTION_REQUEST: 'UPDATE_USER_MOCK_INTERVIEW_ACTION_REQUEST',
  UPDATE_USER_MOCK_INTERVIEW_ACTION_SUCCESS: 'UPDATE_USER_MOCK_INTERVIEW_ACTION_SUCCESS',
  UPDATE_USER_MOCK_INTERVIEW_ACTION_FAILURE: 'UPDATE_USER_MOCK_INTERVIEW_ACTION_FAILURE',

  FETCH_USER_MOCK_INTERVIEW_QUESTIONS_ACTION_REQUEST: 'FETCH_USER_MOCK_INTERVIEW_QUESTIONS_ACTION_REQUEST',
  FETCH_USER_MOCK_INTERVIEW_QUESTIONS_ACTION_SUCCESS: 'FETCH_USER_MOCK_INTERVIEW_QUESTIONS_ACTION_SUCCESS',
  FETCH_USER_MOCK_INTERVIEW_QUESTIONS_ACTION_FAILURE: 'FETCH_USER_MOCK_INTERVIEW_QUESTIONS_ACTION_FAILURE',

  FETCH_USER_MUX_UPLOAD_URL_ACTION_REQUEST: 'FETCH_USER_MUX_UPLOAD_URL_ACTION_REQUEST',
  FETCH_USER_MUX_UPLOAD_URL_ACTION_SUCCESS: 'FETCH_USER_MUX_UPLOAD_URL_ACTION_SUCCESS',
  FETCH_USER_MUX_UPLOAD_URL_ACTION_FAILURE: 'FETCH_USER_MUX_UPLOAD_URL_ACTION_FAILURE',

  FETCH_USER_MUX_UPLOAD_ASSET_IDS_ACTION_REQUEST: 'FETCH_USER_MUX_UPLOAD_ASSET_IDS_ACTION_REQUEST',
  FETCH_USER_MUX_UPLOAD_ASSET_IDS_ACTION_SUCCESS: 'FETCH_USER_MUX_UPLOAD_ASSET_IDS_ACTION_SUCCESS',
  FETCH_USER_MUX_UPLOAD_ASSET_IDS_ACTION_FAILURE: 'FETCH_USER_MUX_UPLOAD_ASSET_IDS_ACTION_FAILURE',

  FETCH_USER_MOCK_INTERVIEW_QUESTION_FEEDBACK_ACTION_REQUEST:
    'FETCH_USER_MOCK_INTERVIEW_QUESTION_FEEDBACK_ACTION_REQUEST',
  FETCH_USER_MOCK_INTERVIEW_QUESTION_FEEDBACK_ACTION_SUCCESS:
    'FETCH_USER_MOCK_INTERVIEW_QUESTION_FEEDBACK_ACTION_SUCCESS',
  FETCH_USER_MOCK_INTERVIEW_QUESTION_FEEDBACK_ACTION_FAILURE:
    'FETCH_USER_MOCK_INTERVIEW_QUESTION_FEEDBACK_ACTION_FAILURE',
};
