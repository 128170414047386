import React from 'react';
import MainLayout from '../../../_layout/MainLayout';
import { Container } from 'react-bootstrap';

function Dashboard() {
  return (
    <MainLayout activeMenuItem="home">
      <div className="top bg-grey pb-4">
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="text-dark">Dashboard</h2>
          </div>
        </Container>
      </div>
    </MainLayout>
  );
}

export default Dashboard;
