export const userJobBookmarkConstants = {
  FETCH_USER_JOB_BOOKMARKS_ACTION_REQUEST: 'FETCH_USER_JOB_BOOKMARKS_ACTION_REQUEST',
  FETCH_USER_JOB_BOOKMARKS_ACTION_SUCCESS: 'FETCH_USER_JOB_BOOKMARKS_ACTION_SUCCESS',
  FETCH_USER_JOB_BOOKMARKS_ACTION_FAILURE: 'FETCH_USER_JOB_BOOKMARKS_ACTION_FAILURE',

  FETCH_USER_JOB_DETAILS_BOOKMARK_ACTION_REQUEST: 'FETCH_USER_JOB_DETAILS_BOOKMARK_ACTION_REQUEST',
  FETCH_USER_JOB_DETAILS_BOOKMARK_ACTION_SUCCESS: 'FETCH_USER_JOB_DETAILS_BOOKMARK_ACTION_SUCCESS',
  FETCH_USER_JOB_DETAILS_BOOKMARK_ACTION_FAILURE: 'FETCH_USER_JOB_DETAILS_BOOKMARK_ACTION_FAILURE',

  UPDATE_STATUS_USER_BOOKMARK_JOB_ACTION_REQUEST: 'UPDATE_STATUS_USER_BOOKMARK_JOB_ACTION_REQUEST',
  UPDATE_STATUS_USER_BOOKMARK_JOB_ACTION_SUCCESS: 'UPDATE_STATUS_USER_BOOKMARK_JOB_ACTION_SUCCESS',
  UPDATE_STATUS_USER_BOOKMARK_JOB_ACTION_FAILURE: 'UPDATE_STATUS_USER_BOOKMARK_JOB_ACTION_FAILURE',

  MARK_AUTO_APPLY_USER_BOOKMARK_JOB_ACTION_REQUEST: 'MARK_AUTO_APPLY_USER_BOOKMARK_JOB_ACTION_REQUEST',
  MARK_AUTO_APPLY_USER_BOOKMARK_JOB_ACTION_SUCCESS: 'MARK_AUTO_APPLY_USER_BOOKMARK_JOB_ACTION_SUCCESS',
  MARK_AUTO_APPLY_USER_BOOKMARK_JOB_ACTION_FAILURE: 'MARK_AUTO_APPLY_USER_BOOKMARK_JOB_ACTION_FAILURE',
};
