import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const CandidateRoute = (props) => {
  const user = JSON.parse(localStorage.getItem('aiclues-user'));
  return user && user.user && user.user.role ? <Outlet /> : <Navigate to="/" />;
};

export const PrivateRoute = (props) => {
  const user = JSON.parse(localStorage.getItem('aiclues-user'));
  return user && user.token !== undefined ? <Navigate to="/" /> : <Outlet />;
};

export const PublicRoute = (props) => {
  const user = JSON.parse(localStorage.getItem('aiclues-user'));
  return user && user.token !== undefined ? <Navigate to="/" /> : <Outlet />;
};
