import React from 'react';

const experienceConst = [
  {
    key: '1',
    value: '1',
  },
  {
    key: '2',
    value: '2',
  },
  {
    key: '3',
    value: '3',
  },
  {
    key: '4',
    value: '4',
  },
  {
    key: '5',
    value: '5',
  },
  {
    key: '>5',
    value: '>5',
  },
];
function SkillExperienceBasic({ onSkillExperienceChange, selectedValue, selectedKey }) {
  return (
    <span className="select mt-1 flex items-center justify-center">
      <select
        className="font-semibold rounded p-2 border border-gray-600 bg-transparent"
        onChange={(e) => onSkillExperienceChange(e.target.value)}
        name={selectedKey}
      >
        <option disabled={true} value="" selected={selectedValue === null || selectedValue === ''}>
          Experience in Years
        </option>
        {experienceConst.map((item) => (
          <option key={item.key} selected={selectedValue === item.value} defaultValue={item.value}>
            {item.key}
          </option>
        ))}
      </select>
    </span>
  );
}

export default SkillExperienceBasic;
