/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetch_course_type_action } from '../_redux/action/_master';

const CourseTypeSearchBasic = ({ defaultValue, onSearchChange }) => {
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  const { courseTypes } = useSelector((state) => state.fetch_course_types_reducer);

  const ref = useRef();
  useEffect(() => {
    if (ref?.current && ref?.current?.value) {
      ref.current.value = defaultValue;
    }
  }, []);

  useEffect(() => {
    const callApi = async () => {
      await dispatch(fetch_course_type_action());
    };
    callApi();
  }, []);

  useEffect(() => {
    if (courseTypes?.courseTypes) {
      setOptions(courseTypes.courseTypes);
    }
  }, [courseTypes]);

  return (
    <Select
      ref={ref}
      showSearch
      value={defaultValue}
      className="relative w-full"
      placeholder={'Search Course Type'}
      allowClear={true}
      options={(options || []).map((d) => ({
        value: d?._id,
        label: `${d.display_name}`,
      }))}
      getPopupContainer={(trigger) => trigger.parentNode}
      filterOption={(input, option) => (option.label.toLowerCase() ?? '').includes(input.toLowerCase())}
      onSelect={onSearchChange}
    />
  );
};
export default CourseTypeSearchBasic;
