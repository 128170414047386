/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { reset_password_action } from '../../../_redux/action';
import { navigateRoutes } from '../../../_config/route';
import { passwordCheck } from '../../../_helpers/formFieldErrorCheck';
import { errorMsg } from '../../../_helpers/errorConst';

function useResetPassword(props) {
  //initial states
  const [password, setPassword] = useState();
  const [formInputsError, setFormInputsError] = useState({});
  const [loading, setloading] = useState(false);
  const [showPassword, setshowPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    if (!id) {
      navigate(navigateRoutes.signIn);
    }
  }, [id]);

  const onInputChange = (value) => {
    setPassword(value);
  };

  // submit form
  const onSubmitClick = async (e) => {
    e.preventDefault();
    setloading(true);
    if (passwordCheck(password)) {
      const loginData = await dispatch(reset_password_action(id, { password }));
      if (loginData) {
        navigate(navigateRoutes.signIn);
      }
    } else setFormInputsError({ password: errorMsg.password });

    setloading(false);
  };

  const onSignUpClick = () => {
    navigate(navigateRoutes.signUp);
  };

  const handleShowPassword = (key) => {
    setshowPassword(!showPassword);
  };

  const checkFieldError = (val) => {
    if (!passwordCheck(val)) setFormInputsError({ password: errorMsg.password });
  };

  return {
    formInputsError,
    loading,
    showPassword,

    onInputChange,
    onSubmitClick,
    onSignUpClick,
    handleShowPassword,
    checkFieldError,
  };
}

export default useResetPassword;
