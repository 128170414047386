import { errorMsg } from '../../../../_helpers/errorConst';
import { emptyStringCheck } from '../../../../_helpers/formFieldErrorCheck';

export const educationFormSchema = {
  end_date: null,
  gpa: null,
  course_type: null,
  major: null,
  college: null,
};

export const professionFormSchema = {
  start_date: null,
  end_date: null,
  company: null,
  headline: null,
};

export const educationFormatter = (form) => {
  let formError = {};
  for (let i in educationFormSchema) {
    if (form[i] === undefined || emptyStringCheck(form[i])) {
      formError[i] = errorMsg.emptyField;
    }
  }
  if (form?.college && form.college?._id) {
    form = {
      ...form,
      college: form?.college && form.college?._id,
    };
  }
  if (form?.major && form.major?._id) {
    form = {
      ...form,
      major: form?.major && form.major?._id,
    };
  }
  if (form?.course_type && form.course_type?._id) {
    form = {
      ...form,
      course_type: form?.course_type && form.course_type?._id,
    };
  }
  return { form, formError };
};

export const professionFormatter = (form) => {
  let formError = {};
  for (let i in professionFormSchema) {
    if (i === 'end_date') {
      if (form?.is_current_company === true) {
        break;
      } else if (form[i] === undefined || emptyStringCheck(form[i])) {
        formError[i] = errorMsg.emptyField;
      }
    }
    if (form[i] === undefined || emptyStringCheck(form[i])) {
      formError[i] = errorMsg.emptyField;
    }
  }
  if (form?.company && form.company?._id) {
    form = {
      ...form,
      company: form?.company && form.company?._id,
    };
  }

  return { form, formError };
};
