import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { navigateRoutes } from '../../_config/route';

function useHome() {
  const [isSignedIn, setIsSignedIn] = useState(null);
  const [candidateToggle, setCandidateToggle] = useState(true);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [isExploreDisabled, setIsExploreDisabled] = useState(true);

  const teamSkills = [
    { name: 'AI Engineer', icon: '🤖', isSelected: false },
    { name: 'Back End Developer', icon: '🗄️', isSelected: false },
    { name: 'Front End Developer', icon: '💻', isSelected: false },
    { name: 'Product Designer', icon: '🎨', isSelected: false },
    { name: 'Product Manager', icon: '📊', isSelected: false },
    { name: 'Data Scientist', icon: '📈', isSelected: false },
    { name: 'QA Engineer', icon: '🧪', isSelected: false },
    { name: 'App Developer', icon: '📱', isSelected: false },
    { name: 'Solutions Architect', icon: '🧩', isSelected: false },
    { name: 'Dev Ops Engineer', icon: '🔄', isSelected: false },
  ];

  const productSkills = [
    { name: 'Rapid Prototyping', icon: '⚡', isSelected: false },
    { name: 'Data Science Solutions', icon: '📊', isSelected: false },
    { name: 'End-to-end Development', icon: '🛠️', isSelected: false },
    { name: 'MVP', icon: '🚀', isSelected: false },
    { name: 'Wireframing + UX', icon: '🖥️', isSelected: false },
    { name: 'API Integration', icon: '🔗', isSelected: false },
    { name: 'Product Growth', icon: '📈', isSelected: false },
    { name: 'AI + Machine Learning', icon: '🤖', isSelected: false },
    { name: 'Re-platforming', icon: '🔄', isSelected: false },
  ];

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('aiclues-user'));

    if (user) {
      setIsSignedIn(true);
    }
  }, []);

  const navigate = useNavigate();

  const onSiginClick = () => {
    navigate(navigateRoutes.signIn);
  };

  const onDashboardClick = () => {
    navigate(navigateRoutes.candidateDashboard);
  };

  const handleCandidateToggle = (newToggleState) => {
    if (candidateToggle !== newToggleState) {
      setCandidateToggle(newToggleState);
    }
  };

  useEffect(() => {
    setSelectedSkills(candidateToggle ? teamSkills : productSkills);
  }, [candidateToggle]);

  const handleSkillClick = (skillName) => {
    setSelectedSkills((prevSkills) =>
      prevSkills.map((skill) => (skill.name === skillName ? { ...skill, isSelected: !skill.isSelected } : skill))
    );
  };

  useEffect(() => {
    setIsExploreDisabled(!selectedSkills.some((skill) => skill.isSelected));
  }, [selectedSkills]);

  return {
    isSignedIn,
    candidateToggle,

    onSiginClick,
    onDashboardClick,
    handleCandidateToggle,
    selectedSkills,
    handleSkillClick,
    isExploreDisabled,
  };
}

export default useHome;
