import React from 'react';
import MainLayout from '../../../_layout/MainLayout';
import useMockInterviews from './useMockInterviews';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button, Spin } from 'antd';

function MockInterviews(props) {
  const {
    loading,
    userMockInterviews,
    mockInterviewForm,
    mockInterviewModelOpen,
    userResumes,
    allJobs,

    onMockInterviewClick,
    onMockInterviewFormChange,
    onMockInterviewGenerate,
    handleMockInterviewModalOpen,
  } = useMockInterviews(props);
  return (
    <MainLayout activeMenuItem="home">
      <div className="p-4 sticky">
        <div className="centered justify-between mb-4 window-container">
          <h2 className="window-title">Mock Interviews</h2>
          <div className="centered justify-center">
            <button
              className="btn  bg-slate-50 text-gray-900 rounded-xl outline outline-1 hover:bg-slate-200"
              onClick={() => handleMockInterviewModalOpen({})}
            >
              Create Mock Interview
            </button>
          </div>
        </div>

        {loading ? (
          <div className="centered spinner-box">
            <Spin size="large" />
          </div>
        ) : (
          <div className="centered min-h-[90vh] flex-col gap-2 w-full">
            <div className="overflow-x-auto w-full flex-col mb-8">
              <table className="border-collapse w-full">
                <thead>
                  <tr className="w-full">
                    <th className="px-2 py-1">
                      <div className="text-center text-sm font-medium text-gray-400">#</div>
                    </th>
                    <th className=" px-2 py-1">
                      <div className="text-start text-sm font-medium text-gray-400">Name</div>
                    </th>
                    <th className=" px-2 py-1">
                      <div className="text-start text-sm font-medium text-gray-400">Company</div>
                    </th>
                    <th className=" px-2 py-1">
                      <div className="text-start text-sm font-medium text-gray-400">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(userMockInterviews) &&
                    userMockInterviews.map((item, index) => (
                      <>
                        <tr className="border border-gray-200 border-l-0 border-r-0">
                          <td className="px-2 py-1 border border-gray-200 border-l-0 text-center">{index + 1}</td>

                          <td className=" px-2 py-1 border border-gray-200  ">
                            <div className="mb-1 text-base font-medium text-blue-900">
                              {item?.job_title || item?.job?.title}
                            </div>
                          </td>

                          <td className=" px-2 py-1 border border-gray-200">
                            <div className="text-xs font-medium text-gray-600">{item?.job?.company}</div>
                          </td>
                          <td className=" px-2 py-1 border border-r-0 border-gray-200">
                            <div className="text-center cursor-pointer">
                              <FontAwesomeIcon
                                className="m-2"
                                icon={faEye}
                                onClick={() => onMockInterviewClick(item)}
                              />
                            </div>
                          </td>
                        </tr>
                      </>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      <Modal
        title="Generate Mock Interview by selecting job and resume"
        open={mockInterviewModelOpen}
        onCancel={() => handleMockInterviewModalOpen({})}
        footer={[
          <Button key="generate" type="primary" onClick={onMockInterviewGenerate}>
            Generate Mock Interview
          </Button>,
        ]}
      >
        <div className="mt-4 space-y-4">
          <div className="flex flex-col">
            <label className="font-medium text-gray-700">
              <span className="text-red-500">*</span> Job
            </label>
            <select
              className="mt-1 p-2 border rounded bg-transparent"
              onChange={onMockInterviewFormChange}
              name="job"
              value={mockInterviewForm?.job || ''}
            >
              <option disabled value="">
                Select Job
              </option>
              {Array.isArray(allJobs) &&
                allJobs.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.title} at {item.company}
                  </option>
                ))}
            </select>
          </div>

          <div className="flex flex-col">
            <label className="font-medium text-gray-700">
              <span className="text-red-500">*</span> Resume
            </label>
            <select
              className="mt-1 p-2 border rounded bg-transparent"
              onChange={onMockInterviewFormChange}
              name="resume"
              value={mockInterviewForm?.resume || ''}
            >
              <option disabled value="">
                Select Resume
              </option>
              {Array.isArray(userResumes) &&
                userResumes.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </Modal>
    </MainLayout>
  );
}

export default MockInterviews;
