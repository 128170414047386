import React from 'react';
import MainLayout from '../../../_layout/MainLayout';
import useSavedContacts from './useSavedContacts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDeleteLeft, faEdit, faEye, faSquareXmark } from '@fortawesome/free-solid-svg-icons';
import StarRatings from 'react-star-ratings';
import ContactStatus from '../../../_component/ContactStatus';
import ContactRelationship from '../../../_component/ContactRelationship';
import { Modal, Button, Spin } from 'antd';
import InputField from '../../../_component/InputField';

function SavedContacts(props) {
  const {
    loading,
    allContacts,
    contactForm,
    contactModalOpen,
    deleteContact,
    deleteModalOpen,
    formErrors,

    onDeleteContactConfirm,
    onInputChange,
    onContactSave,
    onContactDelete,
    handleContactModalOpen,
    onContactViewClick,
    onDeleteModelClose,
  } = useSavedContacts(props);

  function getStatusColorClass(status) {
    switch (status) {
      case 'GET_IN_TOUCH':
        return 'text-green-900 bg-green-200'; // Green for "Get in Touch"
      case 'MEETING_SCHEDULED':
        return 'text-blue-900 bg-blue-200'; // Blue for "Meeting Scheduled"
      case 'FOLLOW_UP':
        return 'text-orange-900 bg-orange-200'; // Orange for "Follow Up"
      default:
        return 'text-gray-500'; // Default color if none match
    }
  }
  return (
    <MainLayout activeMenuItem="home">
      <div className="p-4 sticky">
        <div className="centered justify-between mb-4 window-container">
          <h2 className="window-title">Saved Contacts</h2>
          <div className="centered justify-center">
            <button
              className="btn  bg-slate-50 text-gray-900 rounded-xl outline outline-1 hover:bg-slate-200"
              onClick={() => handleContactModalOpen({})}
            >
              Add Contact
            </button>
          </div>
        </div>

        {loading ? (
          <div className="centered spinner-box">
            <Spin size="large" />
          </div>
        ) : (
          <div className="centered min-h-[90vh] flex-col gap-2 w-full">
            <div className="overflow-x-auto w-full flex-col mb-8">
              <table className="border-collapse w-full">
                <thead>
                  <tr className="w-full">
                    <th className="px-2 py-1">
                      <div className="centered justify-center text-sm font-medium text-gray-400">#</div>
                    </th>
                    <th className="px-2 py-1">
                      <div className="centered justify-start text-sm font-medium text-gray-400">Name</div>
                    </th>
                    <th className=" px-2 py-1">
                      <div className="centered  justify-start text-sm font-medium text-gray-400">Email</div>
                    </th>
                    <th className=" px-2 py-1">
                      <div className="centered  justify-start text-sm font-medium text-gray-400">Phone</div>
                    </th>
                    <th className="px-2 py-1">
                      <div className="centered  justify-start text-sm font-medium text-gray-400">Company</div>
                    </th>
                    <th className="px-2 py-1">
                      <div className="centered  justify-start text-sm font-medium text-gray-400">Relation</div>
                    </th>
                    <th className="px-2 py-1">
                      <div className="centered  justify-start text-sm font-medium text-gray-400">Location</div>
                    </th>
                    <th className="px-2 py-1">
                      <div className="centered  justify-start text-sm font-medium text-gray-400">Status</div>
                    </th>
                    <th className="px-2 py-1">
                      <div className="centered  justify-start text-sm font-medium text-gray-400">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(allContacts) &&
                    allContacts.map((item, index) => (
                      <tr key={item._id || index} className="border border-gray-200 border-l-0 border-r-0">
                        <td className="px-2 py-1 border border-gray-200 border-l-0 text-center">{index + 1}</td>

                        <td className="px-2 py-1 border text-base font-medium text-blue-900">{item?.name}</td>
                        <td className="px-2 py-1 border border-gray-200 text-xs font-medium text-blue-700 hover:text-blue-800 hover:underline">
                          <a href={`mailto:${item?.email}`}>{item?.email}</a>
                        </td>
                        <td className="px-2 py-1 border border-gray-200 text-xs font-medium text-[#2563EB] hover:text-[#1D4ED8] hover:underline">
                          <a href={`tel:${item?.contact_number}`}>{item?.contact_number}</a>
                        </td>
                        <td className="px-2 py-1 border border-gray-200 text-xs font-medium text-gray-600">
                          {item?.company}
                        </td>
                        <td className="px-2 py-1 border border-gray-200 text-xs font-medium text-gray-600">
                          {item?.contact_relationship.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
                        </td>
                        <td className="px-2 py-1 border border-gray-200 text-xs font-medium text-gray-600">
                          {item?.location}
                        </td>
                        <td className={`p-1 text-start border border-gray-200 text-xs font-medium  `}>
                          <span className={`p-2 rounded ${getStatusColorClass(item.contact_status)}`}>
                            {item?.contact_status &&
                              item.contact_status
                                .split('_')
                                .join(' ')
                                .toLowerCase()
                                .replace(/^\w/, (c) => c.toUpperCase())}
                          </span>
                        </td>
                        <td className="px-2 py-1 border border-r-0 border-gray-200">
                          <div className="centered justify-evenly">
                            <FontAwesomeIcon
                              className="m-2 cursor-pointer"
                              icon={faDeleteLeft}
                              onClick={() => onContactDelete(item)}
                            />
                            <FontAwesomeIcon
                              className="m-2 cursor-pointer"
                              icon={faEdit}
                              onClick={() => handleContactModalOpen(item)}
                            />
                            <FontAwesomeIcon
                              className="m-2 cursor-pointer"
                              icon={faEye}
                              onClick={() => onContactViewClick(item)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <Modal
          open={contactModalOpen}
          onCancel={() => handleContactModalOpen({})}
          footer={[
            <Button className="p-4 font-medium" key="save" onClick={onContactSave}>
              Save Contact
            </Button>,
          ]}
          centered
          title={contactForm?.name ? `Edit ${contactForm?.name}` : 'Add New Contact'}
        >
          {contactForm && (
            <div className="mt-4 space-y-3">
              <div className="flex flex-row justify-between">
                <p className="font-medium text-gray-700">Priority</p>
                <StarRatings
                  rating={contactForm?.interested}
                  starRatedColor="#FFCC2A"
                  changeRating={(e) =>
                    onInputChange({
                      target: { value: e, name: 'interested' },
                    })
                  }
                  numberOfStars={5}
                  name="rating"
                  starDimension="20px"
                  starHoverColor="#FCDDB0"
                />
              </div>
              <InputField
                id="contact-name"
                type="text"
                key={contactForm?.name || 'name'}
                name={'name'}
                placeholder="Enter name"
                defaultValue={contactForm?.name}
                onBlur={onInputChange}
                containerClassName="flex flex-col"
                inputClassName="p-2 border rounded bg-transparent"
                error={formErrors.name}
                errorClassName="text-xs"
              />
              <InputField
                id="contact-email"
                type="text"
                key={contactForm?.email || 'email'}
                name={'email'}
                placeholder="Enter email"
                defaultValue={contactForm?.email}
                onBlur={onInputChange}
                containerClassName="flex flex-col"
                inputClassName="p-2 border rounded bg-transparent"
                error={formErrors.email}
                errorClassName="text-xs"
              />
              <InputField
                id="contact-number"
                type="tel"
                key={contactForm?.contact_number || 'contact_number'}
                name={'contact_number'}
                placeholder="Enter contact number"
                defaultValue={contactForm?.contact_number}
                onBlur={onInputChange}
                containerClassName="flex flex-col"
                inputClassName="p-2 border rounded bg-transparent"
                error={formErrors.contact_number}
                errorClassName="text-xs"
              />
              <InputField
                id="contact-title"
                type="tel"
                key={contactForm?.title || 'title'}
                name={'title'}
                placeholder="Enter Title"
                defaultValue={contactForm?.title}
                onBlur={onInputChange}
                containerClassName="flex flex-col"
                inputClassName="p-2 border rounded bg-transparent"
                error={formErrors.title}
                errorClassName="text-xs"
              />
              <InputField
                id="contact-company"
                type="text"
                key={contactForm?.company || 'company'}
                name={'company'}
                placeholder="Enter Company"
                defaultValue={contactForm?.company}
                onBlur={onInputChange}
                containerClassName="flex flex-col"
                inputClassName="p-2 border rounded bg-transparent"
                error={formErrors.company}
                errorClassName="text-xs"
              />
              <InputField
                id="contact-location"
                type="text"
                key={contactForm?.location || 'location'}
                name={'location'}
                placeholder="Enter Location"
                defaultValue={contactForm?.location}
                onBlur={onInputChange}
                containerClassName="flex flex-col"
                inputClassName="p-2 border rounded bg-transparent"
                error={formErrors.location}
                errorClassName="text-xs"
              />
              <InputField
                id="contact-url"
                type="text"
                key={contactForm?.bookmark_url || 'bookmark_url'}
                name={'bookmark_url'}
                placeholder="Enter Contact URL"
                defaultValue={contactForm?.bookmark_url}
                onBlur={onInputChange}
                containerClassName="flex flex-col"
                inputClassName="p-2 border rounded bg-transparent"
                error={formErrors.bookmark_url}
                errorClassName="text-xs"
              />
              <ContactRelationship
                selectedValue={contactForm?.contact_relationship || ''}
                onContactRelationshipChange={onInputChange}
              />
              <ContactStatus selectedValue={contactForm?.contact_status || ''} onContactStatusChange={onInputChange} />
              <InputField
                id="contact-notes"
                type="text"
                key={contactForm?.notes || 'notes'}
                name={'notes'}
                placeholder="Enter notes"
                defaultValue={contactForm?.notes}
                onBlur={onInputChange}
                containerClassName="flex flex-col"
                inputClassName="p-2 border rounded bg-transparent"
                error={formErrors.notes}
                errorClassName="text-xs"
              />
            </div>
          )}
        </Modal>

        {/* Delete Modal */}
        <Modal
          title={`Are you sure you want to delete ${deleteContact?.name}?`}
          open={deleteModalOpen}
          onOk={onDeleteContactConfirm}
          onCancel={onDeleteModelClose}
          okText="Delete"
          cancelText="Cancel"
          centered
        />
      </div>
    </MainLayout>
  );
}

export default SavedContacts;
