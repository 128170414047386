import axios from 'axios';
import Config from './config';
const axiosInstance = axios.create({
  baseURL: Config.apiUrl,
  timeout: 25000,
});

axiosInstance.interceptors.request.use(
  function (config) {
    const user = JSON.parse(localStorage.getItem('aiclues-user'));

    const token = user?.access_token ? user.access_token : '';
    config.headers.Authorization = token && token.length ? `Bearer ${token}` : null;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      alert('You are not authorized');
    }
    return response;
  },
  (error) => {
    if (error?.response && error.response?.status === 401) {
      localStorage.removeItem('aiclues-user');
      window.location.reload();
      return Promise.reject(error.response && error.response.data);
    }

    if (error?.code === 'ECONNABORTED') {
      return Promise.reject('Request timeout exceeded');
    }
    return Promise.reject(error.response.data);
  }
);
export default axiosInstance;
