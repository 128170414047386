import intervalToDuration from 'date-fns/intervalToDuration';

export const dateFormatter = (date) => {
  var d = new Date(date);
  let formattedDate = d.toDateString();

  let dateReturn = formattedDate.split(' ');

  return `${dateReturn[2]} ${dateReturn[1]}, ${dateReturn[3]}`;
};

export const dateDiff = (startDate, endData) => {
  let diff = intervalToDuration({
    start: new Date(startDate),
    end: new Date(endData),
  });

  return diff?.years > 0
    ? `${diff?.years} Years`
    : diff?.months > 0
    ? `${diff?.months} Months`
    : diff?.days > 0
    ? `${diff?.days} Days`
    : diff?.hours > 0
    ? `${diff?.hours} Hours`
    : `${diff?.minutes} Minutes`;
};

//date convert
export const convertDate = (d) => {
  var date = new Date(d),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join('-');
};

export const dateDiffInDays = (date) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const today = new Date();
  const utc1 = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
  const utc2 = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());

  return Math.floor((utc1 - utc2) / _MS_PER_DAY);
};

export const timeToLocalString = (d) => {
  let date = new Date(d);
  return date.toLocaleTimeString();
};

export const formatAMPM = (time) => {
  let hours = time && time.split(':')[0];
  let minutes = time && time.split(':')[1];

  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  // minutes = minutes < 10 ? '0' + minutes : minutes;

  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};
