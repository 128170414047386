import io from 'socket.io-client';
import { envDefaults } from './envDefaults';

export const socket = io(envDefaults.serverUrl);

export const emitSocketEvent = (event, room) => {
  socket.emit(event, room);
};

export const consumeSocketEvent = (room, event) => {
  let data;
  socket.on(event, (message) => {
    data = message;
  });

  return data;
};

socket.on('connect_error', (err) => {
  console.log(err);
  console.log(err.context);
});

socket.on('disconnect', (reason, details) => {
  console.log(reason, 'aejlnflanganga');
  console.log(details.context);
});
