import React from 'react';

function AuthorizedToWork({ onAuthorizationChange, selectedValue }) {
  const countries = [
    { name: 'the US', key: 'us_work_authorized' },
    { name: 'the United Kingdom', key: 'uk_work_authorized' },
    { name: 'Canada', key: 'ca_work_authorized' },
  ];
  return (
    <>
      {countries.map((country) => (
        <div className="info-form-group" key={country.key}>
          <label className="info-label">Are you authorized to work in {country.name}?</label>
          <div className="mt-1 flex gap-2">
            <button
              className={`py-1 px-4 border-2 rounded-lg font-medium ${
                selectedValue[country.key] === true ? 'bg-gray-100 border-green-600' : 'border-gray-400'
              }`}
              onClick={() => onAuthorizationChange(country.key, true)}
            >
              Yes
            </button>
            <button
              className={`py-1 px-4 border-2 rounded-lg font-medium ${
                selectedValue[country.key] === false ? 'bg-gray-100 border-green-600' : 'border-gray-400'
              }`}
              onClick={() => onAuthorizationChange(country.key, false)}
            >
              No
            </button>
          </div>
        </div>
      ))}
    </>
  );
}

export default AuthorizedToWork;
