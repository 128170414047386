import { aiConstants } from '../../constant';

const initialState = { resumes: null, resume: null };

export const fetch_user_fine_tune_resumes_reducer = (state = initialState, action) => {
  switch (action.type) {
    case aiConstants.FETCH_USER_FINE_TUNE_RESUMES_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case aiConstants.FETCH_USER_FINE_TUNE_RESUMES_ACTION_SUCCESS:
      return {
        fineTunedResumes: action.payload,
      };
    case aiConstants.FETCH_USER_FINE_TUNE_RESUMES_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const fetch_user_fine_tune_resume_reducer = (state = initialState, action) => {
  switch (action.type) {
    case aiConstants.FETCH_USER_FINE_TUNE_RESUME_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case aiConstants.FETCH_USER_FINE_TUNE_RESUME_ACTION_SUCCESS:
      return {
        fineTunedResumeData: action.payload,
      };
    case aiConstants.FETCH_USER_FINE_TUNE_RESUME_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const fetch_user_mock_interviews_reducer = (state = initialState, action) => {
  switch (action.type) {
    case aiConstants.FETCH_USER_MOCK_INTERVIEWS_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case aiConstants.FETCH_USER_MOCK_INTERVIEWS_ACTION_SUCCESS:
      return {
        mockInterviews: action.payload,
      };
    case aiConstants.FETCH_USER_MOCK_INTERVIEWS_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const fetch_user_mock_interview_reducer = (state = initialState, action) => {
  switch (action.type) {
    case aiConstants.FETCH_USER_MOCK_INTERVIEW_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case aiConstants.FETCH_USER_MOCK_INTERVIEW_ACTION_SUCCESS:
    case aiConstants.CREATE_USER_MOCK_INTERVIEW_ACTION_SUCCESS:
      return {
        mockInterviewData: action.payload,
      };
    case aiConstants.FETCH_USER_MOCK_INTERVIEW_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};
