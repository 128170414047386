import React from 'react';

function IdentifyLGBTQ({ onLGBTQChange, selectedValue }) {
  return (
    <div className="mt-1 flex gap-2">
      <button
        className={`py-1 px-4 border-2 rounded-lg font-medium ${
          selectedValue === true ? 'bg-gray-100 border-green-600' : 'border-gray-400'
        }`}
        onClick={() => onLGBTQChange(true)}
      >
        Yes
      </button>
      <button
        className={`py-1 px-4 border-2 rounded-lg font-medium ${
          selectedValue === false ? 'bg-gray-100 border-green-600' : 'border-gray-400'
        }`}
        onClick={() => onLGBTQChange(false)}
      >
        No
      </button>
      {/* <button
        className={`py-1 px-4 border-2 rounded-lg font-medium ${
          selectedValue === 'decline' ? 'bg-gray-100 border-green-600' : 'border-gray-400'
        }`}
        onClick={() => onLGBTQChange('decline')}
      >
        Decline to State
      </button> */}
    </div>
  );
}

export default IdentifyLGBTQ;
