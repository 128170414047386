import { combineReducers } from 'redux';
import {
  signin_user_reducer,
  signup_user_reducer,
  logout_user_reducer,

  //candidate profile
  fetch_candidate_profile_reducer,
  update_candidate_profile_reducer,

  //master
  fetch_headlines_reducer,
  fetch_skills_reducer,
  fetch_pronouns_reducer,
  fetch_colleges_reducer,
  fetch_cities_reducer,
  fetch_regions_reducer,
  fetch_countries_reducer,
  fetch_companies_reducer,
  fetch_courses_reducer,
  fetch_course_types_reducer,

  //userskill
  fetch_user_skills_reducer,

  //usereducations
  fetch_user_educations_reducer,

  //professions
  fetch_user_professions_reducer,
  fetch_user_job_bookmarks_reducer,
  fetch_user_job_details_bookmark_reducer,
  fetch_user_fine_tune_resume_reducer,
  fetch_user_fine_tune_resumes_reducer,
  fetch_user_all_resumes_reducer,
  fetch_user_contact_bookmarks_reducer,
  fetch_user_contact_details_bookmark_reducer,
  fetch_user_company_bookmarks_reducer,
  fetch_user_company_details_bookmark_reducer,
  fetch_user_mock_interviews_reducer,
  fetch_user_mock_interview_reducer,
} from './reducer';

const rootReducer = combineReducers({
  signin_user_reducer,
  signup_user_reducer,
  logout_user_reducer,

  fetch_candidate_profile_reducer,
  update_candidate_profile_reducer,

  //master
  fetch_headlines_reducer,
  fetch_skills_reducer,
  fetch_pronouns_reducer,
  fetch_colleges_reducer,
  fetch_cities_reducer,
  fetch_regions_reducer,
  fetch_countries_reducer,
  fetch_companies_reducer,
  fetch_courses_reducer,
  fetch_course_types_reducer,

  //userskills
  fetch_user_skills_reducer,

  //usereducations
  fetch_user_educations_reducer,

  //professions
  fetch_user_professions_reducer,

  fetch_user_job_bookmarks_reducer,
  fetch_user_job_details_bookmark_reducer,

  fetch_user_contact_bookmarks_reducer,
  fetch_user_contact_details_bookmark_reducer,

  fetch_user_company_bookmarks_reducer,
  fetch_user_company_details_bookmark_reducer,

  fetch_user_fine_tune_resume_reducer,
  fetch_user_fine_tune_resumes_reducer,
  fetch_user_all_resumes_reducer,

  fetch_user_mock_interviews_reducer,
  fetch_user_mock_interview_reducer,
});

export default rootReducer;
