export const authConstants = {
  //SIGNIN
  SIGNIN_USER_ACTION_REQUEST: 'SIGNIN_USER_ACTION_REQUEST',
  SIGNIN_USER_ACTION_SUCCESS: 'SIGNIN_USER_ACTION_SUCCESS',
  SIGNIN_USER_ACTION_FAILURE: 'SIGNIN_USER_ACTION_FAILURE',

  //signup
  SIGNUP_USER_ACTION_REQUEST: 'SIGNUP_USER_ACTION_REQUEST',
  SIGNUP_USER_ACTION_SUCCESS: 'SIGNUP_USER_ACTION_SUCCESS',
  SIGNUP_USER_ACTION_FAILURE: 'SIGNUP_USER_ACTION_FAILURE',

  //logout
  LOGOUT_USER_ACTION_REQUEST: 'LOGOUT_USER_ACTION_REQUEST',
  LOGOUT_USER_ACTION_SUCCESS: 'LOGOUT_USER_ACTION_SUCCESS',
  LOGOUT_USER_ACTION_FAILURE: 'LOGOUT_USER_ACTION_FAILURE',

  //user meta data
  USER_META_DATA_REQUEST: 'USER_META_DATA_REQUEST',
  USER_META_DATA_SUCCESS: 'USER_META_DATA_SUCCESS',
  USER_META_DATA_FAILURE: 'USER_META_DATA_FAILURE',

  //user meta data
  UPDATE_USER_META_DATA_REQUEST: 'UPDATE_USER_META_DATA_REQUEST',
  UPDATE_USER_META_DATA_SUCCESS: 'UPDATE_USER_META_DATA_SUCCESS',
  UPDATE_USER_META_DATA_FAILURE: 'UPDATE_USER_META_DATA_FAILURE',

  //
  SEND_VERIFICATION_CODE_ACTION_REQUEST: 'SEND_VERIFICATION_CODE_ACTION_REQUEST',
  SEND_VERIFICATION_CODE_ACTION_SUCCESS: 'SEND_VERIFICATION_CODE_ACTION_SUCCESS',
  SEND_VERIFICATION_CODE_ACTION_FAILURE: 'SEND_VERIFICATION_CODE_ACTION_FAILURE',

  VERIFY_EMAIL_CODE_ACTION_REQUEST: 'VERIFY_EMAIL_CODE_ACTION_REQUEST',
  VERIFY_EMAIL_CODE_ACTION_SUCCESS: 'VERIFY_EMAIL_CODE_ACTION_SUCCESS',
  VERIFY_EMAIL_CODE_ACTION_FAILURE: 'VERIFY_EMAIL_CODE_ACTION_FAILURE',

  FORGOT_PASSWORD_ACTION_REQUEST: 'FORGOT_PASSWORD_ACTION_REQUEST',
  FORGOT_PASSWORD_ACTION_SUCCESS: 'FORGOT_PASSWORD_ACTION_SUCCESS',
  FORGOT_PASSWORD_ACTION_FAILURE: 'FORGOT_PASSWORD_ACTION_FAILURE',

  RESET_PASSWORD_ACTION_REQUEST: 'RESET_PASSWORD_ACTION_REQUEST',
  RESET_PASSWORD_ACTION_SUCCESS: 'RESET_PASSWORD_ACTION_SUCCESS',
  RESET_PASSWORD_ACTION_FAILURE: 'RESET_PASSWORD_ACTION_FAILURE',
};
