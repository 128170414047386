import { userContactBookmarkConstants } from '../../constant';

const initialState = {
  bookmarkedContactsData: null,
  bookmarkedContactData: null,
};

export const fetch_user_contact_bookmarks_reducer = (state = initialState, action) => {
  switch (action.type) {
    case userContactBookmarkConstants.FETCH_USER_CONTACT_BOOKMARKS_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case userContactBookmarkConstants.FETCH_USER_CONTACT_BOOKMARKS_ACTION_SUCCESS:
      return {
        bookmarkedContactsData: action.payload,
      };
    case userContactBookmarkConstants.FETCH_USER_CONTACT_BOOKMARKS_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    // Handle creation success
    case userContactBookmarkConstants.CREATE_USER_BOOKMARK_CONTACT_ACTION_SUCCESS:
      return {
        ...state,
        bookmarkedContactsData: [...state.bookmarkedContactsData, action.payload],
      };

    default:
      return state;
  }
};

export const fetch_user_contact_details_bookmark_reducer = (state = initialState, action) => {
  switch (action.type) {
    case userContactBookmarkConstants.FETCH_USER_CONTACT_DETAILS_BOOKMARK_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case userContactBookmarkConstants.FETCH_USER_CONTACT_DETAILS_BOOKMARK_ACTION_SUCCESS:
    case userContactBookmarkConstants.UPDATE_USER_BOOKMARK_CONTACT_ACTION_SUCCESS:
      return {
        bookmarkedContactData: action.payload,
      };
    case userContactBookmarkConstants.FETCH_USER_CONTACT_DETAILS_BOOKMARK_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};
