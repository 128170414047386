export const userCompanyBookmarkConstants = {
  FETCH_USER_COMPANY_BOOKMARKS_ACTION_REQUEST: 'FETCH_USER_COMPANY_BOOKMARKS_ACTION_REQUEST',
  FETCH_USER_COMPANY_BOOKMARKS_ACTION_SUCCESS: 'FETCH_USER_COMPANY_BOOKMARKS_ACTION_SUCCESS',
  FETCH_USER_COMPANY_BOOKMARKS_ACTION_FAILURE: 'FETCH_USER_COMPANY_BOOKMARKS_ACTION_FAILURE',

  FETCH_USER_COMPANY_DETAILS_BOOKMARK_ACTION_REQUEST: 'FETCH_USER_COMPANY_DETAILS_BOOKMARK_ACTION_REQUEST',
  FETCH_USER_COMPANY_DETAILS_BOOKMARK_ACTION_SUCCESS: 'FETCH_USER_COMPANY_DETAILS_BOOKMARK_ACTION_SUCCESS',
  FETCH_USER_COMPANY_DETAILS_BOOKMARK_ACTION_FAILURE: 'FETCH_USER_COMPANY_DETAILS_BOOKMARK_ACTION_FAILURE',

  UPDATE_USER_BOOKMARK_COMPANY_ACTION_REQUEST: 'UPDATE_USER_BOOKMARK_COMPANY_ACTION_REQUEST',
  UPDATE_USER_BOOKMARK_COMPANY_ACTION_SUCCESS: 'UPDATE_USER_BOOKMARK_COMPANY_ACTION_SUCCESS',
  UPDATE_USER_BOOKMARK_COMPANY_ACTION_FAILURE: 'UPDATE_USER_BOOKMARK_COMPANY_ACTION_FAILURE',

  DELETE_USER_BOOKMARK_COMPANY_ACTION_REQUEST: 'DELETE_USER_BOOKMARK_COMPANY_ACTION_REQUEST',
  DELETE_USER_BOOKMARK_COMPANY_ACTION_SUCCESS: 'DELETE_USER_BOOKMARK_COMPANY_ACTION_SUCCESS',
  DELETE_USER_BOOKMARK_COMPANY_ACTION_FAILURE: 'DELETE_USER_BOOKMARK_COMPANY_ACTION_FAILURE',

  // constants for creating company
  CREATE_USER_BOOKMARK_COMPANY_ACTION_REQUEST: 'CREATE_USER_BOOKMARK_COMPANY_ACTION_REQUEST',
  CREATE_USER_BOOKMARK_COMPANY_ACTION_SUCCESS: 'CREATE_USER_BOOKMARK_COMPANY_ACTION_SUCCESS',
  CREATE_USER_BOOKMARK_COMPANY_ACTION_FAILURE: 'CREATE_USER_BOOKMARK_COMPANY_ACTION_FAILURE',
};
