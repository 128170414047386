import { userSkillConstants } from '../../constant';

const initialState = { skills: null };

export const fetch_user_skills_reducer = (state = initialState, action) => {
  switch (action.type) {
    case userSkillConstants.FETCH_USER_SKILLS_ACTION_REQUEST:
      return {
        type: 'alert-success',
        message: action.payload,
      };
    case userSkillConstants.FETCH_USER_SKILLS_ACTION_SUCCESS:
      return {
        skills: action.payload,
      };
    case userSkillConstants.FETCH_USER_SKILLS_ACTION_FAILURE:
      return {
        type: 'alert-failure',
        error: action.payload,
      };
    default:
      return state;
  }
};
