import { showSuccessMessage, showWarningMessage } from '../../../_component/MessageSnack';
import axiosInstance from '../../../_config/AxiosInstance';
import config from '../../../_config/config';
import { authConstants } from '../../constant';

// login
export const sigin_user_action = (data) => async (dispatch) => {
  dispatch({
    type: authConstants.SIGNIN_USER_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(config.auth.signin, data);
    if (response.status) {
      let data = response.data.data;
      localStorage.setItem('aiclues-user', JSON.stringify(data));
      dispatch({
        type: authConstants.SIGNIN_USER_ACTION_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: authConstants.SIGNIN_USER_ACTION_FAILURE,
      payload: e?.msg,
    });
    showWarningMessage(e.error);
    return e;
  }
};

//signup
export const logout_user_action = (data) => async (dispatch) => {
  dispatch({
    type: authConstants.LOGOUT_USER_ACTION_REQUEST,
  });
  try {
    localStorage.removeItem('aiclues-user');
    dispatch({
      type: authConstants.LOGOUT_USER_ACTION_SUCCESS,
      payload: null,
    });
  } catch (e) {
    dispatch({
      type: authConstants.LOGOUT_USER_ACTION_FAILURE,
      payload: e?.msg,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const signup_user_action = (data) => async (dispatch) => {
  dispatch({
    type: authConstants.SIGNUP_USER_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(config.auth.register, data);
    if (response.status) {
      dispatch({
        type: authConstants.SIGNUP_USER_ACTION_SUCCESS,
        payload: response.data,
      });
      let data = response.data.data;
      localStorage.setItem('aiclues-user', JSON.stringify(data));
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: authConstants.SIGNUP_USER_ACTION_FAILURE,
      payload: e?.msg,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const update_user_meta_action = (data) => async (dispatch) => {
  dispatch({
    type: authConstants.SIGNIN_USER_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.put(config.auth.userMeta, data);
    if (response.status) {
      dispatch({
        type: authConstants.SIGNIN_USER_ACTION_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: authConstants.SIGNIN_USER_ACTION_FAILURE,
      payload: e?.msg,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const send_verification_code_action = () => async (dispatch) => {
  dispatch({
    type: authConstants.SEND_VERIFICATION_CODE_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(config.auth.sendVerifyEmail);
    if (response.status) {
      dispatch({
        type: authConstants.SEND_VERIFICATION_CODE_ACTION_SUCCESS,
        payload: response.data,
      });
      showSuccessMessage('Verification email successfully sent.');
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: authConstants.SEND_VERIFICATION_CODE_ACTION_FAILURE,
      payload: e?.msg,
    });
    showWarningMessage(e.error);
    return e;
  }
};

export const verify_email_code_action = (data) => async (dispatch) => {
  dispatch({
    type: authConstants.VERIFY_EMAIL_CODE_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(config.auth.verifyEmail, data);
    if (response.status) {
      dispatch({
        type: authConstants.VERIFY_EMAIL_CODE_ACTION_SUCCESS,
        payload: response.data,
      });
      showSuccessMessage('Email successfully verified.');
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: authConstants.VERIFY_EMAIL_CODE_ACTION_FAILURE,
      payload: e?.msg,
    });
    showWarningMessage(e.error);
    return false;
  }
};

export const forgot_password_action = (data) => async (dispatch) => {
  dispatch({
    type: authConstants.FORGOT_PASSWORD_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(config.auth.forgotPassword, data);
    if (response.status) {
      dispatch({
        type: authConstants.FORGOT_PASSWORD_ACTION_SUCCESS,
        payload: response.data,
      });
      showSuccessMessage('Password reset email sent.');
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: authConstants.FORGOT_PASSWORD_ACTION_FAILURE,
      payload: e?.msg,
    });
    showWarningMessage(e.error);
    return false;
  }
};

export const reset_password_action = (id, data) => async (dispatch) => {
  dispatch({
    type: authConstants.RESET_PASSWORD_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(`${config.auth.resetPassword}/${id}`, data);
    if (response.status) {
      dispatch({
        type: authConstants.RESET_PASSWORD_ACTION_SUCCESS,
        payload: response.data,
      });
      showSuccessMessage('Password reset successfully. Login to continue.');
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: authConstants.RESET_PASSWORD_ACTION_FAILURE,
      payload: e?.msg,
    });
    showWarningMessage(e.error);
    return false;
  }
};
