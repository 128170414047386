/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  create_user_fine_tune_resume_action,
  create_user_resume_action,
  delete_user_resume_action,
  fetch_user_fine_tune_resumes_action,
  fetch_user_resumes_action,
  update_user_resume_action,
} from '../../../_redux/action';
import { showWarningMessage } from '../../../_component/MessageSnack';
import { emptyStringCheck } from '../../../_helpers/formFieldErrorCheck';
import { navigateRoutes } from '../../../_config/route';

function useResumeBuilder(props) {
  const [loading, setLoading] = useState(false);
  const [resumeFile, setResumeFile] = useState(null);
  const [userResumes, setUserResumes] = useState(null);
  const [resumeForm, setResumeForm] = useState(null);
  const [resumeModalOpen, setResumeModalOpen] = useState(null);
  const [fineTuneModelOpen, setFineTuneModelOpen] = useState(null);
  const [fineTuneResumeForm, setFineTuneResumeForm] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { resumes } = useSelector((state) => state.fetch_user_all_resumes_reducer);

  const { fineTunedResumes } = useSelector((state) => state.fetch_user_fine_tune_resumes_reducer);

  useEffect(() => {
    const callApi = async () => {
      setLoading(true);
      await dispatch(fetch_user_resumes_action());
      await dispatch(fetch_user_fine_tune_resumes_action());
      setLoading(false);
    };

    callApi();
  }, []);

  useEffect(() => {
    if (resumes?.resumes) {
      setUserResumes(resumes?.resumes);
    }
  }, [resumes]);

  const onImageUpload = async (e) => {
    const file = e.target.files[0];
    setResumeFile(file);
  };

  const onResumeEdit = async (item) => {
    if (item) {
      setResumeForm(item);
    }
  };

  const onInputChange = async (e) => {
    setResumeForm({ ...resumeForm, [e.target.name]: e.target.value });
  };

  const onResumeDelete = async (item) => {
    if (item?._id) {
      await dispatch(delete_user_resume_action(item._id));
      await dispatch(fetch_user_resumes_action());
    }
  };

  const onResumeSave = async () => {
    if (resumeFile && !emptyStringCheck(resumeForm?.name) && !emptyStringCheck(resumeForm?.parsed_value)) {
      const data = new FormData();
      if (resumeFile) data.append('resume', resumeFile);
      if (resumeForm?.name) data.append('name', resumeForm?.name);
      if (resumeForm?.parsed_value) data.append('parsed_value', resumeForm?.parsed_value);

      if (resumeForm?._id) {
        await dispatch(update_user_resume_action(resumeForm._id, data));
      } else {
        await dispatch(create_user_resume_action(data));
      }
    } else {
      showWarningMessage('Please fill all fields.');
    }
    await dispatch(fetch_user_resumes_action());
    setResumeForm({});
    setResumeFile(null);
    setResumeModalOpen((v) => !v);
  };

  const handleResumeModalOpen = (item) => {
    if (item) {
      setResumeForm(item);
    }
    setResumeModalOpen((v) => !v);
  };

  const onResumeNameEdit = async (val) => {
    if (!emptyStringCheck(val)) {
      const data = new FormData();
      data.append('name', val);

      if (resumeForm?._id) {
        await dispatch(update_user_resume_action(resumeForm._id, data));
      }
    } else {
      showWarningMessage('Non empty field not valid.');
    }
    await dispatch(fetch_user_resumes_action());
    setResumeForm({});
  };

  const onResumeViewClick = async (item) => {
    if (item?.resume) {
      window.open(item.resume, null);
    }
  };

  const onFineTuneResumeSave = async (ai_platform) => {
    if (ai_platform) {
      if (fineTuneResumeForm?.resume && fineTuneResumeForm?.job_description) {
        const result = await dispatch(
          create_user_fine_tune_resume_action({
            ...fineTuneResumeForm,
            ai_platform,
          })
        );
        // Check if result contains the newly created resume ID
        const resumeId = result?.data?.saveFineTunedResume?._id; // Assuming the created resume ID is returned in result.data.id

        if (resumeId) {
          await dispatch(fetch_user_fine_tune_resumes_action());

          navigate(navigateRoutes.fineTunedResume.replace(':id', resumeId));

          setFineTuneResumeForm(null);
          setFineTuneModelOpen(false);
        } else {
          showWarningMessage('Resume creation failed.');
        }
      } else showWarningMessage('Please select resume and enter job description.');
    }
  };

  const onFineTuneFormChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (value) {
      setFineTuneResumeForm({ ...fineTuneResumeForm, [name]: value });
    }
  };
  const handleFineTuneModalOpen = () => {
    setFineTuneModelOpen((v) => !v);
  };

  const onFineTuneResumeViewClick = (item) => {
    if (item._id) {
      navigate(navigateRoutes.fineTunedResume.replace(':id', item._id));
    }
  };

  return {
    loading,
    userResumes,
    resumeForm,
    resumeModalOpen,
    fineTuneModelOpen,
    fineTuneResumeForm,
    fineTunedResumes,

    onImageUpload,
    onInputChange,
    onResumeSave,
    onResumeDelete,
    onResumeEdit,
    handleResumeModalOpen,
    onResumeNameEdit,
    onResumeViewClick,
    onFineTuneResumeSave,
    onFineTuneFormChange,
    handleFineTuneModalOpen,
    onFineTuneResumeViewClick,
  };
}
export default useResumeBuilder;
