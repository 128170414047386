import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { send_verification_code_action, verify_email_code_action } from '../../../_redux/action';
import { navigateRoutes } from '../../../_config/route';

function useEmailVerify(props) {
  //initial states
  const [formData, setformData] = useState({ code: '' });
  const [formInputsError, setFormInputsError] = useState({});
  const [loading, setloading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onInputChange = (value, key) => {
    let data = formData;
    data[key] = value;
    setformData(data);
  };

  // submit form
  const onSubmitClick = async (e) => {
    e.preventDefault();
    setloading(true);
    setFormInputsError({});
    if (validateForm()) {
      const loginData = await dispatch(verify_email_code_action(formData));
      if (loginData) {
        navigate(navigateRoutes.candidateProfile);
      }
    }
    setloading(false);
  };

  const validateForm = () => {
    let form_errors = {};
    if (formData.code.length < 4) {
      form_errors = {
        code: 'Code cannot be less than 4 digits',
      };
      setFormInputsError(form_errors);
      return false;
    }
    return true;
  };

  const onResendEmailClick = async () => {
    await dispatch(send_verification_code_action());
  };

  return {
    formInputsError,
    loading,

    onInputChange,
    onSubmitClick,
    onResendEmailClick,
  };
}

export default useEmailVerify;
