export const userSkillConstants = {
  FETCH_USER_SKILLS_ACTION_REQUEST: 'FETCH_USER_SKILLS_ACTION_REQUEST',
  FETCH_USER_SKILLS_ACTION_SUCCESS: 'FETCH_USER_SKILLS_ACTION_SUCCESS',
  FETCH_USER_SKILLS_ACTION_FAILURE: 'FETCH_USER_SKILLS_ACTION_FAILURE',

  CREATE_USER_SKILL_ACTION_REQUEST: 'CREATE_USER_SKILL_ACTION_REQUEST',
  CREATE_USER_SKILL_ACTION_SUCCESS: 'CREATE_USER_SKILL_ACTION_SUCCESS',
  CREATE_USER_SKILL_ACTION_FAILURE: 'CREATE_USER_SKILL_ACTION_FAILURE',

  UPDATE_USER_SKILL_ACTION_REQUEST: 'UPDATE_USER_SKILL_ACTION_REQUEST',
  UPDATE_USER_SKILL_ACTION_SUCCESS: 'UPDATE_USER_SKILL_ACTION_SUCCESS',
  UPDATE_USER_SKILL_ACTION_FAILURE: 'UPDATE_USER_SKILL_ACTION_FAILURE',

  DELETE_USER_SKILL_ACTION_REQUEST: 'DELETE_USER_SKILL_ACTION_REQUEST',
  DELETE_USER_SKILL_ACTION_SUCCESS: 'DELETE_USER_SKILL_ACTION_SUCCESS',
  DELETE_USER_SKILL_ACTION_FAILURE: 'DELETE_USER_SKILL_ACTION_FAILURE',
};
